<div class="mat-app-background basic-container">
  <mat-card>
    <mat-card-title i18n>
      Добавление контроллера
    </mat-card-title>
    <form [formGroup]="base">
      <mat-card-subtitle i18n>
        Для регистрации в системе нового контроллера необходимо ввести его номер
      </mat-card-subtitle>
      <div>
        <div>
          <mat-form-field>
            <mat-label i18n>Номер контроллера</mat-label>
            <input #snInput matInput type="text" formControlName="SN" [textMask]="{mask: num_mask}">
            <mat-error *ngIf="base.invalid" i18n>
              Проверьте правильность ввода номера. Номер должен состоять из 16 знаков, включающих цифры и буквы ABCDEF
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label i18n>Контрольная информация</mat-label>
            <input #hashInput matInput type="text" formControlName="HASH" [textMask]="{mask: num_hash}">
            <mat-error *ngIf="base.invalid" i18n>
              Проверьте правильность ввода номера. Контрольная информация должна состоять из 16 цифр и латинских букв
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label i18n>Название контроллера</mat-label>
            <input matInput type="text" formControlName="name">
            <mat-error *ngIf="base.invalid" i18n>
              Каждый контроллер должен иметь название
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div>
      <button mat-button *ngIf="success!=null" color="primary">{{success}}</button>
    </div>
    <div>
      <button mat-button *ngIf="error!=null" color="warn">{{error}}</button>
    </div>

    <mat-card-subtitle *ngIf="error!==null && !base.dirty">
      <button mat-button color="warn">{{error}}</button>
    </mat-card-subtitle>
    <mat-card-actions>
      <button mat-button [disabled]="base.invalid" (click)="append()" color="primary">Добавить</button>
    </mat-card-actions>
    <mat-card-subtitle i18n>
      Остальные параметры будут доступны для редактирования после добавления блока в систему
    </mat-card-subtitle>
  </mat-card>
</div>
