<mat-horizontal-stepper #stepper linear="true">
    <!-- Создание нового объекта -->
    <mat-step #createObject>
        <ng-template matStepLabel i18n>Создание нового объекта</ng-template>
        <app-create-obj-step1 (ready)="done_step1($event)"></app-create-obj-step1>
    </mat-step>

    <!-- Выбор параметра объекта -->
    <mat-step #parametrOfObject *ngIf="step1 && step1.type_id && desc.construct[step1.type_id]">
        <ng-template matStepLabel i18n>Выбор параметра объекта</ng-template>
        <app-create-obj-step2 (ready)="done_step2($event)" [type_id]="step1.type_id"></app-create-obj-step2>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel i18n>Тарификация</ng-template>
        <app-create-obj-step3 (ready)="done_step3($event)"></app-create-obj-step3>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel i18n>Подтверждение объекта</ng-template>
        <div>
            <mat-list>
                <app-single-object [object]="object"></app-single-object>
            </mat-list>
        </div>
        <div>
            <button mat-button matStepperPrevious i18n>Назад</button>
            <button mat-button color="primary" (click)="save()" [disabled]="progress" i18n>
                Сохранить
            </button>
            <mat-progress-spinner *ngIf="progress" diameter="10" mode="indeterminate"></mat-progress-spinner>
        </div>
    </mat-step>
</mat-horizontal-stepper>
<div *ngIf="debug">
    <div *ngIf="step1">
        <h1>form</h1>
        <p>{{step1|json}}</p>
    </div>
    <div *ngIf="step2">
        <h1>step2</h1>
        <p>{{step2|json}}</p>
    </div>
    <div>
        <h1>tariff</h1>
        <p>{{tariff|json}}</p>
    </div>
    <div>
        <h1>object</h1>
        <p>{{object|json}}</p>
    </div>
</div>
