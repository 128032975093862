<div class="mat-app-background basic-container">
    <mat-card>
        <mat-card-title>
            <ng-container i18n>Журнал событий</ng-container>
            <ng-container *ngIf="generating;else downloadOk">
                <mat-spinner matTooltip="Подготовка журнала к загрузке" diameter="18" class="md-18"></mat-spinner>
            </ng-container>
            <ng-template #downloadOk>
                <a mat-icon-button (click)="download()" i18n-matTooltip matTooltip="Загрузить журнал">
                    <mat-icon class="md-18">assignment_returned</mat-icon>
                </a>
            </ng-template>
            <a mat-raised-button (click)="selectFilters()">Фильтр</a>
        </mat-card-title>
<!--        <mat-card-subtitle>{{query|json}}</mat-card-subtitle>-->
<!--        <mat-card-subtitle>{{filter.value|json}}</mat-card-subtitle>-->
        <mat-card-subtitle *ngIf="query.metka_sn">
            <ng-container i18n>Метка:</ng-container>
            <a mat-button color="primary" [routerLink]="'/log'" [queryParams]="query|shrink:'metka_sn'">
                {{query.metka_sn}}
                <mat-icon>close</mat-icon>
            </a>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="query.base">
            <ng-container i18n>Контроллера</ng-container>
            <a mat-button i18n color="primary"
               [routerLink]="'/log'" [queryParams]="query|shrink:'base'"
            >
                <app-descr [id]="query.base" val="base"></app-descr>
                <mat-icon>close</mat-icon>
            </a>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="query.start && query.end;else only_start_or_end">
            <ng-container i18n>Показаны события из диапазона дат с</ng-container>
            <a mat-button [routerLink]="'/log'" [queryParams]="query|shrink:['start', 'day']">{{query.start|date_local}}
                <mat-icon>close</mat-icon>
            </a>
            <ng-container i18n>и заканчивая</ng-container>
            <a mat-button [routerLink]="'/log'" [queryParams]="query|shrink:['end','day']">{{query.end|date_local}}
                <mat-icon>close</mat-icon>
            </a>
        </mat-card-subtitle>
        <ng-template #only_start_or_end>
            <mat-card-subtitle *ngIf="query.start;else only_end">
                <ng-container i18n>Показаны события начиная с</ng-container>
                <a mat-button [routerLink]="'/log'"
                                              [queryParams]="query|shrink:['start','day']">{{query.start|date_local}}
                <mat-icon>close</mat-icon>
            </a>
            </mat-card-subtitle>
        </ng-template>
        <ng-template #only_end>
            <mat-card-subtitle *ngIf="query.end">
                <ng-container i18n>Показаны события до</ng-container>
                <a mat-button [routerLink]="'/log'"
                                       [queryParams]="query|shrink:['end','day']">{{query.end|date_local}}
                <mat-icon>close</mat-icon>
            </a>
            </mat-card-subtitle>
        </ng-template>
        <ng-container *ngIf="query.day">
            <a mat-flat-button color="primary" [routerLink]="'/log'" [queryParams]="{day:+query.day+1}">Назад</a>
            <a mat-flat-button color="accent" *ngIf="query.day!='0'" [routerLink]="'/log'" [queryParams]="{day:+query.day-1}">Вперёд</a>
        </ng-container>
        <mat-card-content>
            <mat-spinner *ngIf="updating"></mat-spinner>
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="TIME">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Время</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.TIME|date:'short'}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="base">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Контроллер</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <app-descr [routerLink]="['/base',element.base]" [id]="element.base" val="base"></app-descr>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="METKA_HEX">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Метка</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a mat-button (click)="edit_metka(element.METKA_HEX)" color="primary"
                           *ngIf="element.METKA_HEX!='0';else remote">
                            {{ element.METKA_HEX }}
                        </a>
                        <ng-template #remote>
                            <a mat-button color="accent" i18n>
                                Удалённое открытие
                            </a>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="metka_phone">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Телефон</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a mat-button color="accent" [href]="'tel:'+element.metka_phone">{{ element.metka_phone }}</a>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="metka_type_id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Тип</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a mat-icon-button [ngSwitch]="element.metka_type_id">
                            <app-icon *ngSwitchCase="1" icon="phone" i18n-label label="Электронная метка"></app-icon>
                            <app-icon *ngSwitchCase="2" icon="brelok" i18n-label label="брелок"></app-icon>
                        </a>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status_id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Состояние</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <app-descr [id]="element.status_id" val="status"></app-descr>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="address">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Адрес</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <app-descr [id]="element.address" val="address"></app-descr>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="address_local">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Номер</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.address_local}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="param">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Параметр</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.param==element.address_local">{{element.param}}</ng-container>
                        <ng-container *ngIf="element.param!=element.address_local">{{element.address_local}}
                            ({{element.param}})
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Телефон</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.phone}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="object">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Объект</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <app-descr [id]="element.object" val="object"></app-descr>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="tkd">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>ТКД</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <app-descr [id]="element.tkd" val="tkd"></app-descr>
                    </mat-cell>
                </ng-container>

                <!-- Заголовок группы -->
                <ng-container matColumnDef="groupHeader">
                    <mat-row>
                        <mat-header-cell colspan="999" *matCellDef="let groupBy">
                            <h3>{{groupBy.value}}</h3>
                        </mat-header-cell>
                    </mat-row>
                </ng-container>

                <!-- Заголовоки -->
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <!-- Обычные строки -->
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                <!-- Строка подзаголовка -->
                <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"></mat-row>

            </mat-table>
        </mat-card-content>
        <mat-card-actions>
            <a mat-button routerLink="/log" [queryParams]="change('page', 1)" *ngIf="query.page>1" i18n>Начало</a>
            <a mat-button routerLink="/log" [queryParams]="change('page', query.page-1)" *ngIf="query.page>1"
               i18n>Назад</a>
            <a mat-button routerLink="/log" [queryParams]="change('page', query.page+1)" i18n>Дальше</a>
        </mat-card-actions>
    </mat-card>
</div>
<ng-template #filterDialog>
    <h2 matDialogTitle i18n>Фильтр событий в журнале</h2>
    <mat-dialog-content>
        <p i18n>
            Выбирите, какие фильтры необходимо применить для журнала событий
        </p>
        <form [formGroup]="filter">
            <mat-form-field>
                <mat-label>Начало отображаемого интервала</mat-label>
                <input matInput [matDatepicker]="end_interval" formControlName="start" [max]="maximum_interval_start">
                <mat-datepicker-toggle matSuffix [for]="end_interval"></mat-datepicker-toggle>
                <mat-datepicker #end_interval></mat-datepicker>
                <mat-hint i18n>Оставте пустым для показа начиная с самой первой записи</mat-hint>
            </mat-form-field>
            <mat-form-field>
                <mat-label i18n>Окончание отображаемого интервала</mat-label>
                <input matInput [matDatepicker]="start_interval" formControlName="end" [max]="maximum_interval_end"
                       [min]="minimum_interval_end">
                <mat-datepicker-toggle matSuffix [for]="start_interval"></mat-datepicker-toggle>
                <mat-datepicker #start_interval></mat-datepicker>
                <mat-hint i18n>Оставте пустым для показа начиная с самой последней записи</mat-hint>
            </mat-form-field>
            <mat-form-field>
                <mat-label i18n>Контроллер</mat-label>
                <mat-select formControlName="base">
                    <mat-option [value]="null">Отображать все контроллеры</mat-option>
                    <mat-option *ngFor="let key of objectKeys(desc.base)" [value]="desc.base[key].id">
                        <app-descr val="base" [id]="key"></app-descr>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label i18n>Метка</mat-label>
                <input type="text" matInput formControlName="metka_sn" [matAutocomplete]="auto">

                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let id of ids" [value]="id.serial|hex">{{id.serial|hex}} ({{id.phone}})
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose color="primary" i18n routerLink="/log" [queryParams]="query">
            Применить
        </button>
    </mat-dialog-actions>
</ng-template>
