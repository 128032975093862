import { Component, OnInit, forwardRef, OnDestroy, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseDescription, DescriptionService } from '../description.service';


@Component({
  selector: 'app-base-selector',
  templateUrl: './base-selector.component.html',
  styleUrls: ['./base-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseSelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BaseSelectorComponent),
      multi: true,
    }
  ]
})
export class BaseSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  $desc: Subscription;

  value: number[];
  enabled = true;

  @Input() label: string;
  @Input() hint: string;
  @Input() required: string;

  groupped: {
    group: string;
    bases: BaseDescription[];
  }[];
  ungroupped: BaseDescription[];

  constructor(public desc: DescriptionService) { }

  ngOnInit() {
    this.$desc = this.desc.update().subscribe({
      next: (desc) => {
        this.ungroupped = desc.as_array('base');
        this.groupped = desc.as_array('group').map(g => {
          return {
            group: g.description,
            bases: g.group_bases ? g.group_bases.split(',').map(n => {
              this.ungroupped.splice(this.ungroupped.findIndex(d => +d.id == +n), 1);
              return desc.base[n];
            }) : []
          };
        });
      }
    });
  }

  ngOnDestroy() {
    this.$desc.unsubscribe();
  }

  proxyChange(val: any) {
    if (this.onChange) {
      this.onChange(val.value);
    }
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  public validate(c: FormControl) { return null; }

  writeValue(selected: number[]): void {
    this.value = selected;
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void { this.onChange = fn; }
  registerOnTouched(fn: any): void { this.onTouched = fn; }
  setDisabledState?(isDisabled: boolean): void { this.enabled = !isDisabled; }
}
