<div class="mat-app-background basic-container">
  <mat-card>
    <form [formGroup]="base">
      <mat-card-title>
        <mat-icon *ngIf="data.CHANGE" i18n-matTooltip matTooltip="Идёт обновление меток">update</mat-icon>
        Контроллер "{{data.name}}"
        <span class="right">
          <mat-card-subtitle><span i18n>Время последней синхронизации </span><span
              [class]="data.in_sync?'time_ok':'time_error'">{{data.TIME}}</span>
          </mat-card-subtitle>
        </span>
      </mat-card-title>
      <mat-card-subtitle>
        <span i18n>Идентификационный номер </span><span>{{data.sn | hex}}</span>
      </mat-card-subtitle>
      <mat-card-content>
        <mat-form-field>
          <mat-label i18n>Группы</mat-label>
          <mat-select formControlName="group_ids" multiple>
            <mat-option *ngFor="let key of objectKeys(desc.group)" [value]="desc.group[key].id">
              <app-descr val="group" [id]="key"></app-descr>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput i18n-placeholder placeholder="Название" formControlName="name">
        </mat-form-field>

        <mat-form-field>
          <input matInput i18n-placeholder placeholder="Комментарий" formControlName="comment">
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Адрес регистрации блока</mat-label>
          <mat-select formControlName="address_common_id">
            <mat-option *ngFor="let key of objectKeys(desc.address)" [value]="desc.address[key].id">
              <app-descr val="address" [id]="key"></app-descr>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
    </form>
    <mat-card-actions>
      <h3 *ngIf="updating">
        <mat-progress-spinner diameter="32" mode="indeterminate"></mat-progress-spinner>
        <span class="updating">{{updateText}}</span>
      </h3>
      <button mat-button (click)="settings(data)" color="primary" i18n>Настройки</button>
      <button mat-button *ngIf="base.touched || base.dirty" [disabled]="base.invalid||updating" (click)="done(true)"
        color="primary" i18n>Сохранить изменения</button>
      <button mat-button *ngIf="base.touched || base.dirty" [disabled]="updating" (click)="cancel()" i18n>Отменить</button>
      <a mat-button (click)=downloadLog(data) color="primary" i18n>Загрузить журнал событий</a>
    </mat-card-actions>
  </mat-card>
</div>

<app-id 
  #ids 
  [external_filter]="{base:true}" 
  [dst_base]="{group_bases: [filter.base_id]}" 
  i18n-title title="Зарегистрированные за контроллером метки"
  [allow_add]="data.SERVERSYNC">

</app-id>
