import {Injectable} from '@angular/core';
import {AuthService} from './login/login.component';
import {UrlPathService} from './url-path.service';
import {HttpClient} from '@angular/common/http';
import {tap, flatMap, retryWhen, delay} from 'rxjs/operators';
import {ObjectService} from './object';
import {timer, Observable, of, throwError} from 'rxjs';

export class StateDirty {
    pend: number;
}

@Injectable({
    providedIn: 'root'
})
export class StateService extends StateDirty {
    $subscription: Observable<StateDirty>;
    progress = false;

    constructor(
        public auth: AuthService,
        public path: UrlPathService,
        public http: HttpClient,
        public obj: ObjectService,
    ) {
        super();
        this.$subscription = timer(500, 10 * 1000)
            .pipe(
                flatMap(() => {
                    if (this.auth.loggedIn) {
                        if (!this.progress) {
                            this.progress = true;
                            return this.http.get<StateDirty>(this.path.objectPend()).pipe(
                                tap(() => this.progress = false)
                            );
                        } else {
                            return of({pend: this.pend});
                        }
                    } else {
                        return throwError('not logged in!');
                    }
                }),
                tap((state) => {
                    if (this.pend && this.pend != state.pend) {
                        this.obj.get().subscribe();
                    }
                    this.pend = state.pend;
                }),
                retryWhen(errors => errors.pipe(delay(1000)))
            );
    }

    check() {
        return this.$subscription;
    }
}
