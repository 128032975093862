import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../login/login.component';
import {HttpClient} from '@angular/common/http';
import {AbstractControl} from '@angular/forms';
import {UrlPathService} from '../url-path.service';
import {UserData} from '../login/login.component';
import {Router} from '@angular/router';

import {MatSort, MatSortable} from '@angular/material/sort';

export class PasswordValidation {

    static MatchPassword(AC: AbstractControl) {
        const password = AC.get('password').value; // to get value in input tag
        if (password !== null && password.length < 2) {
            AC.get('password').setErrors({StrengthPassword: true});
        }
        const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
        if (confirmPassword !== null && confirmPassword.length > 0 && password !== confirmPassword) {
            AC.get('confirmPassword').setErrors({MatchPassword: true});
        } else {
            return null;
        }
    }
}

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    contact: FormGroup;
    company: FormGroup;

    pool: { all: number, empty: number, contract: string, licensetime: Date }[];

    error: null | string = null;
    success: null | string = null;
    updating = false;

    displayedColumns: string[] = ['contract', 'empty', 'all', 'licensetime'];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        public auth: AuthService,
        private http: HttpClient,
        private path: UrlPathService) {
    }

    prepare() {
        this.updating = true;
        this.success = null;
        this.error = null;
    }

    server_error() {
        this.success = null;
        this.error = 'Нарушена связь с сервером';
    }

    registerContact() {
        this.prepare();
        const user: UserData = this.contact.value;
        user.role = 1;
        this.http.post(this.path.registerUser(), JSON.stringify(user)).subscribe({
            next: (res: any) => {
                if (res.success !== undefined) {
                    this.success = res.success;
                    this.auth.setAuthorization(this.contact.value.email, this.contact.value.password).subscribe();
                } else {
                    this.error = res.error;
                }
            },
            error: () => this.server_error(),
            complete: () => this.updating = false
        });
    }

    updateContact() {
        const user: UserData = this.contact.value;
        user.role = 1;
        user.id = this.auth.id;
        if (user.passwordold !== null && user.passwordold.length) {
            // проверить старый пароль и если всё хорошо
            this.auth.setAuthorization(this.auth.email, this.contact.value.passwordold).subscribe(() => {
                // запросить обновление данных
                this.http.post(this.path.updateUser(), JSON.stringify(user)).subscribe(res => {
                    this.auth.setAuthorization(this.contact.value.email, this.contact.value.password).subscribe(() => {
                        console.log('User data changed successfuly');
                    }, () => {
                    });
                });
            }, () => {
            });
        } else {
            // запостить обновление данных
            this.http.post(this.path.updateUser(), JSON.stringify(user)).subscribe(res => {
                // обновить информацию об аутентификации
                this.auth.setAuthorization(this.contact.value.email, this.auth.password).subscribe(() => {
                    console.log('User data changed successfuly');
                }, () => {
                });
            });
        }
    }

    updateCompany() {
        this.prepare();
        this.http.post(this.path.registerCompany(), JSON.stringify(this.company.value)).subscribe({
            next: (res: any) => {
                this.success = res.success !== undefined ? res.success : null;
                this.error = res.error !== undefined ? res.error : null;
                if (this.success !== null) {
                    this.auth.getCompany().subscribe(() => console.log('Company updated'), () => this.initForm());
                }
            },
            error: () => this.server_error(),
            complete: () => this.updating = false
        });
    }

    login() {
        this.router.navigate(['/login/']);
    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.contact = this.fb.group({
            name: [this.auth.loggedIn ? this.auth.name : null, [Validators.required]],
            email: [this.auth.loggedIn ? this.auth.email : null, [Validators.required, Validators.email]],
            passwordold: [null],
            password: [null],
            confirmPassword: [null],

            position: [this.auth.loggedIn ? this.auth.position : null],
            phone: [this.auth.loggedIn ? this.auth.phone : null, Validators.required],
        }, {validator: PasswordValidation.MatchPassword});

        this.company = this.fb.group({
            name: [null, [Validators.required]],
            inn: [null, [Validators.pattern('(\\d{10}|\\d{12})')]],
            r_s: [null, [Validators.pattern('(\\d{20})')]],
            kor_s: [null, [Validators.pattern('(\\d{20})')]],
            jur_adr: [null, []],
            kpp: [null, [Validators.pattern('(\\d{9})')]],
            okpo: [null, [Validators.pattern('(\\d{8}|\\d{10})')]],
            bik: [null, [Validators.pattern('(\\d{9})')]],
            jur_phi: ['1'],
        });

        if (this.auth.loggedIn) {
          this.contact.controls['email'].disable(); // TODO! Когда будет готово про операторов криптопровайдера - закоментить эту строчку
          this.auth.getCompany().subscribe(res => {
                if (res !== null) {
                    this.company.patchValue(res);
                    // this.company.disable("jur_phi"); // раскоментировать, если нужно запретить смену лица
                    if (res.pool !== undefined && res.pool.pool !== undefined) {
                        this.pool = res.pool.pool;
                    }
                }
            });
        }
    }
}
