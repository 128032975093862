import { environment } from '../../environments/environment';

import { Component, AfterViewInit, OnInit, OnDestroy, Inject, Input, Injectable, ViewChild, ElementRef, HostListener } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { CanActivate, ActivatedRouteSnapshot, RoutesRecognized, RouterStateSnapshot, Router } from '@angular/router';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';

import { UrlPathService } from '../url-path.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { JsonPipe } from '@angular/common';
import { DescriptionService } from '../description.service';

export interface LoginDialogData {
  email: string;
  password: string;
}

export class UserData {
  id: number;
  email: string;
  role: number;
  name: string;
  phone: string;
  validated: boolean;
  confirmed_email: boolean;
  position: string;
  password?: string;
  passwordold?: string;
}

export class CompanyData {
  name: string;
  inn: string;
  r_s: string;
  kor_s: string;
  jur_adr: string;
  kpp: string;
  okpo: string;
  bik: string;
  pool: {
    company_name: string;
    pool: { all: number, empty: number, contract: string, licensetime: Date }[];
  };
  constructor() {
    // this.pool = {company_name: '', pool: []};
  }
}

function autoLoginEnabled() {
  return (environment.version === 'DEV'
    && (environment as any).login !== undefined && (environment as any).login !== null
    && (environment as any).password !== undefined && (environment as any).password !== null) ||
    (sessionStorage.getItem('email') !== null
      && sessionStorage.getItem('password') !== null
      && sessionStorage.getItem('validated') !== null
      && sessionStorage.getItem('id') !== null
      && sessionStorage.getItem('role') !== null
      && sessionStorage.getItem('version') === environment.version);
}

function getAuthData(field: string) {
  return (environment as any)[field] !== undefined ? (environment as any)[field] : sessionStorage.getItem(field);
}

@Injectable()
export class AuthService extends UserData implements LoginDialogData {
  @Output() needAuth: EventEmitter<any> = new EventEmitter<any>();
  @Output() auth: EventEmitter<UserData> = new EventEmitter<UserData>();

  company: CompanyData = new CompanyData();
  time_exp: Date = null;
  email = '';
  password = '';

  loggedIn = false;
  validated: boolean = false;

  isLoggedIn() { return this.loggedIn; }

  constructor(
    private http: HttpClient,
    private router: Router,
    private desc: DescriptionService,
    private path: UrlPathService) {
    super();

    if (sessionStorage.getItem('version') !== environment.version) {
      sessionStorage.clear(); // Если не та версия - всё стереть
    }

    if (autoLoginEnabled()) {
      this.email = getAuthData('email');
      this.password = getAuthData('password');
      this.id = getAuthData('id');
      this.role = getAuthData('role');
      this.validated = getAuthData('validated') == 'true';
      setTimeout(() => this.setAuthorization(this.email, this.password).subscribe(() => console.log('Logged in')));
    }
  }

  getAuthorizationToken() {
    return 'Basic ' + btoa(`${this.email}:${this.password}`);
  }

  logout() {
    this.email = '';
    this.password = '';
    this.loggedIn = false;
    this.company = new CompanyData();
    this.time_exp = null;

    this.desc.clear();

    sessionStorage.clear();
    this.router.navigate(['/login']).then(() => {
      console.log("refresh window");
      window.location.reload();
    });
  }

  getCompany(): Observable<CompanyData | null> {
    return new Observable((observer) => {
      this.http.get(this.path.company()).subscribe((res: CompanyData) => {
        this.company = res;
        if (res.pool !== undefined && res.pool.pool !== undefined) {
          for (let i = 0; i < res.pool.pool.length; ++i) {
            this.time_exp = new Date(res.pool.pool[i].licensetime);
          }
        }
        this.validated = res != null;
        sessionStorage.setItem('validated', this.validated ? 'true' : 'false');
        observer.next(this.company);
        observer.complete();
      }, () => {
        this.company = null;
        this.validated = false;
        observer.next(this.company);
        observer.complete();
      });
    });
  }

  update() {
    return this.setAuthorization(this.email, this.password);
  }

  setAuthorization(user, pass): Observable<UserData | null> {
    this.email = user;
    this.password = pass;

    return new Observable((observer) => {
      const f = () => {
        this.loggedIn = false;
        this.needAuth.emit();
        this.company = null;
        observer.error();
        observer.complete();
      };
      const s = (res) => {
        if (res.id !== undefined && res.id >= 0) {
          for (const i of Object.keys(res)) {
            this[i] = res[i];
          }
          this.loggedIn = true;
          sessionStorage.setItem('email', user);
          sessionStorage.setItem('password', pass);
          sessionStorage.setItem('role', res.role);
          sessionStorage.setItem('id', res.id);
          sessionStorage.setItem('validated', 'false');
          sessionStorage.setItem('version', environment.version);

          this.role = <number>(JSON.parse(res.role));
          this.getCompany().subscribe((company) => {
            this.auth.emit(res);

            observer.next();
            observer.complete();
          });
        } else {
          f();
        }
      };
      if (this.email.length === 0 && this.password.length === 0) {
        f();
      } else {
        this.http.get(this.path.login(), { responseType: 'json' }).subscribe(s, f);
      }
    });
  }
}

@Injectable()
export class LoginActivate implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (autoLoginEnabled()) {
      return this.auth.validated;
    }

    if (!this.auth.isLoggedIn()) {
      this.router.navigate(['login']);
    }
    return this.auth.validated;
  }
}

@Component({
  selector: 'app-login-dialog',
  templateUrl: 'login-dialog.html',
  styleUrls: ['./login.component.scss']
})
export class LoginDialog {
  failed = false;
  @ViewChild('email', { static: true }) email: ElementRef;
  @ViewChild('password', { static: true }) password: ElementRef;

  constructor(public dialogRef: MatDialogRef<LoginDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: LoginDialogData, private router: Router, public auth: AuthService) {
  }
  logout(): void {
    const refresh = () => window.location.reload();
    this.auth.setAuthorization('', '').subscribe(refresh, refresh, refresh);
  }
  login(): void {
    this.auth.setAuthorization(this.data.email, this.data.password).subscribe(() => {
      this.dialogRef.close(this.auth);
    }, () => {
      this.failed = true;
    });
  }
  registration(): void {
    console.log('Registration');
    this.dialogRef.close();
    this.router.navigate(['/registration/']);
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.login();
    }
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() showDialog = true;

  login = true;

  constructor(
    public dialog: MatDialog,
    public auth: AuthService,
    private path: UrlPathService,
    private router: Router,
    private http: HttpClient) { }

  openDialog(): void {
    if (this.dialog.openDialogs.length > 0) {
      return;
    }
    const dialogRef = this.dialog.open(LoginDialog, {
      panelClass: 'login-css',
      width: '450px',
      disableClose: true,
      data: { email: this.auth.email, password: this.auth.password },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`The dialog was closed ${result}`);
      if (result !== undefined) {
        this.router.navigate(result.validated ? ['/ids/'] : ['/registration/']);
      }
    });
  }

  confirm() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '550px',
      data: $localize`На адрес ${this.auth.email} будет отправлен код подтверждения. При необходимости адрес можно изменить администратор во вкладке "Информация о компании"`
    });

    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.http.get(this.path.confirm()).subscribe(() => {
          console.log("email sent");
        })
      }
    });
  }

  logoutDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize`Вы сейчас авторизованы как ${this.auth.name}. Хотите войти под другими учётными данными?`
    });

    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.auth.logout();
      }
    });
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }

  ngOnInit() {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.login = data.state.root.firstChild === null || data.state.root.firstChild.data === undefined || data.state.root.firstChild.data.login === undefined || data.state.root.firstChild.data.login;
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ((!this.auth.isLoggedIn() || this.showDialog) && this.login) {
        this.openDialog();
      }
    }, 100);
    this.auth.needAuth.subscribe(() => {
      console.log('Need relogin');
      this.openDialog();
    });
  }
}

@Component({
  selector: 'error-sheet',
  templateUrl: './error.sheet.html',
})
export class ErrorSheet {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<ErrorSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public text: any) {
    console.log(text);
  }
}

@Injectable()
export class AddHeaderLogin implements HttpInterceptor {
  constructor(private auth: AuthService, private bottomSheet: MatBottomSheet, private jsonPipe: JsonPipe) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    const clonedRequest = req.clone({ headers: req.headers.set('Authorization', this.auth.getAuthorizationToken()) });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body.error !== undefined && (event.body.userError === undefined || !event.body.userError)) {
          let message = `<dt>Сообщение об ошибке</dt><dd>${event.body.error}</dd>`;
          message += `<dt>url</dt><dd>${event.url}</dd>`;
          let fi = {
            code: 'errorCode',
            info: 'errorInfo',
            query: $localize`запрос`,
            cond: $localize`условия`,
            bind: 'bind',
            user_id: $localize`ID Пользователя`,
            role: $localize`Уровень доступа`,
            sent: $localize`В ответ на запрос к киптопровайдеру`,
            got: $localize`Получил ответ`,
          };

          for (let f in fi) {
            if (event.body[f] !== undefined) {
              if (typeof event.body[f] === 'object') {
                message += `<dt>${fi[f]}</dt><dd>${this.jsonPipe.transform(event.body[f])}</dd>`;
              } else {
                message += `<dt>${fi[f]}</dt><dd>${event.body[f]}</dd>`;
              }
            }
          }

          this.bottomSheet.open(ErrorSheet, { data: '<dl>' + message + '</dl>' });
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse && err.error && err.error.text !== undefined) {
          // do error handling here
          this.bottomSheet.open(ErrorSheet, { data: err.error.text });
        }
      }));
  }
}

