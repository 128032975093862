import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IDComponent } from './id/id.component';
import { LoginActivate, LoginComponent } from './login/login.component';
import { MetkaComponent } from './metka/metka.component';
import { BaseComponent, BaseSingleComponent } from './base/base.component';
import { BaseAddComponent } from './base/base.add.component';
import { DescrEditorComponent } from './descr-editor/descr-editor.component';
import { ExcelImporterComponent } from './excel-importer/excel-importer.component';
import { GroupDetailsComponent } from './group-details/group-details.component';
import { UsersComponent } from './users/users.component';
import { RegistrationComponent } from './registration/registration.component';
import { PasswordRestoreComponent } from './password-restore/password-restore.component';
import { ConfirmEmailComponent } from './password-restore/confirm_email.component';
import { NewPasswordComponent } from './password-restore/new-password.component';
import { ObjectCreateComponent } from './object/object.create';
import { ObjectComponent, ObjectSingleComponent } from './object/object.view';
import { ObjectDetailComponent } from './object/object.detail';

import { LoggerComponent } from './logger/logger.component';
import {RulesComponent} from './rules/rules.component';

/* navigation */
const appRoutes: Routes = [
  { path: 'ids',              component: IDComponent,                                                          canActivate: [LoginActivate] },
  { path: 'metka',            component: MetkaComponent,                                                       canActivate: [LoginActivate] },
  { path: 'base',             component: BaseComponent,                                                        canActivate: [LoginActivate] },
  { path: 'base/add',         component: BaseAddComponent,                                                     canActivate: [LoginActivate] },
  { path: 'base/:base_id',    component: BaseSingleComponent,                                                  canActivate: [LoginActivate] },
  { path: 'editor/:display',  component: DescrEditorComponent,                                                 canActivate: [LoginActivate] },
  { path: 'metka/mass',       component: ExcelImporterComponent,                                               canActivate: [LoginActivate] },
  { path: 'metka/:serial',    component: MetkaComponent,                                                       canActivate: [LoginActivate] },
  { path: 'objects',          component: ObjectComponent,                                                      canActivate: [LoginActivate] },
  { path: 'objects/new',      component: ObjectCreateComponent,                                                canActivate: [LoginActivate] },
  { path: 'objects/:object_id', component: ObjectDetailComponent,                                              canActivate: [LoginActivate] },
  { path: 'objects/:object_id/edit', component: ObjectDetailComponent,                                         canActivate: [LoginActivate] },
  { path: 'group-details',    component: GroupDetailsComponent,                                                canActivate: [LoginActivate] },
  { path: 'users',            component: UsersComponent,        data: { legend: 'users', role: 1 },     canActivate: [LoginActivate] },
  { path: 'log',              component: LoggerComponent,                                                      canActivate: [LoginActivate] },
  { path: 'registration',     component: RegistrationComponent, data: { legend: 'company', role: 1, login: false } },
  { path: 'login',            component: LoginComponent },
  { path: 'restore',          component: PasswordRestoreComponent },
  { path: 'access',           component: NewPasswordComponent },
  { path: 'confirm',          component: ConfirmEmailComponent },
  { path: 'pays',             component: RulesComponent },
  { path: '**',               redirectTo: 'login', pathMatch: 'full' },
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
