import {Component, OnInit, OnDestroy, ViewChild, Input, HostListener, Inject} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {MatSort, MatSortable} from '@angular/material/sort';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {Subscription, Observable, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';

import {GroupedObjectsDataSource, GroupBy} from '../grouped-objects-data-source';
import {UrlPathService} from '../url-path.service';
import {DescriptionService} from '../description.service';
import {BaseData, BaseService} from '../base';
import {FilterBuilder} from '../filter';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {IDComponent} from '../id/id.component';

export class FilterBase extends FilterBuilder {
    id?: string | null;
    user_id?: string | null;
    base_id?: string | null;
    group_description_id?: string | null;
    address_common_id?: string | null;

    constructor() {
        super();
    }
}

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy {
    @Input() external_filter: any = null;
    @Input() allow_add = true;

    objectKeys = Object.keys;
    params$: Subscription;
    update$: Subscription;
    $timeout;

    updating = true;
    updateText = 'Инициализация';

    edit_id: number | null = null;
    base: FormGroup;
    unmod: BaseData;

    @ViewChild(MatSort, {static: true}) sort: MatSort;

    displayedColumns = [
        'name',
        'sn',
        'address_common_id',
        'group_ids',
        'comment',
        'remote',
        'insync',
        'action',
    ];

    dataSource: GroupedObjectsDataSource<BaseData> =
        new GroupedObjectsDataSource<BaseData>('address_common_id', {
            'address_common_id': (id) => id === null ? $localize`не задан` : this.desc.address[id].address,
            'group_ids': (ids) => ids === null ? $localize`не задано` : ids.map((id) => this.desc.group[id].description).join(),
        });

    filter: FilterBase = new FilterBase();

    onload = (a: any[]) => {
    };
    onupdate = () => {
    };

    expand(field: string, value: string): FilterBase {
        const l = {};
        l[field] = value;
        return Object.assign({}, this.filter, l);
    }

    shrink(field: string): FilterBase {
        const l: FilterBase = Object.assign({}, this.filter);
        l[field] = null;
        return l;
    }

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public http: HttpClient,
        public path: UrlPathService,
        public basedata: BaseService,
        public dialog: MatDialog,
        public desc: DescriptionService
    ) {

    }

    ngOnDestroy(): void {
        this.params$.unsubscribe();
        if (this.$timeout) {
            clearTimeout(this.$timeout);
        }
    }

    ngOnInit() {
        this.desc.update().subscribe();
        if (this.sort !== undefined) {
            this.sort.sort(<MatSortable>({id: 'id', start: 'asc'}));
            this.dataSource.sort = this.sort;
        }
        this.cancel();

        const urlParams: Observable<any> = combineLatest(
            this.route.params,
            this.route.queryParams
        ).pipe(
            map(([params, queryParams]) => ({...params, ...queryParams}))
        );

        this.params$ = urlParams.subscribe({
            next: (query) => {
                if (query.id !== undefined) {
                    this.edit_id = query.id;
                }
                this.filter = new FilterBase();
                this.filter.set(query);

                this.get();
            },
            error: (err) => console.log(err),
            complete: () => console.log('complete')
        });
    }

    navigateTo(row: any) {
        const url = '/base/' + row.id;
        this.router.navigate([url]);
    }

    isGroup(index, item: GroupBy | BaseData): boolean {
        return (<GroupBy>item).isGroupBy;
    }

    subheader(
        sort: MatSort, def: string | number,
        dataSource: GroupedObjectsDataSource<BaseData>) {
        return def;
    }

    applyFilter($event: any) {
        this.dataSource.filter = $event.target.value.trim().toLowerCase();
    }

    silentUpdate() {
        if (this.edit_id !== null) {
            return;
        }

        this.get(true);
    }

    get(silent?: boolean) {
        if (silent === undefined || silent === false) {
            this.edit_id = null;
            this.updating = true;
            this.updateText = 'Обновление информации о контроллерах';
        }
        this.desc.update().subscribe(() =>
            this.http.post<BaseData[]>(this.path.base(), this.filter.get())
                .subscribe(
                    (array) => {
                        this.dataSource.data = array.map(e => this.basedata.cook(e));
                        this.onload(array);
                        this.updating = false;

                        if (this.dataSource.data.some(b => (b as BaseData).CHANGE)) {
                            this.$timeout = setTimeout(() => this.silentUpdate(), 10000);
                        }
                    }
                ));
    }

    edit(element: BaseData) {
        this.edit_id = element.id;
        this.base = this.basedata.form(element);
        this.unmod = element;
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.edit_id !== null && event.key === 'Enter' && this.base.valid) {
            this.done(true);
        }
        if (this.edit_id !== null && event.key === 'Escape') {
            this.cancel();
        }
    }


    cancel() {
        this.edit_id = null;
        this.base = this.basedata.form(this.unmod);
    }

    done(confirm?: boolean) {
        const edit_id = this.edit_id;
        this.edit_id = null;

        if (confirm !== undefined && confirm) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '350px',
                data: $localize`Применить изменения?`
            });

            dialogRef.afterClosed().subscribe({
                next: (res) => {
                    if (res) {
                        this.done();
                    } else {
                        this.edit_id = edit_id;
                    }
                }
            });
        } else {
            this.updating = true;
            this.updateText = $localize`Отправка обновлений на сервер`;
            this.basedata.set(this.unmod, this.base.value).subscribe({
                error: () => {
                    this.updating = false;
                },
                complete: () => {
                    this.get();
                    this.onupdate();
                    this.updating = false;
                    this.desc.update(true).subscribe();
                }
            });
        }
    }

    delete(element: BaseData) {
        this.basedata.delete(element.id).subscribe(() => this.get());
    }

    downloadLog(element: BaseData) {
        this.basedata.log(element.id);
    }

    open_controller(base_id: number, relay: number) {
        this.basedata.open(base_id, relay).then(r => {
            this.get();
            this.onupdate();
            this.updating = false;
        });
    }
}

@Component({
    selector: 'app-base-dialog',
    templateUrl: './base.dialog.component.html',
    styleUrls: ['./base.dialog.component.scss']
})
export class BaseDialogComponent implements OnInit {
    base: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<BaseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BaseData,
        public basedata: BaseService,
        public desc: DescriptionService
    ) {

    }

    ngOnInit() {
        this.base = this.basedata.form(this.data);
    }

    save() {
        this.dialogRef.close(this.base.value);
    }

    close() {
        this.dialogRef.close();
    }
}


@Component({
    selector: 'app-base-single',
    templateUrl: './base.single.component.html',
    styleUrls: ['./base.single.component.scss']
})
export class BaseSingleComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('ids', {static: true}) ids: IDComponent;

    data: BaseData = new BaseData();

    onload = (a: any[]) => {
        for (let i = 0; i < a.length; ++i) {
            if (this.filter.base_id !== undefined && this.filter.base_id === a[i].id) {
                this.data = this.basedata.cook(a[i]);
                this.edit(a[i]);
            }
        }
    };

    onupdate = () => {
        this.ids.get();
    };

    settings(data: BaseData) {
        const dialogRef = this.dialog.open(BaseDialogComponent, {
            width: '620px',
            closeOnNavigation: true,
            disableClose: false,
            hasBackdrop: true,

            data: data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                this.base.patchValue(result);
                this.done();
            }
        });
    }


    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public http: HttpClient,
        public path: UrlPathService,
        public basedata: BaseService,
        public dialog: MatDialog,
        public desc: DescriptionService,
    ) {
        super(router, route, http, path, basedata, dialog, desc);
    }

}
