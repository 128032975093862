<form>
  <mat-card>
    <mat-card-title i18n>Восстановление пароля</mat-card-title>
    <mat-card-content [formGroup]="emailForm">
      <mat-form-field>
        <input type="email" matInput placeholder="Email" formControlName="email">
        <mat-hint>Введите email</mat-hint>
        <mat-error *ngIf="emailForm.hasError('email', 'email') && !emailForm.hasError('required', 'email')" i18n>
          Ошибка при вводе email
        </mat-error>
        <mat-error *ngIf="emailForm.hasError('required', 'email')" i18n>
          Для восстановления пароля email необходим
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input type="text" autocomplete="username" matInput placeholder="UID" formControlName="uid">
        <mat-hint i18n>Введите код востановления пароля из письма</mat-hint>
        <mat-error *ngIf="emailForm.hasError('required', 'uid')" i18n>
          Для восстановления пароля код <strong>необходим</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input type="password" autocomplete="new-password" required matInput i18n-placeholder placeholder="Пароль" formControlName="password">
        <mat-hint i18n>
          Пароль должен состоять как минимум из 3 символов!
        </mat-hint>
        <mat-error *ngIf="(emailForm.touched || emailForm.dirty) && emailForm.hasError('StrengthPassword','password')" i18n>
          Слишком простой пароль
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input type="password" autocomplete="new-password" required matInput i18n-placeholder placeholder="Подтверждение пароля"
               formControlName="confirmPassword">
        <mat-hint i18n>
          Повторите ввод пароля
        </mat-hint>
        <mat-error
                *ngIf="(emailForm.touched || emailForm.dirty) && emailForm.hasError('MatchPassword','confirmPassword')" i18n>
          Введённые пароли должны совпадать
        </mat-error>

      </mat-form-field>

    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="apply()" i18n>Продолжить</button>
    </mat-card-actions>
  </mat-card>
</form>
