import {Component, OnInit, OnDestroy, ViewChild, Input, HostListener} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {map, finalize, tap, mergeMap, ignoreElements, debounceTime} from 'rxjs/operators';
import {Subscription, Observable, combineLatest, forkJoin, defer, merge, Subject, of} from 'rxjs';

import {MatDialog} from '@angular/material/dialog';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';

import {GroupedObjectsDataSource, GroupBy} from '../grouped-objects-data-source';
import {UrlPathService} from '../url-path.service';
import {DescriptionService} from '../description.service';
import {IDData, IDDataService} from '../iddata';
import {MetkaDialogComponent} from '../metka/metka.component';
import {FormControl, FormGroup} from '@angular/forms';

import {FilterBuilder} from '../filter';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {AuthService} from '../login/login.component';
import {dec2hex} from '../hex.pipe';
import {query} from '@angular/animations';

export class FilterID extends FilterBuilder {
    group_description_id?: string | null;
    user_id?: string | null;
    address_common_id?: string | null;
    status_id?: string | null;
    metka_type_id?: string | null;
    base_id?: string | null;

    constructor() {
        super();
    }
}

@Component({
    selector: 'app-id',
    templateUrl: './id.component.html',
    styleUrls: ['./id.component.scss']
})
export class IDComponent implements OnInit, OnDestroy {
    objectKeys = Object.keys;
    params$: Subscription;
    get$: Subscription;
    timeout$: any;
    desc$: Subscription;
    update$: Subscription;

    edit_id: string | null = null;
    metka: FormGroup;
    unmod: IDData;
    updating = true;
    pend_update = false;
    updating_progress = 0;
    need_update: boolean;

    add_group_ids: number | null = null;
    add_base_id: number | null = null;
    remove_group_ids: number | null = null;
    remove_base_id: number | null = null;

    remote_open = 'default';
    group_bases_remote = new FormControl();
    selected_remote = -1;

    avaible_groups_to_remove = [];
    avaible_bases_to_remove = [];

    @Input() title = $localize`Зарегестрированные метки`;
    @Input() external_filter: any = null;
    @Input() allow_add = true;
    @Input() dst_base: any = null;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    displayedColumns: string[];

    now = new Date();

    dataSource: GroupedObjectsDataSource<IDData> =
        new GroupedObjectsDataSource<IDData>('metka_type_id', {
            'address_common_id': (id) => this.desc.get('address', id),
            'metka_type_id': (id) => this.desc.get('metka_type', id),
            'user_id': (id) => this.desc.get('user', id),
            'status_id': (id) => this.desc.get('status', id),
            'time_exp': (date) => date instanceof Date ? date.toLocaleDateString() : $localize`не задан`,
            'group_ids': (ids) => (ids === null || ids.length === 0) ? $localize`не задано` : ids.map((id) => this.desc.get('group', id)).join(),
        });
    filter: FilterID = new FilterID();

    selection = new SelectionModel<number>(true, []);
    group_selector = false;

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    get_selection() {
        this.dataSource.data = this.dataSource.data.filter((el) => this.selection.selected.indexOf((el as IDData).id) >= 0);
        this.avaible_groups_to_remove = ([].concat(
            ...this.dataSource.data.map(el => (<IDData>(el)).group_ids)))
            .filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
        this.avaible_bases_to_remove = ([].concat(
            ...this.dataSource.data.map(el => (<IDData>(el)).group_bases)))
            .filter((value, index, self) => {
                return Number(value) !== -1 && self.indexOf(value) === index;
            });
        this.group_selector = true;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach((row) => this.selection.select((row as IDData).id));
    }

    checkboxLabel(row?: IDData | GroupBy): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected((row as IDData).id) ? 'deselect' : 'select'} row ${(<IDData>row).id + 1}`;
    }

    expand(field: string, value: string): FilterID {
        const l = {};
        l[field] = value;
        return Object.assign({}, this.filter, l);
    }

    shrink(field: string): FilterID {
        const l: FilterID = Object.assign({}, this.filter);
        l[field] = null;
        return l;
    }

    initDisplayedColumns() {
        this.displayedColumns = [
            'change_state',
            'select',
            'serial',
            'group_ids',
            'group_bases',
            'group_remote_bases',
            'objects',
            'phone',
            'comment',
            'address_common_id',
            'address_local',
            'status_id',
            'metka_type_id',
            'user_id',
            'activation_time',
            'time_exp',
            'action',
        ];
        this.displayedColumns = this.displayedColumns.filter((f) => {
            return this.iddata.display[f] === undefined || this.iddata.display[f] === true;
        });
    }

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private path: UrlPathService,
        private router: Router,
        public iddata: IDDataService,
        public dialog: MatDialog,
        public desc: DescriptionService,
        public auth: AuthService,
    ) {
    }

    ngOnDestroy(): void {
        this.params$.unsubscribe();
        this.get$.unsubscribe();
        this.desc$.unsubscribe();
        this.update$.unsubscribe();

        if (this.timeout$) {
            clearTimeout(this.timeout$);
        }
    }

    add_metka() {
        this.dialog.open(MetkaDialogComponent, {
            width: '620px',
            closeOnNavigation: true,
            disableClose: false,
            hasBackdrop: true,

            data: this.dst_base
        });
    }

    edit_metka(serial?: string | null) {
        this.dialog.open(MetkaDialogComponent, {
            width: '620px',
            closeOnNavigation: true,
            disableClose: false,
            hasBackdrop: true,

            data: {serial: serial}
        });
    }

    ngOnInit() {
        this.initDisplayedColumns();

        this.group_selector = false;
        this.desc.update().subscribe();
        this.sort.sort(<MatSortable>({id: 'metka_type_id', start: 'asc'}));

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.cancel();

        const urlParams: Observable<any> = combineLatest([
            this.route.params,
            this.route.queryParams
        ]).pipe(
            map(([params, queryParams]) => ({...params, ...queryParams}))
        );

        this.params$ = urlParams
            .subscribe({
                next: (query) => {
                    console.log(query);
                    this.filter = new FilterID();
                    this.filter.set(query);

                    this.initDisplayedColumns();
                    this.displayedColumns = this.displayedColumns.filter((f) => {
                        return query[f] === undefined;
                    });

                    this.get();
                },
                error: (err) => console.log(err),
                complete: () => console.log('complete')
            });

        this.update$ = this.iddata.afterUpdate
            .pipe(debounceTime(20000))
            .subscribe({
                next: () => this.get()
            });
    }

    isGroup(index, item: GroupBy | IDData): boolean {
        return (<GroupBy>item).isGroupBy;
    }

    subheader(
        sort: MatSort, def: string | number,
        dataSource: GroupedObjectsDataSource<IDData>) {
        return def;
    }

    applyFilter(event: any) {
        this.dataSource.filter = event.target.value.trim().toLowerCase();
    }

    checkIfNeverDelete(el: IDData): boolean {
        if (el.change_state !== 3) {
            return false;
        }

        for (let i = 0; i < el.group_bases.length; ++i) {
            if (this.desc.base[el.group_bases[i]].TAGSYNC === 1) {
                return false;
            }
        }
        return true;
    }

    get() {
        this.updating = true;
        if (this.pend_update) {
            return;
        }
        this.pend_update = true;
        this.desc$ = this.desc.update().subscribe(() => {
            this.get$ = this.http.post<IDData[]>(this.path.metka(), this.filter.get(this.external_filter == null ? {} : this.external_filter))
                .subscribe(
                    (array) => {
                        this.pend_update = false;
                        if (this.timeout$) {
                            clearTimeout(this.timeout$);
                            this.timeout$ = null;
                        }
                        this.dataSource.data = array
                            .map(e => this.iddata.cook(e))
                            .filter(e =>
                                this.filter.base_id === undefined
                                || (e.group_bases as string[]).indexOf(this.filter.base_id) >= 0)
                            .filter(e => !this.checkIfNeverDelete(e));

                        this.need_update = array.length && (array.map(e => isNaN(Number(e.change_state)) ? 0 : Number(e.change_state)).reduce((a: number, b: number): number => a + b) > 0);
                        if (this.need_update) {
                            if (this.displayedColumns.indexOf('change_state') < 0) {
                                this.displayedColumns.unshift('change_state');
                            }
                            // TODO: При logout не дропаются таймеры обновления - идут левые запросы
                            if (!this.timeout$) {
                                this.timeout$ = setTimeout(() => this.get(), 20000);
                            }
                        } else {
                            this.updating = false;
                            this.displayedColumns = this.displayedColumns.filter(e => e !== 'change_state');
                        }
                    }
                );
        });
    }

    edit(idData: IDData) {
        this.initDisplayedColumns();
        this.edit_id = idData.serial;
        this.metka = this.iddata.form(idData);
        this.unmod = idData;
    }

    delete(idData: IDData) {
        this.updating = true;
        if (idData.id !== undefined) {
            this.iddata.delete(idData).subscribe({complete: () => this.get()});
        } else {
            this.iddata.delete(<IDData>({'serial_hex': idData.serial_hex})).subscribe({complete: () => this.get()});
        }
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.edit_id !== null && event.key === 'Enter' && this.metka.valid) {
            this.done(true);
        }
        if (this.edit_id !== null && event.key === 'Escape') {
            this.cancel();
        }
    }

    cancel() {
        this.edit_id = null;
        this.metka = this.iddata.form();
    }

    done(confirm?: boolean) {
        const edit_id = this.edit_id;
        this.edit_id = null;

        if (confirm !== undefined && confirm) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '350px',
                data: $localize`Применить изменения?`
            });

            dialogRef.afterClosed().subscribe({
                next: (res) => {
                    if (res) {
                        this.done();
                    } else {
                        this.edit_id = edit_id;
                    }
                }
            });
        } else {
            this.updating = true;
            this.iddata.set(this.unmod, this.metka.getRawValue()).subscribe({complete: () => this.get()});
        }
    }

    updateFromGroups() {
        this.iddata.updateFromGroups(this.metka, this.desc);
    }

    downloadLog(idData: IDData) {
        this.iddata.log(idData.id);
    }


    removeSelectedGroup() {
        this.updating = true;
        const arrayOfObservables = [];
        for (let i = 0; i < this.selection.selected.length; ++i) {
            const src = this.dataSource.data.find((el: IDData) => el.id === this.selection.selected[i]) as IDData;
            const remove_group = this.remove_group_ids.toString();

            let group_ids = <string[]>(src.group_ids);
            let group_bases = <string[]>(src.group_bases);

            // Убираем из списка групп
            group_ids = group_ids.filter((e) => e !== remove_group);
            // Эти контроллеры необходимо удалить
            const remove_bases = this.desc.group[remove_group].group_bases === null
                ? []
                : this.desc.group[remove_group].group_bases.split(',');
            // чистим список контроллеров так, чтобы удаляемых небыло в списке
            group_bases = group_bases.filter(e => remove_bases.indexOf(e) < 0);

            // Лечим список контроллеров так, чтобы положенные восстановились
            for (let k = 0; k < group_ids.length; ++k) {
                if (this.desc.group[group_ids[k]].group_bases !== null) {
                    group_bases.concat(this.desc.group[group_ids[k]].group_bases.split(','));
                }
            }

            // убираем повторы
            group_bases = group_bases.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });

            const dst = Object.assign(src, {
                'id': src.id, 'serial_hex': src.serial_hex, 'group_ids': group_ids, 'group_bases': group_bases
            });

            if (dst.group_bases.length) {
                arrayOfObservables.push(this.iddata.set({'id': src.id}, dst));
            } else {
                arrayOfObservables.push(this.iddata.delete(dst));
            }
        }
        this.progress(arrayOfObservables).then(() => {
            this.selection.clear();
            this.group_selector = false;
            this.get();
        });
    }

    async removeSelectedBase() {
        this.updating = true;
        const arrayOfObservables = [];
        const remove_base = this.remove_base_id.toString();
        let is_delete = false;
        for (let i = 0; i < this.selection.selected.length; ++i) {
            const src = this.dataSource.data.find((el: IDData) => el.id === this.selection.selected[i]) as IDData;

            const group_bases = src.group_bases as String[];
            const idx = group_bases.indexOf(remove_base);
            if (idx < 0) {
                continue;
            }
            group_bases.splice(idx, 1);
            const dst = Object.assign(src, {
                'id': src.id, 'serial_hex': src.serial_hex, 'group_bases': group_bases
            });

            if (dst.group_bases.length) {
                arrayOfObservables.push(this.iddata.set({'id': src.id}, dst));
            } else {
                is_delete = true;
                arrayOfObservables.push(this.iddata.delete(dst));
            }
        }
        if (is_delete) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '350px',
                data: $localize`Некоторые метки будут удалены. Продолжить?`
            });

            const dialogRes = await dialogRef.afterClosed().toPromise();
            if (!dialogRes) {
                return;
            }
        }

        this.progress(arrayOfObservables).then(
            () => {
                this.selection.clear();
                this.group_selector = false;
                this.get();
            });
    }

    async remoteSelectedMetka(mode: string) {
        this.updating = true;
        const arrayOfObservables = [];
        let query_dialog;
        for (let i = 0; i < this.selection.selected.length; ++i) {
            const src = this.dataSource.data.find((el: IDData) => el.id === this.selection.selected[i]) as IDData;
            const dst = {...src};
            const group_bases_remote_set = new Set<number>((src.group_bases_remote as string[]).map(s => Number(s)));
            switch (mode) {
                case 'disallow':
                    dst.REMOTE = '0';
                    query_dialog = $localize`Выбранным меткам будет запрещена возможность удалённого открытия. Продолжить?`;
                    break;
                case 'base':
                    dst.REMOTE = '-1';
                    query_dialog = $localize`Для выбранных меток возможность удалённого открытия будет определяться контроллером. Продолжить?`;
                    break;
                case 'individual_add':
                    dst.REMOTE = '1';
                    this.group_bases_remote.value.forEach(v => group_bases_remote_set.add(Number(v)));
                    query_dialog = $localize`На указанных контроллерах выбранным меткам будет предоставлена возможность удалённого открытия. Продолжить?`;
                    dst.group_bases_remote = Array.from(group_bases_remote_set);
                    break;
                case 'individual_remove':
                    query_dialog = $localize`На указанных контроллерах выбранным меткам будет запрещена возможность удалённого открытия. Продолжить?`;
                    this.group_bases_remote.value.forEach(v => group_bases_remote_set.delete(Number(v)));
                    dst.group_bases_remote = Array.from(group_bases_remote_set);
                    break;
            }

            arrayOfObservables.push(this.iddata.set(src, dst));
        }
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: query_dialog
        });

        const dialogRes = await dialogRef.afterClosed().toPromise();
        if (!dialogRes) {
            return;
        }

        this.progress(arrayOfObservables).then(
            () => {
                this.selection.clear();
                this.group_selector = false;
                this.get();
            });
    }

    async removeSelectedMetka() {
        this.updating = true;
        const arrayOfObservables = [];
        for (let i = 0; i < this.selection.selected.length; ++i) {
            const src = this.dataSource.data.find((el: IDData) => el.id === this.selection.selected[i]) as IDData;
            arrayOfObservables.push(this.iddata.delete(src, false));
        }
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: $localize`Выбранные метки будут удалены. Продолжить?`
        });

        const dialogRes = await dialogRef.afterClosed().toPromise();
        if (!dialogRes) {
            return;
        }

        this.progress(arrayOfObservables).then(
            () => {
                this.selection.clear();
                this.group_selector = false;
                this.get();
            });
    }

    async addSelectedGroup() {
        this.updating = true;
        await this.auth.getCompany().toPromise();
        for (let i = 0; i < this.selection.selected.length; ++i) {
            const src = this.dataSource.data.find((el: IDData) => el.id === this.selection.selected[i]) as IDData;
            const new_group = this.add_group_ids.toString();

            const group_ids = <string[]>(src.group_ids);
            let group_bases = <Number[]>(src.group_bases);

            group_ids.push(new_group);
            if (this.desc.group[new_group].group_bases !== null) {
                group_bases.concat(this.desc.group[new_group].group_bases.split(','));
            }

            // убираем повторы и отрицательные номера
            group_bases = this.repairGroupBases(group_bases);

            const dst = {
                ...(Number(src.metka_type_id) !== 3
                    ? src
                    : this.autoPhoneMetka(src))
                , group_bases: group_bases
                , group_ids: group_ids
            };

            const resp = await this.iddata.set({'id': src.id}, dst).toPromise() as { serial: string };
            // console.log(resp);
            src.serial = resp.serial;
            src.serial_hex = dec2hex(resp.serial);
        }

        this.selection.clear();
        this.group_selector = false;
        this.get();
    }

    private repairGroupBases(group_bases: (string | Number)[]): Number[] {
        return group_bases.map(v => Number(v)).filter((value, index, self) => {
            return value !== -1 && self.indexOf(value) === index;
        });
    }

    async addSelectedBase() {
        this.updating = true;
        await this.auth.getCompany().toPromise();

        const new_base = Number(this.add_base_id);
        const selected = this.selection.selected;
        for (let i = 0; i < selected.length; ++i) {
            this.updating_progress = 100 * i / this.selection.selected.length;
            const srcIdx = this.dataSource.data.findIndex((el: IDData) => el.id === selected[i]);

            if (srcIdx < 0) {
                continue;
            }

            const src = this.dataSource.data[srcIdx] as IDData;
            if ((src.group_bases as number[]).findIndex(e => Number(e) === Number(new_base)) >= 0) {
                console.log('Skipped: ' + src);
                continue;
            }
            let group_bases = src.group_bases as Number[];
            group_bases.push(new_base);
            // убираем повторы и отрицательные номера
            group_bases = this.repairGroupBases(group_bases);

            const dst = {
                ...(Number(src.metka_type_id) !== 3
                    ? src
                    : this.autoPhoneMetka(src))
                , group_bases: group_bases
            } as IDData;

            const resp = await this.iddata.set({'id': src.id}, dst).toPromise() as { serial: string };
            const data = this.dataSource.data as IDData[];
            dst.serial = resp.serial;
            dst.serial_hex = dec2hex(resp.serial);
            data[srcIdx] = dst;
            this.dataSource.data = data;
        }

        this.selection.clear();
        this.group_selector = false;
        this.updating = false;
        this.get();
    }

    private autoPhoneMetka(src: IDData) {
        return {
            metka_type_id: 1,
            time_exp: this.auth.time_exp,
            address_common_id: src.address_common_id,
            address_local: src.address_local,
            comment: src.comment,
            id: src.id,
            phone: src.phone,
            serial_auto: true,
            status_id: 2,
            user_id: src.user_id,
        };
    }

    async progress(arrayOfObservables: Observable<any>[]): Promise<any> {
        for (let i = 0; i < arrayOfObservables.length; ++i) {
            this.updating_progress = 100 * i / arrayOfObservables.length;
            await arrayOfObservables[i].toPromise();
        }
        return true;
    }

    inverseState(idData: IDData) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: idData.status_id === 2 ? $localize`Деактивировать метку?` : $localize`Активировать метку?`
        });

        dialogRef.afterClosed().subscribe({
            next: (res) => {
                if (res) {
                    this.updating = true;
                    const was = Object.assign({}, idData);
                    const want = idData;
                    want.status_id = idData.status_id === 2 ? 1 : 2;
                    this.iddata.set(was, want).subscribe({complete: () => this.get()});
                }
            }
        });
    }

    controls_callback(id) {
        this.router.navigate(['/base', id]).then();
    }

    objects_callback(id) {
        this.router.navigate(['/objects', id]).then();
    }

    groups_callback(id) {
        this.router.navigate(['/ids'], {queryParams: this.expand('group_description_id', id)}).then();
    }
}
