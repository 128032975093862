<form [formGroup]="emailForm" (ngSubmit)="apply()">
  <mat-card>
    <mat-card-title i18n>Подтверждение email</mat-card-title>
    <mat-card-content>
      <mat-form-field>
        <input type="text" matInput i18n-placeholder placeholder="Код подтверждения" formControlName="uid">
        <mat-hint i18n>Введите код подтверждения, пришедший на email</mat-hint>
        <mat-error *ngIf="emailForm.get('uid').hasError('required')" i18n>
          Для подтверждения email необходим код подтверждения
        </mat-error>
      </mat-form-field>
      <p *ngIf="success == false" class="warn" i18n>Ошибка при подтверждении Email. проверьте правильность введённых данных</p>
      <a *ngIf="success == true"  class="primary" [routerLink]="'/'" i18n>Email подтверждён. Можете продолжать работу</a>
    </mat-card-content>
    <mat-card-actions>
      <button type="submit" mat-raised-button [disabled]="!emailForm.valid || sent" i18n>Продолжить</button>
    </mat-card-actions>
  </mat-card>
</form>
