<ng-container *ngIf="object">
    <h3 matSubheader>
        <span class="obj_name">{{object.name}}</span>
        <span class="obj_address" i18n>Адрес: <app-descr val="address" [id]="object.address"></app-descr></span>
        <span class="obj_type">
      (<app-descr val="construct" [id]="object.type_id"></app-descr>)
    </span>
        <span class="obj_user" i18n>
      Ответственный: <app-descr val="user" [id]="object.user"></app-descr>
    </span>
    </h3>
    <h4 matLine class="tariff_head" *ngIf="object.tariff.starterkit" i18n>
        Тариф: starterkit
    </h4>
    <ng-container *ngIf="object.tariff.starterkit == 0">
        <h4 matLine class="tariff_head" i18n>Тариф: ELS Cloud Pro</h4>
        <p matLine class="tariff_body" *ngIf="object.tariff.freePeriod" i18n>
            Бесплатный пробный период {{obj.getMonth(object.tariff.freePeriod)}}
        </p>
        <ng-container *ngFor="let g of object.tariff.grid">
            <p matLine class="tariff_body" *ngIf="g.apply" i18n>
                {{obj.getMonth(g.monthes)}} стоят {{g.payment}} рублей
            </p>
        </ng-container>
    </ng-container>
    <h5 mat-line *ngIf="object.REMOTE">На объекте разрешено открытие через интернет</h5>
    <p *ngIf="object.stat" matLine>
        <!-- пользователей всего: {{object.stat.total}}, -->
        <ng-container *ngIf="object.stat.allow && object.stat.allow>0" i18n>допущенных
            <button mat-mini-fab disabled>{{object.stat.allow}}</button>
        </ng-container>
        <ng-container *ngIf="object.stat.denied && object.stat.denied > 0" i18n>запрещённых
            <button mat-mini-fab color="accent">{{object.stat.denied}}</button>
        </ng-container>
        <ng-container *ngIf="object.stat.pend && object.stat.pend>0" i18n>запросов на подключение
            <button mat-mini-fab color="primary"
                    [routerLink]="['/objects',object.object_id]"
            >{{object.stat.pend}}</button>
        </ng-container>
    </p>
    <!--TODO: Для тарифа стартер кид выводить общее количество доступных пользователей -->
    <p matLine>{{object.extra}}</p>
    <ng-container *ngIf="object.tkds;else errortkds">
        <ng-container *ngIf="object.tkds.length>1;else single">
            <p matLine *ngFor="let t of object.tkds">
                <span *ngIf="t.name" class="tkd_name">{{t.name}}:</span>
                <span *ngIf="t.numbers" class="tkd_numbers">{{t.numbers}}</span>
                <span class="tkd_bases">
        <app-descr-expand val="base" [ids]="t.bases" i18n-title title="Контроллеры в группе контроля доступа">
        </app-descr-expand>
      </span>
            </p>
        </ng-container>

        <ng-template #single>
            <p matLine *ngFor="let t of object.tkds">
                <span *ngIf="t.name" class="tkd_name">{{t.name}}:</span>
                <span *ngIf="t.numbers" class="tkd_numbers">{{t.numbers}}</span>
                <span class="tkd_bases">
        <span i18n> Контроллеры в группе контроля доступа: </span>
        <app-descr-expand val="base" [ids]="t.bases" i18n-title title="Контроллеры в группе контроля доступа">
        </app-descr-expand>
      </span>
            </p>
        </ng-template>
    </ng-container>
</ng-container>
<ng-template #errortkds>
    <button mat-button color="warn">Не может существовать объекта без ТКД!</button>
</ng-template>
