import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { UrlPathService } from '../url-path.service';
import { Router } from '@angular/router';
import { BaseService } from '../base';
import {DescriptionService} from '../description.service';

interface BaseAddAns {
  id: number;
  error?: string;
  success?: string;
}

@Component({
  selector: 'app-base-add',
  templateUrl: './base.add.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseAddComponent implements OnInit {

  base: FormGroup;
  success: string | null = null;
  error: string | null = null;
  num_mask = [
    'E', '7', ':',
    /[0-9a-fA-F]/, /[0-9a-fA-F]/, ':',
    /[0-9a-fA-F]/, /[0-9a-fA-F]/, ':',
    /[0-9a-fA-F]/, /[0-9a-fA-F]/, ':',
    /[0-9a-fA-F]/, /[0-9a-fA-F]/, ':',
    /[0-9a-fA-F]/, /[0-9a-fA-F]/, ':',
    /[0-9a-fA-F]/, /[0-9a-fA-F]/, ':',
    /[0-9a-fA-F]/, /[0-9a-fA-F]/
  ];

  num_hash = [
    /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, '-',
    /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, '-',
    /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, '-',
    /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/
  ];

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private path: UrlPathService,
    public router: Router,
    public basedata: BaseService,
    public desc: DescriptionService,
  ) { }

  ngOnInit() {
    this.base = this.fb.group({
      SN: [{ value: null }, Validators.required],
      HASH: [{ value: null }, Validators.required],
      name: [{ value: null }, Validators.required],
    });
    this.base.patchValue({ SN: 'E7', HASH: '', name: '' });
  }


  append() {
    this.http.get<BaseAddAns>(this.path.baseAdd(this.base.value.SN.split(':').join(''), this.base.value.HASH.split('-').join(''))).subscribe({
      next: (res: BaseAddAns) => {
        if (res.success !== undefined) {
          this.success = res.success;
        }
        if (res.id !== undefined && res.id > 0) {

          this.basedata.set(null, {...this.base.value, ...{id: res.id}}).subscribe(() => {
            console.log(`Registered base module with SN: ${this.base.value.SN} to id: ${res.id}`);
            const url = '/base/' + res.id;
            this.desc.update(true).subscribe();
            this.router.navigate([url]);
          });
        } else {
          this.error = res.error ? res.error : $localize`Неизвестная ошибка`;
        }
      }
    });
  }

}


