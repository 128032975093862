<app-navbar></app-navbar>
<mat-sidenav-container>
  <mat-sidenav 
    mode="side" 
    [opened]="auth.loggedIn && auth.validated" 
    [disableClose]="true"
    >
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<footer>
  <div>{{version}}</div>
</footer>