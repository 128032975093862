import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatStepper} from '@angular/material/stepper';

import * as XLSX from 'xlsx';

import {DescriptionService} from '../description.service';
import {IDData, IDDataService} from '../iddata';
import {UrlPathService} from '../url-path.service';
import {HttpClient} from '@angular/common/http';

import {CountryCode, parsePhoneNumberFromString} from 'libphonenumber-js';
import {Country, CountryCodeList} from './country-code';

class ExcelLine {
  common: string | number;
  local: string;
  phone: string;
  comment: string;

  error?: string;

  good?: boolean;
  importable?: boolean;
  single?: boolean;
  exist?: boolean;
  serial?: string;
  metka_type_id?: number | string;
}

@Component({
  selector: 'app-excel-importer',
  templateUrl: './excel-importer.component.html',
  styleUrls: ['./excel-importer.component.scss'],
  providers: [
    CountryCodeList
  ]
})
export class ExcelImporterComponent implements OnInit {
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  objectKeys = Object.keys;

  file_name: any;
  workbook: any;
  header = ['common', 'local', 'phone', 'comment', 'error'];
  importColumns = ['exist', 'common', 'local', 'phone', 'comment', 'metka_type_id', 'serial'];
  displayedColumns = ['select', 'common', 'local', 'phone', 'comment', 'error'];

  exist: IDData[] = [];
  dataSource: MatTableDataSource<ExcelLine> = new MatTableDataSource<ExcelLine>();
  dataSource_import: MatTableDataSource<ExcelLine> = new MatTableDataSource<ExcelLine>();
  common: { [address: string]: { id: number; count: number }; } = {};

  selection = new SelectionModel<ExcelLine>(true, []);
  addresses: { address: string, id: number }[] = [];

  progress: number;
  table_progress: number;
  abort_import = false;
  importing = false;
  success = 0;
  lines = 0;

  stat = {
    undefined: 0,
    good: 0,
    unparsed: 0
  };

  allCountries: Array<Country> = [];
  selectedCountry: '' | CountryCode = '';

  protected fetchCountryData(): void {
    this.allCountries = this.countryCodeData.fetchCountryData();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter(e => e.importable).length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach((row) => row.importable ? this.selection.select(row) : null);
  }

  checkboxLabel(row?: ExcelLine): string {
    if (!row) {
      return `${this.isAllSelected() ? $localize`выбрать все` : $localize`снять выбор`}`;
    }
    return $localize`${this.selection.isSelected(row) ? $localize`убрать` : $localize`выбрать`} пользователя с телефоном ${(<ExcelLine>row).phone}`;
  }

  constructor(
    private http: HttpClient,
    public desc: DescriptionService,
    public iddata: IDDataService,
    private path: UrlPathService,
    private countryCodeData: CountryCodeList
  ) {
    this.fetchCountryData();
  }

  get(filter?): Observable<IDData[]> {
    if (filter === undefined) { filter = {}; }
    return this.http.post<IDData[]>(this.path.metka(), filter)
      .pipe(
        map(
          (array) => {
            return array.map(e => this.iddata.cook(e));
          })
      );
  }

  ngOnInit() {
    this.update_adresses();
    this.get().subscribe();

    if (sessionStorage.getItem('LOCATION') !== null) {
      this.selectedCountry = <CountryCode>(sessionStorage.getItem('LOCATION'));
    } else {
      this.http.get(this.path.geo()).subscribe({
        next: (res: any) => {
          if (res.countryCode) {
            this.selectedCountry = <CountryCode>(res.countryCode);
          }
        }
      });
    }
  }

  private update_adresses() {
    this.desc.update().subscribe((d) => {
      this.addresses = Object.keys((<DescriptionService>d).address).map(a => d.address[a]);
      Object.keys(this.common).forEach(a => {
        if (+this.common[a].id === -1) {
          const element = this.addresses.find(e => e.address === a);
          if (element) {
            this.common[a].id = element.id;
          }
        }
        return this.common[a];
      });
    });
  }

  fileChanged(e) {
    this.file_name = e.target.files[0];
  }

  get_class(row) {
    if (row.good) {
      return `good ${row.single ? 'single' : 'multi'}`;
    }
    return `bad`;
  }

  parseFile() {
    const data = <ExcelLine[]>[];
    this.common = {};
    this.lines = 0;
    this.stat.undefined = 0;
    this.stat.good = 0;
    this.stat.unparsed = 0;
    sessionStorage.setItem('LOCATION', this.selectedCountry);
    this.workbook.SheetNames.forEach((sheetName) => {         // Here is your object
      const XL_row_object = XLSX.utils.sheet_to_json<ExcelLine>(this.workbook.Sheets[sheetName], { header: this.header });
      this.lines += XL_row_object.length;
      XL_row_object.forEach((e, idx, src) => {
        this.table_progress = 100 * idx / src.length;
        if (idx === 0 && e.phone === 'Телефон') {
          return;
        }

        if (e.phone === undefined) {
          e.phone = '';
          this.stat.undefined++;
        }


        e.good = (/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)$/).test(e.phone.toString());
        if (e.good) {
          e.single = true;
          this.appendAddressPhone(e, data);
          return;
        } else {
          // Попробуем разобраться
          let ph;
          let found = 0;
          const phone = /(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)/g;

          while ((ph = phone.exec(e.phone)) !== null) {
            e.single = false;
            const new_e = Object.assign({}, e);
            new_e.phone = ph[0];
            new_e.comment = e.comment ? `${e.comment} (${e.phone})` : e.phone;
            new_e.good = true;

            this.appendAddressPhone(new_e, data);
            ++found;
          }

          if (found) {
            return;
          }
        }
        e.single = false;
        data.push(e);
      });
    });

    this.dataSource.data = data;
    this.update_adresses();
  }

  private appendAddressPhone(e: ExcelLine, data: ExcelLine[]) {

    const num = this.selectedCountry !== '' ? parsePhoneNumberFromString(e.phone.toString(), this.selectedCountry) : parsePhoneNumberFromString(e.phone.toString());
    if (num && num.isValid()) {
      e.phone = num.number.toString();
      e.good = true;
      e.importable = true;
      ++this.stat.good;
    } else {
      ++this.stat.unparsed;
      e.good = false;
      e.error = $localize`Неверный формат номера телефона`;
      e.comment = e.comment ? $localize`Номер телефона ${e.phone}. Комментарий: ${e.comment}` : $localize`Номер телефона ${e.phone}`;
      e.importable = true;
    }

    if (this.common[e.common] === undefined) {
      this.common[e.common] = { id: -1, count: 0 };
    }
    ++this.common[e.common].count;

    if (e.good) {
      this.selection.select(e);
    }

    data.push(e);
  }

  onload(reader: FileReader): any {
    const data = reader.result;
    this.workbook = XLSX.read(data, { type: 'binary' });
    this.parseFile();
    this.stepper.next();
  }

  loadFile() {
    const reader = new FileReader();
    reader.onload = () => { this.onload(reader); };

    reader.onerror = function (ex) {
      console.log(ex);
    };

    reader.readAsBinaryString(this.file_name);
  }

  is_create_new_address(): boolean {
    return Object.keys(this.common).map(key => this.common[key]).some(e => +e.id === -1);
  }

  create_new_address() {
    Object.keys(this.common).map(key => Object.assign(this.common[key], { 'key': key })).filter(e => +e.id === -1).forEach(n => {
      this.desc.set({ id: '0', address: n.key }, this.desc.display['address']).subscribe(
        () => this.desc.update(true).subscribe(
          () => {
            this.update_adresses();
          })
      );
    });
  }

  select_for_import() {
    this.dataSource_import.data = [];
    this.get().subscribe({
      next: exist => {
        this.dataSource_import.data = this.selection.selected.map(src => {
          const e = Object.assign({}, src);

          e.common = this.common[e.common].id;
          const q = exist.find((val) => {
            return val.phone === e.phone;// && val.address_common_id === e.common && val.address_local === e.local
          });
          e.exist = q !== undefined;
          if (e.exist) {
            e.exist = true;
            e.serial = q.serial_hex;
            e.metka_type_id = q.metka_type_id;
            e.common = q.address_common_id;
            e.local = q.address_local;
          }

          return e;
        });
      }
    });
  }

  next_import(idx: number) {
    this.importing = true;
    for (; idx < this.dataSource_import.data.length && !this.abort_import; ++idx) {
      const d = this.dataSource_import.data[idx];
      if (!d.exist) {
        const iddata = {
          phone: d.phone,
          address_local: d.local.toString(),
          address_common_id: d.common,
          comment: d.comment,
          status_id: 0,
          metka_type_id: 3,
        };
        this.progress = 100 * idx / this.dataSource_import.data.length;
        this.success++;
        this.iddata.set(null, iddata).subscribe(() => {
          this.dataSource_import.data[idx].metka_type_id = 3;
          this.dataSource_import.data[idx].exist = true;
          this.next_import(idx + 1);
        });
        return;
      }
    }
    this.progress = 100;
    this.abort_import = false;
    this.importing = false;
    this.stepper.next();
  }

  start_import() {
    this.abort_import = false;
    this.success = 0;
    this.next_import(0);
  }

  start_abort() {
    this.abort_import = true;
  }

  tooltip(row: ExcelLine) {
    if (row.exist) {
      return $localize`Адрес и телефон уже зарегестрирован в системе`;
    }
    if (row.good) {
      return row.single ? '' : $localize`Строка содержала несколько номеров`;
    }
    return $localize`Ошибка обработки номера телефона`;
  }
}
