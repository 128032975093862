<div class="mat-app-background basic-container">
  <mat-card>
    <mat-card-title>
      {{name}}
    </mat-card-title>
    <app-base [external_filter]="{base:true}" [allow_add]="false">

    </app-base>
    <app-id [external_filter]="{base:true}" [allow_add]="true" [dst_base]="{group_ids: [group_id]}" >

    </app-id>
  </mat-card>
</div>