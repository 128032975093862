<h1 mat-dialog-title>
  <ng-container i18n>Настройки контроллера</ng-container>"{{data.name}}"
</h1>
<mat-dialog-content>
  <form [formGroup]="base">
    <h3 i18n>Радиомодуль</h3>
    <div>
      <h5 i18n>Радиомодуль 1</h5>
      <mat-slide-toggle formControlName="RF1ENABLE" i18n>
        Радиомодуль 1
      </mat-slide-toggle>
      <div>
        <mat-label>
          <ng-container i18n>Порог срабатывания</ng-container>{{base.value.RF1VALUE}}<ng-container i18n>дБмВт</ng-container></mat-label>
        <mat-slider min="-100" max="0" step="1" formControlName="RF1VALUE"></mat-slider>
      </div>
      <h5 i18n>Радиомодуль 2</h5>
      <mat-slide-toggle formControlName="RF2ENABLE" i18n>
        Радиомодуль 2
      </mat-slide-toggle>
      <div>
        <mat-label><ng-container i18n>Порог срабатывания</ng-container>{{base.value.RF2VALUE}}<ng-container i18n>дБмВт</ng-container></mat-label>
        <mat-slider min="-100" max="0" step="1" formControlName="RF2VALUE"></mat-slider>
      </div>
    </div>
    <h3 i18n>Реле</h3>
    <div>
      <h5 i18n>Реле 1</h5>
      <mat-radio-group i18n-aria-label aria-label="Режим работы реле" formControlName="RELAY1TYPE">
        <mat-radio-button value="0" i18n>Импульсно</mat-radio-button>
        <mat-radio-button value="1" i18n>Постоянно</mat-radio-button>
      </mat-radio-group>
      <mat-form-field *ngIf="base.value.RELAY1TYPE==1">
        <mat-label i18n>Выключить через</mat-label>
        <input matInput type="number" min="0" max="65535" formControlName="RELAY1TIMEOFF">
        <p matSuffix i18n>секунд</p>
      </mat-form-field>
      <mat-form-field *ngIf="base.value.RELAY1TYPE==0">
        <mat-label i18n>Включить на</mat-label>
        <input matInput type="number" min="0" max="65535" formControlName="RELAY1TIMEON">
        <p matSuffix i18n>секунд</p>
      </mat-form-field>
      <mat-form-field *ngIf="base.value.RELAY1TYPE==0">
        <mat-label i18n>Повторно через</mat-label>
        <input matInput type="number" min="0" max="65535" formControlName="RELAY1TIMEREPEAD">
        <p matSuffix i18n>секунд</p>
      </mat-form-field>
    </div>
    <div>
      <h5 i18n>Реле 2</h5>
      <mat-radio-group i18n-aria-label aria-label="Режим работы реле" formControlName="RELAY2TYPE">
        <mat-radio-button value="0" i18n>Импульсно</mat-radio-button>
        <mat-radio-button value="1" i18n>Постоянно</mat-radio-button>
      </mat-radio-group>
      <mat-form-field *ngIf="base.value.RELAY2TYPE==1">
        <mat-label i18n>Выключить через</mat-label>
        <input matInput type="number" min="0" max="65535" formControlName="RELAY2TIMEOFF">
        <p matSuffix i18n>секунд</p>
      </mat-form-field>
      <mat-form-field *ngIf="base.value.RELAY2TYPE==0">
        <mat-label i18n>Включить на</mat-label>
        <input matInput type="number" min="0" max="65535" formControlName="RELAY2TIMEON">
        <p matSuffix i18n>секунд</p>
      </mat-form-field>
      <mat-form-field *ngIf="base.value.RELAY2TYPE==0">
        <mat-label i18n>Повторно через</mat-label>
        <input matInput type="number" min="0" max="65535" formControlName="RELAY2TIMEREPEAD">
        <p matSuffix i18n>секунд</p>
      </mat-form-field>
    </div>
    <h3 i18n>Формат интерфейса Wiegand</h3>
    <div>
      <mat-form-field>
        <mat-label i18n>Интерфейс Wiegand 1</mat-label>
        <mat-select formControlName="WIEGAND1" [compareWith]="desc.compareWith">
          <mat-option [value]="0" i18n>Wiegand-26. Состоит из 24 бит кода и 2 бит контроля на четность. </mat-option>
          <mat-option [value]="1" i18n>Wiegand-33. Состоит из 32 бит кода и 1 бита контроля на четность. </mat-option>
          <mat-option [value]="2" i18n>Wiegand-34. Состоит из 32 бит кода и 2 бит контроля на четность. </mat-option>
          <mat-option [value]="3" i18n>Wiegand-37. Состоит из 35 бит кода и 2 бит контроля на четность. </mat-option>
          <mat-option [value]="4" i18n>Wiegand-40. Состоит из 40 бит кода, контроля на четность нет. </mat-option>
          <mat-option [value]="5" i18n>Wiegand-42. Состоит из 40 бит кода и 2 бит контроля на четность.</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>Интерфейс Wiegand 2</mat-label>
        <mat-select formControlName="WIEGAND2" [compareWith]="desc.compareWith">
          <mat-option [value]="0" i18n>Wiegand-26. Состоит из 24 бит кода и 2 бит контроля на четность. </mat-option>
          <mat-option [value]="1" i18n>Wiegand-33. Состоит из 32 бит кода и 1 бита контроля на четность. </mat-option>
          <mat-option [value]="2" i18n>Wiegand-34. Состоит из 32 бит кода и 2 бит контроля на четность. </mat-option>
          <mat-option [value]="3" i18n>Wiegand-37. Состоит из 35 бит кода и 2 бит контроля на четность. </mat-option>
          <mat-option [value]="4" i18n>Wiegand-40. Состоит из 40 бит кода, контроля на четность нет. </mat-option>
          <mat-option [value]="5" i18n>Wiegand-42. Состоит из 40 бит кода и 2 бит контроля на четность.</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <h3 i18n>Сетевые настройки</h3>
    <div>
      <div class="ip_mask_gate">
        <mat-slide-toggle formControlName="DHCP">
          DHCP
        </mat-slide-toggle>
      </div>
      <div class="ip_mask_gate" *ngIf="!base.value.DHCP;else curent_ip">
        <mat-label>ip:</mat-label>
        <span>
          <ngx-ip formControlName="IP" [mode]="'ipv4'"></ngx-ip>
        </span>
      </div>
      <ng-template #curent_ip>
        <div>
          <mat-label>ip:</mat-label>
          <span>
            <ngx-ip [(value)]="data.CURR_IP" [mode]="'ipv4'" disabled="true"></ngx-ip>
          </span>
        </div>
      </ng-template>
      <div class="ip_mask_gate" *ngIf="!base.value.DHCP;else curent_mask">
        <mat-label>Mask: </mat-label>
        <span>
          <ngx-ip formControlName="MASK" [mode]="'ipv4'"></ngx-ip>
        </span>
      </div>
      <ng-template #curent_mask>
        <div>
          <mat-label>Mask: </mat-label>
          <span>
            <ngx-ip [(value)]="data.CURR_MASK" [mode]="'ipv4'" disabled="true"></ngx-ip>
          </span>
        </div>
      </ng-template>
      <div class="ip_mask_gate" *ngIf="!base.value.DHCP;else curent_gateway">
        <mat-label>gate: </mat-label>
        <span>
          <ngx-ip formControlName="GETWAY" [mode]="'ipv4'"></ngx-ip>
        </span>
      </div>
      <ng-template #curent_gateway>
        <div>
          <mat-label>gate: </mat-label>
          <span>
            <ngx-ip [(value)]="data.CURR_GETWAY" [mode]="'ipv4'" disabled="true"></ngx-ip>
          </span>
        </div>
      </ng-template>
      <div class="ip_mask_gate" *ngIf="!base.value.DHCP;else curent_dns">
        <mat-label>dns: </mat-label>
        <span>
          <ngx-ip formControlName="DNS" [mode]="'ipv4'"></ngx-ip>
        </span>
      </div>
      <ng-template #curent_dns>
        <div>
          <mat-label>dns: </mat-label>
          <span>
            <ngx-ip [(value)]="data.CURR_DNS" [mode]="'ipv4'" disabled="true"></ngx-ip>
          </span>
        </div>
      </ng-template>
    </div>
    <h3 i18n>Время</h3>
    <div>
      <mat-slide-toggle formControlName="TIMESYNC" i18n>
        Синхронизация времени с сервером
      </mat-slide-toggle>
    </div>
    <h3 i18n>Синхронизация</h3>
    <div>
      <mat-slide-toggle formControlName="SERVERSYNC" i18n>
        Управление контроллером при помощи сервера
      </mat-slide-toggle>
      <p i18n>необходимо включить для изменения настроек контроллера</p>
      <mat-slide-toggle formControlName="LOGSYNC" i18n>
        Передача журнала событий на сервер
      </mat-slide-toggle>
      <p i18n>если выключено, журнал событий доступен только непосредственно с веб-интерфейса контроллера</p>
      <mat-slide-toggle formControlName="TAGSYNC" i18n>
        Управление сервером зарегистрированными метками
      </mat-slide-toggle>
      <p i18n>необходимо включить для передачи контроллеру списка разрешённых меток в противном случае добавление меток
        возможно только из интерфейса контроллера</p>
    </div>
    <h3 i18n>Удалённое открытие через интернет</h3>
    <div>
      <mat-radio-group class="on_new_line" i18n-aria-label aria-label="Удалённое открытие через интернет" formControlName="REMOTE">
        <mat-radio-button [value]="-1" i18n>Определяется настройками ТКД или Объекта</mat-radio-button>
        <mat-radio-button [value]="0" i18n>Запрещено</mat-radio-button>
        <mat-radio-button [value]="1" i18n>Разрешено</mat-radio-button>
      </mat-radio-group>
      <mat-form-field *ngIf="base.value.RF1ENABLE">
        <mat-label i18n>Реле 1 отображать как</mat-label>
        <input matInput type="text" formControlName="RELAY1NAME">
        <mat-hint i18n>Имя, выводимое в списке удалённо открываемых реле</mat-hint>
      </mat-form-field>
      <mat-form-field *ngIf="base.value.RF2ENABLE">
        <mat-label i18n>Реле 2 отображать как</mat-label>
        <input matInput type="text" formControlName="RELAY2NAME">
        <mat-hint i18n>Имя, выводимое в списке удалённо открываемых реле</mat-hint>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [disabled]="base.invalid" (click)="save()" color="primary" i18n>
    Сохранить изменения</button>
  <button mat-button (click)="close()" color="primary" i18n>
    Закрыть</button>
</mat-dialog-actions>
