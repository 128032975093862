<div>
    <p i18n>
        Для объекта типа {{desc.construct[type_id].name}} можно задать несколько ассоциированных объектов
        ({{desc.construct[type_id].param}}) с автоматической привязкой к параметру объекта
        ({{desc.construct[type_id].object}})
    </p>
</div>
<div>
    <mat-checkbox [formControl]="REMOTE"  i18n>Разрешение на удалённое управление открытием всего Объекта</mat-checkbox>
</div>
<!--Таблица с ТКД -->
<mat-table *ngIf="tkds.data.length>0" [dataSource]="tkds">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef  i18n> Название</mat-header-cell>
        <mat-cell *matCellDef="let tkd">{{tkd.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="numbers">
        <mat-header-cell *matHeaderCellDef  i18n>
            {{desc.construct[type_id].object}} диапазон номеров
        </mat-header-cell>
        <mat-cell *matCellDef="let tkd"> {{tkd.numbers}}</mat-cell>
    </ng-container>

    <!-- Контроллеры -->
    <ng-container matColumnDef="bases">
        <mat-header-cell *matHeaderCellDef  i18n>Контроллеры</mat-header-cell>
        <mat-cell *matCellDef="let tkd">
            <app-descr-expand val="base" [ids]="tkd.bases"  i18n-title title="Контроллеры метки"></app-descr-expand>
        </mat-cell>
    </ng-container>

    <!-- Удалённое открытие -->
    <ng-container matColumnDef="remote">
        <mat-header-cell *matHeaderCellDef  i18n>Удалённое открытие</mat-header-cell>
        <mat-cell *matCellDef="let tkd">
            <ng-container [ngSwitch]="REMOTE.value?tkd.REMOTE:-1">
                <ng-container *ngSwitchCase="-1"></ng-container>
                <ng-container *ngSwitchCase="false" i18n>Запрещено</ng-container>
                <ng-container *ngSwitchCase="true" i18n>Разрешено</ng-container>
            </ng-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef class="actions_col" i18n>
            Действия
        </mat-header-cell>
        <mat-cell *matCellDef="let tkd; let idx=index;" class="actions_col">
            <button mat-icon-button (click)="delete(idx, $event)" n18n-matTooltip matTooltip="Удалить ассоциированный объект">
                <mat-icon class="md-18">delete</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <!-- Заголовоки -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <!-- Обычные строки -->
    <mat-row *matRowDef="let row; columns: displayedColumns; let idx=index" [class.selected]="idx==selected_item"
             (click)="select(idx)"></mat-row>
</mat-table>

<div *ngIf="selected_item!==undefined && tkds.data.length!=0">
    <form [formGroup]="tkd">
        <mat-form-field>
            <mat-label i18n>Название ассоциированного объекта</mat-label>
            <input matInput type="text" formControlName="name"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{desc.construct[type_id].object}} <ng-container i18n>диапазон номеров (например: 4-5,7,12-15)</ng-container>
            </mat-label>
            <input matInput type="text" formControlName="numbers"/>
            <mat-error *ngIf="(tkd.touched || tkd.dirty) && tkd.get('numbers').hasError('pattern')" i18n>
                Диапазон должен состоять из чисел, разделённых знаком тире и запятыми
            </mat-error>
            <mat-error *ngIf="(tkd.touched || tkd.dirty) && tkd.get('numbers').hasError('validateRange')" i18n>
                Ошибка при вводе диапазонов
            </mat-error>
        </mat-form-field>
        <app-base-selector formControlName="bases" i18n-label label="Точки контроля доступа"
                           i18n-hint hint="Контроллеры, разрешающие доступ к ассоциированному объекту"
                           i18n-required required="Необходимо выбрать хотя бы один контроллер"></app-base-selector>
        <ng-container *ngIf="REMOTE.value">
          <mat-checkbox formControlName="REMOTE" i18n>Разрешение на удалённое управление открытием точки контроля доступа</mat-checkbox>
        </ng-container>
    </form>
</div>
<div *ngIf="tkds.data.length==0">
    <form [formGroup]="tkd_single">

        <app-base-selector formControlName="bases" i18n-label label="Точки контроля доступа"
                           i18n-hint hint="Контроллеры, разрешающие доступ к ассоциированному объекту"
                           i18n-required required="Необходимо выбрать хотя бы один контроллер"></app-base-selector>
    </form>
</div>
<div>
  <button mat-button matStepperPrevious>Назад</button>
  <button mat-button *ngIf="selected_item!==undefined && tkd.touched" [disabled]="tkd.invalid"
          (click)="select(selected_item)" color="accent" i18n>
    Сохранить
  </button>
  <button mat-button [disabled]="selected_item!==undefined && tkd.invalid" (click)="append()" i18n>
    Добавить новый ассоциированный объект
  </button>
  <button mat-button *ngIf="selected_item!==undefined" (click)="cancel()" i18n>
    Отмена
  </button>
  <button mat-button matStepperNext color="primary" (click)="update()"
          [disabled]="(tkd.invalid && tkds.data.length>0)
          || (tkd_single.invalid && tkds.data.length==0)
          || (selected_item!==undefined && tkd.touched)"
          i18n>
    Дальше
  </button>
</div>
<div i18n>
  Если будут добавлены ассоциированные объекты, то в WEB приложении при
  выборе данного объекта, пользователю будет предложено ввести номер
  ассоциированного объекта. В зависимости от введённого номера пользователь
  получит доступ к соответствующему объекту
</div>

<div *ngIf="debug">
    <p>tkd.invalid: {{tkd.invalid}}</p>
    <p>tkd.invalid: {{tkd.value|json}}</p>
    <p>selected_item: {{selected_item}}</p>
    <p>tkd.touched: {{tkd.touched}}</p>
    <p>tkd: {{tkd.controls["name"].errors|json}}</p>
    <p>tkd: {{tkd.controls["numbers"].errors|json}}</p>
    <p>tkd: {{tkd.controls["bases"].errors|json}}</p>
</div>
