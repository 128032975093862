<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <mat-select [disabled]="!enabled" [(ngModel)]="value" [compareWith]="desc.compareWith" multiple
    (selectionChange)="proxyChange($event)">
    <mat-option *ngFor="let b of ungroupped" [value]="b.id">
      {{b.name}}
    </mat-option>
    <mat-optgroup *ngFor="let g of groupped" [label]="g.group">
      <mat-option *ngFor="let b of g.bases" [value]="b.id">
        {{b.name}}
      </mat-option>
    </mat-optgroup>
  </mat-select>
  <mat-hint>
    {{hint}}
  </mat-hint>
  <mat-error *ngIf="value && value.length==0">
    {{required}}
  </mat-error>

</mat-form-field>