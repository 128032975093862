import {
    Component, OnInit,
    OnDestroy,
    ElementRef,
    ViewChild,
    Inject
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';

import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import {DescriptionService} from '../description.service';
import {AuthService} from '../login/login.component';
import {IDData, IDDataService} from '../iddata';
import {dec2hex} from '../hex.pipe';

@Component({
    selector: 'app-dlg-metka',
    templateUrl: './metka.dialog.html',
    styleUrls: ['./metka.dialog.scss']
})
export class MetkaDialogComponent implements OnInit, OnDestroy {
    @ViewChild('phone_input', {static: true}) phone_input: ElementRef;
    objectKeys = Object.keys;

    query$: Subscription;
    metka: FormGroup;
    value: IDData;
    id = 0;
    updating = false;
    serial: string | null = '';

    state = '';
    now = new Date();
    last_date: Date;

    displayedColumns: string[] = ['timestamp', 'message', 'user'];

    //pick = (...props) => o => props.reduce((a, e) => ({ ...a, [e]: o[e] }), {});
    pick = (fileds: string[], obj: any) => fileds.reduce((a, e) => {
        if (obj[e]) {
            a[e] = obj[e];
        }
        return a;
    }, {});

    constructor(
        public dialogRef: MatDialogRef<MetkaDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        private query: {
            serial?: string | null;
            serial_hex?: string | null;
            group_ids?: number[];
            group_bases?: number[];
            metka_type_id?: number;
        },
        public auth: AuthService,
        public desc: DescriptionService,
        public iddata: IDDataService,
        private route: ActivatedRoute
    ) {
    }

    updateForm() {
        const q = Object.assign({}, this.query);
        if (q['group_ids'] !== undefined && !Array.isArray(q['group_ids'])) {
            q['group_ids'] = new Array(q['group_ids']);
        }
        if (q['group_bases'] !== undefined && !Array.isArray(q['group_bases'])) {
            q['group_bases'] = new Array(q['group_bases']);
        }
        if (q.metka_type_id === 3) {
            q.metka_type_id = 1;
        }
        this.metka.patchValue(q);
        if (q['group_ids'] !== undefined) {
            this.updateFromGroups();
        }
    }

    ngOnDestroy() {
        if (this.query$) {
            this.query$.unsubscribe();
        }
    }

    ngOnInit() {
        this.auth.getCompany().toPromise().then(() => {
            this.last_date = this.auth.time_exp;
            this.initForm();
            if (this.query && this.query.serial && this.query.serial !== null) {
                this.serial = this.query.serial;
                this.get(this.serial).subscribe(() => this.updateForm());
            } else {
                this.state = $localize`Добавление новой метки`;

                this.query$ = this.route.queryParams.subscribe({
                    next: query => {
                        this.metka.patchValue(this.pick([
                            'phone',
                            'address_local',
                            'address_common_id',
                            'comment',
                            'id'], query));
                        this.updateForm();
                        console.log(this.metka.value);
                    }
                });
            }
        });
    }

    inUpdate(newState?: string) {
        this.updating = true;
        if (newState !== undefined) {
            this.state = newState;
        }
        console.log('inUpdate');
    }

    doneUpdate(newState?: string) {
        this.updating = false;
        if (newState !== undefined) {
            this.state = newState;
        }
        console.log('doneUpdate');
    }

    get(serial) {
        this.inUpdate($localize`Загрузка актуальной информации о метке`);
        return this.iddata.get(serial).pipe(
            map(data => {
                this.value = data;
                this.metka = this.iddata.form(data);
                this.id = data.id == null ? 0 : data.id;
                this.serial = data.serial == null ? '' : data.serial;
                this.doneUpdate($localize`Редактирование метки ${dec2hex(this.serial)}`);
            })
        );
    }

    initForm() {
        this.metka = this.iddata.form();
        this.value = null;
    }

    cancel() {
        this.get(this.serial).subscribe(() => this.updateForm());
        this.dialogRef.close();
    }

    save() {
        this.inUpdate($localize`Синхронизация информации о метке с сервером`);
        this.iddata.set(this.value, this.metka.getRawValue()).subscribe({
            next: (res: any) => {
                this.metka.markAsPristine();
                this.metka.markAsUntouched();
                this.dialogRef.close();
            },
            error: () => this.cancel(),
            complete: () => {
                this.doneUpdate($localize`Данные метки отправлены на сервер`);
            }
        });
    }

    delete() {
        this.iddata.delete(this.value).subscribe({
            next: (res: any) => {
                this.dialogRef.close();
            },
            error: () => this.cancel(),
            complete: () => {
                this.doneUpdate($localize`Метка успешно удалена`);
            }
        });
    }

    updateType() {
        if (
            this.metka.controls['metka_type_id'].untouched &&
            this.metka.controls['serial_hex'].value !== null
        ) {
            let prefix = (this.metka.controls['serial_hex'].value as string)
                .substr(0, 2)
                .toLowerCase();
            if (prefix == 'e1') {
                this.metka.patchValue({metka_type_id: '2'});
            } else if (prefix == 'eb') {
                this.metka.patchValue({metka_type_id: '1'});
            }
        }
    }

    updateFromGroups() {
        this.iddata.updateFromGroups(this.metka, this.desc);
    }

    disallow() {
        let dis = '';
        for (let bi = 0; bi < this.metka.value.group_bases.length; ++bi) {
            let b = this.metka.value.group_bases[bi];
            if (!this.desc.base[b].TAGSYNC) {
                dis = dis + this.desc.base[b].name;
            }
        }
        return dis;
    }

    downloadLog() {
        this.iddata.log(this.value.serial);
    }

    onlyDigits(key) {
    }

    selectNotEmpty(a: string, b: string): string {
        return a === '' ? dec2hex(b) : a;
    }
}

@Component({
    selector: 'app-dlg-metka',
    templateUrl: './metka.component.html',
    styleUrls: ['./metka.component.scss']
})
export class MetkaComponent implements OnInit {
    params$;

    constructor(
        public dialog: MatDialog,
        private route: ActivatedRoute,
        public iddata: IDDataService
    ) {
    }

    ngOnInit() {
        this.params$ = this.route.params.subscribe({
            next: params => {
                let serial = null;

                if (params.serial !== undefined) {
                    serial = params.serial;
                }

                const dialogRef = this.dialog.open(MetkaDialogComponent, {
                    width: '620px',
                    closeOnNavigation: true,
                    disableClose: false,
                    hasBackdrop: true,

                    data: {serial: serial}
                });
            },
            error: err => console.log(err),
            complete: () => console.log('complete')
        });
    }
}
