<mat-card>
  <mat-card-header>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <app-single-object [object]="object"></app-single-object>
    </mat-list>
    <mat-action-row>
      <button mat-button color="primary" (click)="edit()" i18n>Редактировать</button>
    </mat-action-row>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event)" i18n-placeholder placeholder="Поиск">
    </mat-form-field>
    <!--Таблица с запросами на объект с возможностью подтверждения -->
    <!--Таблица с допущенными на объект с возможностью подтверждения -->
    <!--Таблица с запрещёнными на объект с возможностью подтверждения -->
    <mat-table [dataSource]="request" matSort>
      <!--Действия-->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef class="actions_col" i18n> Действия </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actions_col">
          <button mat-icon-button *ngIf="element.allow==null || element.allow==0">
            <mat-icon class="md-18" color="primary" i18n-matTooltip matTooltip="Разрешить доступ на объект" (click)="allow(element)">
              check_circle</mat-icon>
          </button>
          <button mat-icon-button i18n-matTooltip matTooltip="Запретить доступ на объект" *ngIf="element.allow==null || element.allow==1">
            <mat-icon class="md-18" color="warn" (click)=deny(element)>highlight_off</mat-icon>
          </button>
<!--          <button mat-icon-button i18n-matTooltip matTooltip="Создать электронную метку" *ngIf="element.allow && element.serial==null">-->
<!--            <mat-icon class="md-18" color="warn" (click)=add_metka(element)>add</mat-icon>-->
<!--          </button>-->
        </mat-cell>
      </ng-container>

      <!--Доступ-->
      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Доступ </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.state}}
        </mat-cell>
      </ng-container>

      <!--Параметр-->
      <ng-container matColumnDef="param">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Параметр доступа </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.param}}
        </mat-cell>
      </ng-container>

      <!--Телефон-->
      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Телефон </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a [href]="'tel:+'+element.phone" mat-button color="primary" [matTooltip]="'+'+element.phone">+{{element.phone}}</a>
        </mat-cell>
      </ng-container>

      <!--Коментарий-->
      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Комментарий </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.comment}}
        </mat-cell>
      </ng-container>

      <!--Метка-->
      <ng-container matColumnDef="serial">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n class="metka"> Метка # </mat-header-cell>
        <mat-cell *matCellDef="let element" class="metka">
          <button *ngIf="element.serial;else no_metka" mat-button (click)="show_metka(element.serial)" [matTooltip]="decToHex(element.serial)">{{element.serial|hex}}</button>
          <ng-template #no_metka>
            <button mat-button (click)="add_metka(element)">Метка не выпущена</button>
          </ng-template>
        </mat-cell>
      </ng-container>

      <!--Срок действия-->
      <ng-container matColumnDef="time_exp">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Срок действия </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button *ngIf="element.time_exp > now; else badTime"
            mat-button>{{ element.time_exp | date: "shortDate" }}</button>
          <ng-template #badTime>
            <button mat-button color="warn" i18n-matTooltip
              matTooltip="Срок дейсвия электронного ключа закончился">{{ element.time_exp | date: "shortDate" }}</button>
          </ng-template>
        </mat-cell>
      </ng-container>

      <!--Данные устойства клиента-->
      <ng-container matColumnDef="device_info">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n> устройство </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.device_info.os.toLowerCase()=='android'" i18n-matTooltip matTooltip="Android">android</mat-icon>
          <mat-icon *ngIf="element.device_info.os.toLowerCase()=='iphone'" i18n-matTooltip matTooltip="iPhone">phone_iphone</mat-icon>
          <mat-icon *ngIf="element.device_info.os.toLowerCase()=='ios'" i18n-matTooltip matTooltip="iPhone">phone_iphone</mat-icon>
          <mat-icon *ngIf="element.device_info.os.toLowerCase()=='windows'" i18n-matTooltip matTooltip="ПК">laptop</mat-icon>
          <mat-icon *ngIf="element.device_info.os.toLowerCase()=='linux'" i18n-matTooltip matTooltip="ПК">laptop</mat-icon>
          <mat-icon *ngIf="element.device_info.os.toLowerCase()=='macos'" i18n-matTooltip matTooltip="ПК">laptop</mat-icon>
        </mat-cell>
      </ng-container>

      <!--Данные устойства клиента-->
      <ng-container matColumnDef="tariff">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n> оплата </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.paid_tariff==null"></ng-container>
          <ng-container *ngIf="element.paid_tariff==0" i18n>Ожидается подтверждение оплаты</ng-container>
          <ng-container *ngIf="element.paid_tariff>0" i18n>Последний оплаченный тариф: {{element.paid_tariff}} {element.paid_tariff, plural, =1 {месяц} =2 {месяца} =3 {месяца} =4 {месяца} other {месяцев}}</ng-container>
          <ng-container *ngIf="element.paid_tariff<0" i18n>Активирован пробный период</ng-container>
        </mat-cell>
      </ng-container>

      <!-- Заголовок группы -->
      <ng-container matColumnDef="groupHeader">
        <mat-row>
          <mat-header-cell *matCellDef="let groupBy">
            <button mat-flat-button>{{ groupBy.initial }}</button>
            <ng-container *ngIf="groupBy.src.pend && groupBy.src.allow == null">
              <button mat-raised-button color="primary" i18n-matTooltip matTooltip="Разрешить всем доступ на объект"
                      (click)="allow()" i18n>Разрешить всем</button>
              <button mat-raised-button color="warn" i18n-matTooltip matTooltip="Запретить всем доступ на объект"
                      (click)="deny()" i18n>Запретить всем</button>
            </ng-container>
          </mat-header-cell>
        </mat-row>
      </ng-container>

      <!-- Заголовоки -->
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

      <!-- Обычные строки -->
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      <!-- Строка подзаголовка -->
      <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"></mat-row>



    </mat-table>
  </mat-card-content>
</mat-card>
