import {environment} from '../../environments/environment';

import { Component, OnInit, OnDestroy, ViewChild, Input, Directive } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {MatTableDataSource, MatTable} from '@angular/material/table';

import {DescriptionService} from '../description.service';
import {ObjectService, TariffData, validateRange} from '../object';
import {FormGroup, FormBuilder, Validators, FormArray, FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ObjectData, TKDData} from '../object';
import {AuthService} from '../login/login.component';
import {ObjectCreateStep1} from './step1/obj-create-step1.component';
import {ObjectCreateStep2} from './step2/obj-create-step2.component';

@Directive()
export class ObjectBaseComponent implements OnInit, OnDestroy {
    $desc: Subscription;
    objectKeys = Object.keys;
    progress = false;

    constructor(
        public desc: DescriptionService,
        public obj: ObjectService) {
    }

    ngOnInit() {
        this.$desc = this.desc.update().subscribe();
    }

    ngOnDestroy(): void {
        if (this.$desc) {
            this.$desc.unsubscribe();
        }
    }
}

@Component({
    selector: 'app-create-object',
    templateUrl: './object.create.html',
    styleUrls: ['./object.common.scss']
})
export class ObjectCreateComponent extends ObjectBaseComponent implements OnInit, OnDestroy {
    step1: ObjectCreateStep1;
    step2: ObjectCreateStep2;
    tariff: TariffData;

    object: ObjectData;
    debug: boolean = environment.version === 'DEV';

    constructor(
        public desc: DescriptionService,
        public obj: ObjectService,
        public fb: FormBuilder,
        public auth: AuthService,
        public router: Router,
    ) {
        super(desc, obj);
    }


    // Если что, вот тут почти понятно! https://alligator.io/angular/reactive-forms-formarray-dynamic-fields/

    done_step1(value: ObjectCreateStep1) {
        this.step1 = value;
        this.update();
    }

    done_step2(value: ObjectCreateStep2) {
        this.step2 = value;
        this.update();
    }

    done_step3(value: TariffData) {
        this.tariff = value;
        this.update();
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.desc.update(true).subscribe();
    }

    update() {
        if (this.step1 && this.step2 && this.tariff) {
            this.object = {
                ...this.step1,
                ...this.step2,
                tariff: this.tariff,
            };
            return this.object;
        }
        return undefined;
    }

    save() {
        this.progress = true;
        this.obj.set(this.update()).subscribe({
            next: () => {
                this.desc.update(true).subscribe();
                this.router.navigate(['objects']);
            },
            complete: () => this.progress = false
        });
    }
}

