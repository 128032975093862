import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UrlPathService} from './url-path.service';
import {saveAs} from 'file-saver';

export interface LogSimpleLine {
    TIME: string | Date;

    base: number;

    METKA_HEX: string;
    metka_id: number;
    metka_phone: string;
    metka_type_id: number;
    status_id: number;

    address: number;
    address_local: number;
    param: number | null;

    phone: string | null;
    object: number;
    tkd: number;
}

@Injectable({
    providedIn: 'root'
})
export class LogService {
    constructor(
        private http: HttpClient,
        private url: UrlPathService
    ) {
    }

    log(params: HttpParams): Promise<LogSimpleLine[]> {
        return this.http.get<LogSimpleLine[]>(this.url.log(), {params: params})
            .toPromise()
            .then(log => log
                .map(l => {
                    return {...l, TIME: new Date(l.TIME)};
                }));
    }

    download(params: HttpParams): Promise<void> {
        return new Promise<any>(((resolve, reject) => {
            this.http.get(this.url.log(), {params: params.append('download', 'csv'), responseType: 'arraybuffer'})
                .subscribe({
                    next: (log) => {
                        const file = new File([log], `log.csv`);
                        saveAs(file);
                    },
                    complete: () => {
                        resolve();
                    },
                    error: () => reject()
                });
        }));
    }
}
