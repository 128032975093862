import { Component, OnInit, forwardRef, OnDestroy } from '@angular/core';
import { DescriptionService } from 'src/app/description.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-address-selector',
  templateUrl: './address-selector.component.html',
  styleUrls: ['./address-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressSelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressSelectorComponent),
      multi: true,
    }
  ]
})
export class AddressSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  $desc: Subscription;

  address_id: number | null;
  enabled = true;
  objectKeys = Object.keys;

  proxyChange(val: any) {
    if (this.onChange) {
      this.onChange(val.value);
    }
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  public validate(c: FormControl) {
    return (this.address_id == null) ? {
      required: {
        valid: false,
      }
    } : null;
  }

  constructor(public desc: DescriptionService) { }

  ngOnInit() {
    this.$desc = this.desc.update().subscribe();
  }

  ngOnDestroy() {
    this.$desc.unsubscribe();
  }


  writeValue(address_id: any): void {
    this.address_id = address_id;
    this.onChange(this.address_id);
  }

  registerOnChange(fn: any): void { this.onChange = fn; }
  registerOnTouched(fn: any): void { this.onTouched = fn; }
  setDisabledState?(isDisabled: boolean): void { this.enabled = !isDisabled; }

  createNew() {

  }
}
