import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule} from './material-module';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { JsonPipe } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';

import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { NgxIpModule } from 'ngx-ip';
import { MdePopoverModule } from '@material-extended/mde';

import { DeviceDetectorModule } from 'ngx-device-detector';

/* recapcha */
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

/* Локаль */
import {LOCALE_ID} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
registerLocaleData(localeRu, 'ru');

/* Domophone */
import { NavbarComponent } from './navbar/navbar.component';
import { DescriptionService } from './description.service';
import { UrlPathService } from './url-path.service';

import { IDDataService } from './iddata';
import { BaseService } from './base';
import { ObjectService } from './object';

import { LoginComponent, LoginDialog, AddHeaderLogin, AuthService, LoginActivate, ErrorSheet } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { UsersComponent } from './users/users.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { IDComponent } from './id/id.component';
import { MetkaComponent, MetkaDialogComponent } from './metka/metka.component';
import { DescrEditorComponent } from './descr-editor/descr-editor.component';
import { BaseComponent, BaseSingleComponent, BaseDialogComponent } from './base/base.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { BaseAddComponent } from './base/base.add.component';
import { HexPipe } from './hex.pipe';
import { GroupDetailsComponent } from './group-details/group-details.component';
import { DiggitsDirective } from './diggits.directive';
import { ExcelImporterComponent } from './excel-importer/excel-importer.component';
import { IconComponent } from './icon/icon.component';
import { DescrComponent, DescrExpandComponent } from './descr/descr.component';

import { PasswordRestoreComponent } from './password-restore/password-restore.component';
import { ConfirmEmailComponent } from './password-restore/confirm_email.component';
import { NewPasswordComponent } from './password-restore/new-password.component';
import { AddressSelectorComponent } from './object/address-selector/address-selector.component';
import { DisableControlDirective } from './disable-control.directive';

import { ObjectCreateComponent } from './object/object.create';
import { ObjectComponent, ObjectSingleComponent } from './object/object.view';
import { ObjectDetailComponent } from './object/object.detail';
import { ObjectEditDialog } from './object/object.dialog';
import { BaseSelectorComponent } from './base-selector/base-selector.component';
import { ObjCreateStep1Component } from './object/step1/obj-create-step1.component';
import { ObjCreateStep2Component } from './object/step2/obj-create-step2.component';
import { ObjCreateStep3Component } from './object/step3/obj-create-step3.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoggerComponent } from './logger/logger.component';
import { DateLocalPipe } from './date-local.pipe';
import { ShrinkPipe } from './shrink.pipe';
import { RulesComponent } from './rules/rules.component';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    LoginDialog,
    RegistrationComponent,
    UsersComponent,
    ConfirmationDialogComponent,
    IDComponent,
    MetkaComponent, MetkaDialogComponent,
    DescrEditorComponent,
    BaseComponent, BaseSingleComponent, BaseDialogComponent,
    SidenavComponent,
    ErrorSheet,
    BaseAddComponent,
    HexPipe,
    GroupDetailsComponent,
    DiggitsDirective,
    ExcelImporterComponent,
    IconComponent,
    DescrComponent, DescrExpandComponent, PasswordRestoreComponent, ConfirmEmailComponent, NewPasswordComponent,
    ObjectCreateComponent, ObjectComponent, ObjectSingleComponent, ObjectDetailComponent, AddressSelectorComponent, ObjectEditDialog,
    DisableControlDirective,
    BaseSelectorComponent,
    ObjCreateStep1Component,
    ObjCreateStep2Component,
    ObjCreateStep3Component,
    LoggerComponent,
    DateLocalPipe,
    ShrinkPipe,
    RulesComponent
  ],
  imports: [
    BrowserModule,
    RecaptchaV3Module,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxIpModule,
    TextMaskModule,
    NgxMatIntlTelInputModule,
    MdePopoverModule,
    DeviceDetectorModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [JsonPipe, UrlPathService, AuthService, LoginActivate, DescriptionService, IDDataService, BaseService, ObjectService,
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: HTTP_INTERCEPTORS, useClass: AddHeaderLogin, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LclXcQUAAAAABha_jgGl-2bSW1OWaNyE2vVLM8m' },
  ],
  entryComponents: [LoginDialog, ConfirmationDialogComponent, ErrorSheet, BaseDialogComponent, MetkaDialogComponent, ObjectEditDialog],
  bootstrap: [AppComponent]
})
export class AppModule { }
