<h1 mat-dialog-title i18n>Вход</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input type="text" matInput tabindex="1" i18n-placeholder placeholder="email пользователя" [(ngModel)]="data.email" autofocus #email>
  </mat-form-field>
  <mat-form-field>
    <input type="password" matInput tabindex="2" i18n-placeholder placeholder="Пароль" [(ngModel)]="data.password" #password>
  </mat-form-field>
  <p class="mat-error" *ngIf="failed" i18n>Пароль или имя пользователя неверны</p>
</div>
<div mat-dialog-actions>
  <button *ngIf="!auth.loggedIn" mat-button tabindex="4" (click)="registration()" color="primary" i18n>Регистрация</button>
  <button *ngIf="auth.loggedIn && !auth.validated" mat-button tabindex="4" (click)="registration()" color="primary" i18n>Продолжить регистрацию</button>
  <span class="flex-spacer"></span>
  <button *ngIf="!auth.loggedIn" mat-button tabindex="3" cdkFocusInitial (click)="login()" color="primary" i18n>Продолжить</button>
  <button *ngIf="auth.loggedIn" mat-button tabindex="3" cdkFocusInitial (click)="logout()" color="primary" i18n>Выход</button>
</div>
<a mat-button color="warn" [routerLink]="'/restore'" class="restore" i18n>Восстановление пароля</a>
