import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { DescriptionService } from '../description.service';


// TODO: Перепроверить, что ВСЕГДА формируется один запрос и только один запрос
@Component({
  selector: 'app-descr',
  templateUrl: './descr.component.html'
})
export class DescrComponent implements OnInit, OnDestroy {
  @Input() val: 'group' | 'address' | 'status' | 'metka_type' | 'user' | 'base' | 'construct' | 'object' | 'tkd';
  @Input() id;

  text: string;

  desc$: Subscription;
  update$: Subscription;


  constructor(
    public desc: DescriptionService
  ) { }

  ngOnInit() {
    this.text = this.desc.get(this.val, this.id);
    this.desc$ = this.desc.afterUpdate.subscribe({
      next: (desc) => {
        this.text = this.desc.get(this.val, this.id);
      }
    });
    this.update$ = this.desc.update().subscribe();
  }

  ngOnDestroy() {
    this.desc$.unsubscribe();
    this.update$.unsubscribe();
  }
}

@Component({
  selector: 'app-descr-expand',
  templateUrl: './descr.expandable.html'
})
export class DescrExpandComponent {
  @Input() val: 'group' | 'address' | 'status' | 'metka_type' | 'user' | 'base' | 'construct' | 'object';
  @Input() ids: number[];
  @Input() title: string;
  @Output() select: EventEmitter<number> = new EventEmitter<number>();

  callback(id: number) {
    this.select.emit(id);
  }
}
