import { Pipe, PipeTransform } from '@angular/core';

function subs(a: string, b: string): string | null {
  a = a.split('').reverse().join('');
  b = b.split('').reverse().join('');
  let c = '';

  let carry = 0;

  for (let i = 0; i < a.length || i < b.length; ++i) {
    const _a = i < a.length ? a[i] : '0';
    const _b = i < b.length ? b[i] : '0';
    let _c = parseInt(_a, 10) - parseInt(_b, 10) - carry;
    if (_c < 0) {
      _c = _c + 10;
      carry = 1;
    } else {
      carry = 0;
    }
    c = c + _c;
  }
  return carry ? null : c.split('').reverse().join('');
}

export
function dec2hex(value: string): string {
  if (value === undefined) {
    return '';
  }

  if (value.length && value[0] === '-') {
    return 'overflow';
  }


  const alpha = '0123456789ABCDEF';
  let res = '';

  const digits = [
    '1152921504606846976',
    '72057594037927936',
    '4503599627370496',
    '281474976710656',
    '17592186044416',
    '1099511627776',
    '68719476736',
    '4294967296',
    '268435456',
    '16777216',
    '1048576',
    '65536',
    '4096',
    '256',
    '16',
    '1',
  ];

  for (let i = 0; i < 16; ++i) {
    let v = 0;
    const p = digits[i];
    for (; v < 15; ++v) {
      const next = subs(value, p);
      if (next === null) {
        break;
      }
      value = next;
    }
    res = res + alpha[v];
  }

  return res;
}

/**
 * A function for converting hex <-> dec w/o loss of precision.
 *
 * The problem is that parseInt("0x12345...") isn't precise enough to convert
 * 64-bit integers correctly.
 *
 * Internally, this uses arrays to encode decimal digits starting with the least
 * significant:
 * 8 = [8]
 * 16 = [6, 1]
 * 1024 = [4, 2, 0, 1]
 *
 * Source: http://www.danvk.org/hex2dec.html
 */

// Adds two arrays for the given base (10 or 16), returning the result.
// This turns out to be the only "primitive" operation we need.
function add(x, y, base) {
  let z = [];
  let n = Math.max(x.length, y.length);
  let carry = 0;
  let i = 0;
  while (i < n || carry) {
    let xi = i < x.length ? x[i] : 0;
    let yi = i < y.length ? y[i] : 0;
    let zi = carry + xi + yi;
    z.push(zi % base);
    carry = Math.floor(zi / base);
    i++;
  }
  return z;
}

// Returns a*x, where x is an array of decimal digits and a is an ordinary
// JavaScript number. base is the number base of the array x.
function multiplyByNumber(num, x, base) {
  if (num < 0) { return null; }
  if (num == 0) { return []; }

  let result = [];
  let power = x;
  while (true) {
    if (num & 1) {
      result = add(result, power, base);
    }
    num = num >> 1;
    if (num === 0) { break; }
    power = add(power, power, base);
  }

  return result;
}

function parseToDigitsArray(str, base) {
  let digits = str.split('');
  let ary = [];
  for (let i = digits.length - 1; i >= 0; i--) {
    let n = parseInt(digits[i], base);
    if (isNaN(n)) { return null; }
    ary.push(n);
  }
  return ary;
}

function convertBase(str, fromBase, toBase) {
  let digits = parseToDigitsArray(str, fromBase);
  if (digits === null) { return null; }

  let outArray = [];
  let power = [1];
  for (let i = 0; i < digits.length; i++) {
    // invariant: at this point, fromBase^i = power
    if (digits[i]) {
      outArray = add(outArray, multiplyByNumber(digits[i], power, toBase), toBase);
    }
    power = multiplyByNumber(fromBase, power, toBase);
  }

  let out = '';
  for (let i = outArray.length - 1; i >= 0; i--) {
    out += outArray[i].toString(toBase);
  }
  if (out === '') {
    out = '0';
  }
  return out;
}

export function hexToDec(hexStr) {
  if (hexStr.substring(0, 2) === '0x') hexStr = hexStr.substring(2);
  hexStr = hexStr.toLowerCase();
  return convertBase(hexStr, 16, 10);
}

@Pipe({
  name: 'hex'
})
export class HexPipe implements PipeTransform {
  transform(value: number): string {
      return value !== undefined ? dec2hex(value.toString()) : '';
  }
}
