import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Router} from '@angular/router';

import {MatDialog} from '@angular/material/dialog';

import {DescriptionService} from '../description.service';
import {ObjectService} from '../object';
import {ObjectData} from '../object';
import {ObjectBaseComponent} from './object.create';
import {ObjectEditDialog} from './object.dialog';
import {Subscription, of} from 'rxjs';

@Component({
    selector: 'app-single-object',
    templateUrl: './object.single.html',
    styleUrls: ['./object.common.scss']
})
export class ObjectSingleComponent extends ObjectBaseComponent implements OnInit, OnDestroy {
    @Input() object: ObjectData = null;

    constructor(
        public desc: DescriptionService,
        public obj: ObjectService
    ) {
        super(desc, obj);
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
    }
}

@Component({
    selector: 'app-multi-object',
    templateUrl: './object.table.html',
    styleUrls: ['./object.common.scss']
})
export class ObjectComponent extends ObjectBaseComponent implements OnInit, OnDestroy {
    objects$: Subscription;

    constructor(
        public desc: DescriptionService,
        public obj: ObjectService,
        public router: Router,
        public dialog: MatDialog
    ) {
        super(desc, obj);
    }

    ngOnInit() {
        this.objects$ = this.obj.get().subscribe();
    }

    ngOnDestroy(): void {
        if (this.objects$) {
            this.objects$.unsubscribe();
        }
    }

    delete(object: ObjectData) {
        this.obj.delete(object).subscribe();
    }

    edit(object: ObjectData) {
        const dialogRef = this.dialog.open(ObjectEditDialog, {
            width: '80vw',
            data: object
        });
        return dialogRef.afterClosed().subscribe();
    }

    details(object: ObjectData) {
        this.router.navigate(['objects', object.object_id]);
    }
}
