<div class="mat-app-background basic-container">
    <mat-card *ngIf="
      !external_filter &&
      (filter.group_description_id ||
        filter.user_id ||
        filter.address_common_id ||
        filter.status_id ||
        filter.metka_type_id)
    ">
        <mat-card-subtitle i18n>
            Выбранные фильтры:
        </mat-card-subtitle>

        <mat-card-content>
            <a *ngIf="
          filter.group_description_id &&
          desc &&
          desc.group[filter.group_description_id]
        " [routerLink]="'/ids'" [queryParams]="shrink('group_description_id')">
                <app-descr val="group" [id]="filter.group_description_id"></app-descr>
                <mat-icon>close</mat-icon>
            </a>

            <a *ngIf="filter.user_id && desc && desc.user[filter.user_id]" [routerLink]="'/ids'"
               [queryParams]="shrink('user_id')">
                <app-descr val="user" [id]="filter.user_id"></app-descr>
                <mat-icon>close</mat-icon>
            </a>

            <a *ngIf="
          filter.address_common_id &&
          desc &&
          desc.address[filter.address_common_id]
        " [routerLink]="'/ids'" [queryParams]="shrink('address_common_id')">
                <app-descr val="address" [id]="filter.address_common_id"></app-descr>
                <mat-icon>close</mat-icon>
            </a>

            <a *ngIf="filter.base_id && desc && desc.base[filter.base_id]" [routerLink]="'/ids'"
               [queryParams]="shrink('base_id')">
                <app-descr val="base" [id]="filter.base_id"></app-descr>
                <mat-icon>close</mat-icon>
            </a>

            <a *ngIf="filter.status_id && desc && desc.status[filter.status_id]" [routerLink]="'/ids'"
               [queryParams]="shrink('status_id')">
                <app-descr val="status" [id]="filter.status_id"></app-descr>
                <mat-icon>close</mat-icon>
            </a>

            <a *ngIf="
          filter.metka_type_id && desc && desc.metka_type[filter.metka_type_id]
        " [routerLink]="'/ids'" [queryParams]="shrink('metka_type_id')">
                <app-descr val="metka_type" [id]="filter.metka_type_id"></app-descr>
                <mat-icon>close</mat-icon>
            </a>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-title>
            <mat-icon *ngIf="updating===true" i18n-matTooltip matTooltip="Идёт обновление меток">update</mat-icon>
            {{ title }}
            <a [disabled]="!allow_add" (click)="add_metka()" mat-raised-button color="primary"
               [matTooltip]="allow_add?'Добавить метку':'Управление метками возможно только из интерфейса контроллера'"
               i18n>Добавить
                метку</a>
            <a routerLink="/metka/mass" mat-raised-button color="primary" i18n>Загрузить список жильцов</a>
            <button mat-raised-button *ngIf="!selection.isEmpty() && !group_selector" (click)="get_selection()"
                    color="primary" i18n>
                Управление группами выбранных меток
            </button>
            <button mat-raised-button *ngIf="group_selector" (click)="ngOnInit()" color="primary" i18n>
                Назад
            </button>
        </mat-card-title>
        <mat-card-content class="table_metka">
            <mat-form-field class="wide">
                <mat-label i18n>Поиск</mat-label>
                <input matInput (keyup)="applyFilter($event)"/>
                <mat-icon matSuffix>search</mat-icon>
                <mat-hint i18n>Поиск по меткам</mat-hint>
            </mat-form-field>
            <mat-card *ngIf="group_selector">
                <mat-card-title>Управление выбранными метками</mat-card-title>
                <mat-card-content>
                    <mat-card-subtitle>Группы контроллеров</mat-card-subtitle>

                    <mat-form-field *ngIf="!selection.isEmpty()" class="wide">
                        <mat-label i18n>Добавить выбранные метки в группу</mat-label>
                        <mat-select [(value)]="add_group_ids">
                            <mat-option *ngFor="let key of objectKeys(desc.group)" [value]="desc.group[key].id">
                                <app-descr val="group" [id]="key"></app-descr>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <a *ngIf="add_group_ids !== null" mat-raised-button color="primary" (click)="addSelectedGroup()"
                       i18n>
                        Добавить выбранные метки в группу
                    </a>

                    <mat-form-field *ngIf="!selection.isEmpty()" class="wide">
                        <mat-label i18n>Удалить выбранные метки из группы</mat-label>
                        <mat-select [(value)]="remove_group_ids">
                            <mat-option *ngFor="let key of avaible_groups_to_remove" [value]="desc.group[key].id">
                                <app-descr val="group" [id]="key"></app-descr>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <a *ngIf="remove_group_ids !== null" mat-raised-button color="primary"
                       (click)="removeSelectedGroup()"
                       i18n>Удалить
                        выбранные метки из группы</a>
                    <mat-card-subtitle>Контроллеры</mat-card-subtitle>
                    <mat-form-field *ngIf="!selection.isEmpty()" class="wide">
                        <mat-label i18n>Добавить выбранные метки в контроллер</mat-label>
                        <mat-select [(value)]="add_base_id">
                            <mat-option *ngFor="let key of objectKeys(desc.base)" [value]="desc.base[key].id">
                                <app-descr val="base" [id]="key"></app-descr>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <a *ngIf="add_base_id !== null" mat-raised-button color="primary" (click)="addSelectedBase()" i18n>
                        Добавить выбранные метки к контроллеру
                    </a>

                    <mat-form-field *ngIf="!selection.isEmpty()" class="wide">
                        <mat-label i18n>Удалить выбранные метки из контроллера</mat-label>
                        <mat-select [(value)]="remove_base_id">
                            <mat-option *ngFor="let key of avaible_bases_to_remove" [value]="desc.base[key].id">
                                <app-descr val="base" [id]="key"></app-descr>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <a *ngIf="remove_base_id !== null" mat-raised-button color="primary" (click)="removeSelectedBase()"
                       i18n>
                        Удалить выбранные метки из контроллера
                    </a>
                    <!----------------------->
                    <mat-divider></mat-divider>
                    <mat-card-subtitle>Удалённое открытие</mat-card-subtitle>
                    <mat-form-field *ngIf="!selection.isEmpty()" class="wide">
                        <mat-label i18n>Задать возможность удалённого открытия</mat-label>
                        <mat-select [(value)]="remote_open">
                            <mat-option value="default">Не менять</mat-option>
                            <mat-option value="disallow">Запретить</mat-option>
                            <mat-option value="base">Задаётся контроллером</mat-option>
                            <mat-option value="individual_add">Индивидуальные разрешения</mat-option>
                            <mat-option value="individual_remove">Индивидуальные запрещения</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="remote_open=='individual_add'">
                        <mat-form-field>
                            <mat-label i18n>Разрешить открытие на следующих контроллерах</mat-label>
                            <mat-select [formControl]="group_bases_remote" multiple [disabled]="metka.value.REMOTE==-1">
                                <mat-option *ngFor="let key of objectKeys(desc.base)" [value]="desc.base[key].id"
                                            [disabled]="desc.base[key].TAGSYNC=='0'">
                                    <app-descr val="base" [id]="key"></app-descr>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <a mat-raised-button color="primary" (click)="remoteSelectedMetka(remote_open)">
                            Добавить удалённое открытие
                        </a>
                    </ng-container>
                    <ng-container *ngIf="remote_open=='individual_remove'">
                        <mat-form-field>
                            <mat-label i18n>Запретить открытие на следующих контроллерах</mat-label>
                            <mat-select [formControl]="group_bases_remote" multiple [disabled]="metka.value.REMOTE==-1">
                                <mat-option *ngFor="let key of objectKeys(desc.base)" [value]="desc.base[key].id"
                                            [disabled]="desc.base[key].TAGSYNC=='0'">
                                    <app-descr val="base" [id]="key"></app-descr>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <a mat-raised-button color="primary" (click)="remoteSelectedMetka(remote_open)">
                            Запретить удалённое открытие
                        </a>
                    </ng-container>
                    <ng-container *ngIf="remote_open=='base'">
                        <a mat-raised-button color="primary" (click)="remoteSelectedMetka(remote_open)">
                            Сбросить все индивидуальные запреты и разрешения
                        </a>
                    </ng-container>
                    <ng-container *ngIf="remote_open=='disallow'">
                        <a mat-raised-button color="accent" (click)="remoteSelectedMetka(remote_open);">
                            Запретить удалённое открытие
                        </a>
                    </ng-container>
                    <!----------------------->
                    <mat-divider></mat-divider>
                    <mat-card-subtitle>Удаление меток</mat-card-subtitle>
                    <a mat-raised-button color="accent" (click)="removeSelectedMetka()">
                        Удалить выбранные метки
                    </a>
                </mat-card-content>
                <mat-card-footer *ngIf="updating">
                    <mat-progress-bar mode="determinate" color="accent"
                                      [value]="updating_progress"></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
            <form [formGroup]="metka">
                <mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="select">
                        <mat-header-cell *matHeaderCellDef class="select_col">
                            <mat-checkbox (change)="$event ? masterToggle() : null" class="table_checkbox"
                                          [checked]="selection.hasValue() && isAllSelected()"
                                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                                          [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" class="select_col">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                          (change)="$event ? selection.toggle(row.id) : null"
                                          class="table_checkbox" [checked]="selection.isSelected(row.id)"
                                          [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="change_state">
                        <mat-header-cell *matHeaderCellDef class="select_col">

                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="select_col">
                            <mat-icon *ngIf="element.change_state>0" i18n-matTooltip
                                      matTooltip="Идёт обновление данных метки">update
                            </mat-icon>
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef class="action_row" i18n>
                            Действия
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="action_row">
                            <ng-container *ngIf="edit_id == null">
                                <ng-container *ngIf="element.metka_type_id!=3">
                                    <button mat-icon-button (click)="downloadLog(element)" i18n-matTooltip
                                            matTooltip="Журнал событий">
                                        <mat-icon class="md-18">assignment_returned</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="edit(element)" i18n-matTooltip
                                            matTooltip="Быстрое редактирование метки">
                                        <mat-icon class="md-18">edit</mat-icon>
                                    </button>
                                </ng-container>
                                <button mat-icon-button (click)="delete(element)" i18n-matTooltip
                                        matTooltip="Удалить метку">
                                    <mat-icon class="md-18">delete</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="element.id != null && edit_id == element.serial">
                                <button mat-icon-button (click)="cancel()">
                                    <mat-icon class="md-18" color="warn">cancel</mat-icon>
                                </button>
                                <button mat-icon-button (click)="done()">
                                    <mat-icon class="md-18">done</mat-icon>
                                </button>
                            </ng-container>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            #
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="serial">
                        <mat-header-cell *matHeaderCellDef class="serial" mat-sort-header i18n>
                            Идентификационный номер
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="serial">
                            <ng-container *ngIf="element.metka_type_id!=3; else createMetka">
                                <a mat-button (click)="edit_metka(element.serial)"
                                   [color]="element.base_only?undefined:'primary'"
                                   [matTooltip]="element.base_only?'Метка зарегестрирована только на контроллере':''">
                                    {{ element.serial | hex }}
                                </a>
                            </ng-container>

                            <ng-template #createMetka>
                                <a mat-button color="primary" [routerLink]="['/metka']" [queryParams]="element" i18n>Создать
                                    метку</a>
                            </ng-template>

                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="group_ids">
                        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>
                            Группа меток
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="
                  element.id != null && edit_id == element.serial;
                  else show_group
                ">
                                <mat-form-field>
                                    <mat-label i18n>Группы</mat-label>
                                    <mat-select formControlName="group_ids" multiple
                                                (selectionChange)="updateFromGroups()">
                                        <mat-option *ngFor="let key of objectKeys(desc.group)"
                                                    [value]="desc.group[key].id">
                                            <app-descr val="group" [id]="key"></app-descr>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-template #show_group>
                                <app-descr-expand val="group" [ids]="element.group_ids" i18n-title title="Группы метки"
                                                  (select)="groups_callback($event)">
                                </app-descr-expand>
                            </ng-template>
                        </mat-cell>
                    </ng-container>

                    <!-- Контроллеры -->
                    <ng-container matColumnDef="group_bases">
                        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Контроллеры</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="
                  element.id != null && edit_id == element.serial;
                  else show_controls
                ">
                                <mat-form-field>
                                    <mat-label i18n>Контроллеры</mat-label>
                                    <mat-select formControlName="group_bases" multiple>
                                        <mat-option *ngFor="let key of objectKeys(desc.base)"
                                                    [value]="desc.base[key].id"
                                                    [disabled]="desc.base[key].TAGSYNC=='0'">
                                            <app-descr val="base" [id]="key"></app-descr>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-template #show_controls>
                                <app-descr-expand val="base" [ids]="element.group_bases" i18n-title
                                                  title="Контроллеры метки"
                                                  (select)="controls_callback($event)">
                                </app-descr-expand>
                            </ng-template>
                        </mat-cell>
                    </ng-container>

                    <!-- Удалённое открытие -->
                    <ng-container matColumnDef="group_remote_bases">
                        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Удалённое открытие</mat-header-cell>
                        <mat-cell *matCellDef="let element" (click)="edit_metka(element.serial)">
                            <ng-container *ngIf="element.REMOTE">
                                <ng-container *ngIf="element.REMOTE==0"><a mat-button color="warn">Запрещено</a>
                                </ng-container>
                                <ng-container *ngIf="element.REMOTE==-1"><a mat-button>Не задано</a></ng-container>
                                <ng-container *ngIf="element.REMOTE==1">
                                    <app-descr-expand val="base" [ids]="element.group_bases_remote" i18n-title
                                                      title="Контроллеры к которым есть удалённый доступ">
                                    </app-descr-expand>
                                </ng-container>
                            </ng-container>
                        </mat-cell>
                    </ng-container>

                    <!-- Объекты -->
                    <ng-container matColumnDef="objects">
                        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Объекты</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <app-descr-expand val="object" [ids]="element.objects" i18n-title
                                              title="Объекты куда привязана метка" (select)="objects_callback($event)">
                            </app-descr-expand>
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="comment">
                        <mat-header-cell *matHeaderCellDef i18n>Комментарий</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="
                  element.id != null && edit_id == element.serial;
                  else show_comment
                ">
                                <mat-form-field>
                                    <input matInput i18n-placeholder placeholder="Комментарий"
                                           formControlName="comment"/>
                                </mat-form-field>
                            </ng-container>
                            <ng-template #show_comment>
                                {{ element.comment }}
                            </ng-template>
                        </mat-cell>
                    </ng-container>

                    <!-- Адрес регистрации -->
                    <ng-container matColumnDef="address_common_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Адрес регистрации</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="
                  element.id != null && edit_id == element.serial;
                  else show_address
                ">
                                <mat-form-field>
                                    <mat-label i18n>Адрес регистрации</mat-label>
                                    <mat-select formControlName="address_common_id" [compareWith]="desc.compareWith">
                                        <mat-option *ngFor="let key of objectKeys(desc.address)"
                                                    [value]="desc.address[key].id">
                                            <app-descr val="address" [id]="key"></app-descr>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-template #show_address>
                                <a *ngIf="element.address_common_id" [routerLink]="'/ids'" [queryParams]="
                    expand('address_common_id', element.address_common_id)
                  ">
                                    <app-descr val="address" [id]="element.address_common_id"></app-descr>
                                </a>
                            </ng-template>
                        </mat-cell>
                    </ng-container>
                    <!-- Номер квартиры -->
                    <ng-container matColumnDef="address_local">
                        <mat-header-cell *matHeaderCellDef i18n>Номер квартиры</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="
                  element.id != null && edit_id == element.serial;
                  else show_address_local
                ">
                                <mat-form-field>
                                    <input matInput i18n-placeholder placeholder="Номер квартиры"
                                           formControlName="address_local"/>
                                </mat-form-field>
                            </ng-container>
                            <ng-template #show_address_local>
                                {{ element.address_local }}
                            </ng-template>
                        </mat-cell>
                    </ng-container>
                    <!-- Статус -->
                    <ng-container matColumnDef="status_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="status_id">Статус</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.id != null && edit_id == element.serial;else show_status">
                                <mat-form-field>
                                    <mat-label i18n>Статус метки</mat-label>
                                    <mat-select formControlName="status_id" [compareWith]="desc.compareWith">
                                        <mat-option *ngFor="let key of objectKeys(desc.status)"
                                                    [value]="desc.status[key].id">
                                            <app-descr val="status" [id]="key"></app-descr>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-template #show_status>
                                <ng-container
                                        *ngIf="element.metka_type_id!=3 && element.group_bases.length && desc && desc.status[1] && desc.status[2]">
                                    <button mat-icon-button [ngSwitch]="element.status_id">
                                        <mat-icon *ngSwitchCase="1" class="md-18" (click)="inverseState(element);"
                                                  [matTooltip]="desc.status[1].status">radio_button_unchecked
                                        </mat-icon>
                                        <mat-icon *ngSwitchCase="2" class="md-18" (click)="inverseState(element);"
                                                  [matTooltip]="desc.status[2].status" color="primary">
                                            radio_button_checked
                                        </mat-icon>
                                    </button>
                                </ng-container>


                            </ng-template>
                        </mat-cell>
                    </ng-container>
                    <!-- Тип -->
                    <ng-container matColumnDef="metka_type_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Тип метки</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a mat-icon-button [ngSwitch]="element.metka_type_id" [routerLink]="'/ids'"
                               [queryParams]="expand('metka_type_id', element.metka_type_id)">
                                <app-icon *ngSwitchCase="1" icon="phone" i18n-label
                                          label="Электронная метка"></app-icon>
                                <app-icon *ngSwitchCase="2" icon="brelok" i18n-label label="брелок"></app-icon>
                                <app-icon *ngSwitchCase="3" icon="type_3" i18n-label
                                          label="метка не создана"></app-icon>
                            </a>
                        </mat-cell>
                    </ng-container>
                    <!-- Пользователь -->
                    <ng-container matColumnDef="user_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Менеджер</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="
                  element.id != null && edit_id == element.serial;
                  else show_user
                ">
                                <mat-label i18n>Менеджер</mat-label>
                                <mat-select formControlName="user_id" [compareWith]="desc.compareWith">
                                    <mat-option *ngFor="let key of objectKeys(desc.user)" [value]="desc.user[key].id">
                                        <app-descr val="user" [id]="key"></app-descr>
                                    </mat-option>
                                </mat-select>
                            </ng-container>
                            <ng-template #show_user>
                                <app-descr val="user" [id]="element.user_id"></app-descr>
                            </ng-template>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="phone">
                        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Телефон</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="
                  element.id != null && edit_id == element.serial;
                  else show_phone
                ">
                                <mat-form-field>
                                    <input matInput i18n-placeholder placeholder="Телефон" formControlName="phone"
                                           appDiggits/>
                                </mat-form-field>
                            </ng-container>
                            <ng-template #show_phone>
                                <a mat-button color="accent" [href]="'tel:'+element.phone">{{ element.phone }}</a>
                            </ng-template>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="aviability">
                        <mat-header-cell *matHeaderCellDef i18n>Время доступа</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="
                  element.id != null && edit_id == element.serial;
                  else show_aviabile
                ">
                            </ng-container>
                            <ng-template #show_aviabile></ng-template>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="activation_time">
                        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Время активации метки</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="
                  element.id != null && edit_id == element.serial;
                  else show_activation
                ">
                                <input matInput [matDatepicker]="activeDatepicker" i18n-placeholder
                                       placeholder="активна с"
                                       formControlName="activation_time"/>
                                <mat-datepicker-toggle matSuffix [for]="activeDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #activeDatepicker></mat-datepicker>
                            </ng-container>
                            <ng-template #show_activation>
                                {{ element.activation_time | date: "shortDate" }}
                            </ng-template>
                        </mat-cell>
                    </ng-container>
                    <!-- Срок действия -->
                    <ng-container matColumnDef="time_exp">
                        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Срок действия</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="
                  element.id != null && edit_id == element.serial && element.metka_type_id==1;
                  else show_until
                ">
                                <input matInput [matDatepicker]="validDatepicker" i18n-placeholder
                                       placeholder="активна до" formControlName="time_exp"/>
                                <mat-datepicker-toggle matSuffix [for]="validDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #validDatepicker></mat-datepicker>
                            </ng-container>
                            <ng-template #show_until>
                                <button *ngIf="element.time_exp - now > 0; else badTime"
                                        (click)="edit_metka(element.serial)"
                                        mat-button>{{ element.time_exp | date: "shortDate" }}</button>
                                <ng-template #badTime>
                                    <button mat-button color="warn" (click)="edit_metka(element.serial)"
                                            i18n-matTooltip
                                            matTooltip="Срок действия электронного ключа закончился">{{ element.time_exp | date: "shortDate" }}</button>
                                </ng-template>
                            </ng-template>
                        </mat-cell>
                    </ng-container>

                    <!-- Заголовок группы -->
                    <ng-container matColumnDef="groupHeader">
                        <mat-row>
                            <mat-header-cell colspan="999" *matCellDef="let groupBy">
                                <h3>{{ groupBy.value }}</h3>
                            </mat-header-cell>
                        </mat-row>
                    </ng-container>

                    <!-- Заголовоки -->
                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                    <!-- Обычные строки -->
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                    <!-- Строка подзаголовка -->
                    <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"></mat-row>
                </mat-table>
                <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>
            </form>
        </mat-card-content>
    </mat-card>
</div>
