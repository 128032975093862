/* angular */
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
/* matrerial */
import { MatSort } from '@angular/material/sort';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';

/* rxjs */
import { Subscription } from 'rxjs';
/* local */
import { DescriptionService, EditorDescription } from '../description.service';

@Component({
  selector: 'app-descr-editor',
  templateUrl: './descr-editor.component.html',
  styleUrls: ['./descr-editor.component.scss']
})
export class DescrEditorComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  params$: Subscription;
  display: EditorDescription;

  active: any;

  form: FormGroup;
  matcher = new ErrorStateMatcher();

  edit_id: number | null = null;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public desc: DescriptionService) { }

  ngOnInit() {
    this.active = {};
    this.desc.update().subscribe();
    this.params$ = this.route
      .params
      .subscribe({
        next: (query) => {
          console.log(query);
          this.display = this.desc.display[query.display];
          this.initForm();
        },
        error: (err) => console.log(err),
        complete: () => console.log('complete')
      });
  }

  ngOnDestroy(): void {
    this.params$.unsubscribe();
  }

  initForm() {
    const r = { id: 0 };
    for (let f = 0; f < this.display.fields.length; ++f) {
      if (this.display.fields[f].required === undefined) {
        r[this.display.fields[f].key] = [null];
      } else {
        r[this.display.fields[f].key] = [null, Validators.required];
      }
    }
    this.form = this.fb.group(r);
  }

  create() {
    this.active = {};
    this.initForm();
  }

  cancel() {
    this.select(this.active);
  }

  save() {
    this.desc.set(this.form.value, this.display).subscribe({
      next: () => this.desc.update(true).subscribe()
    });
  }

  select(row) {
    this.active = row;
    this.form.patchValue(row);
  }

  delete(row) {
    this.desc.delete(row, this.display).subscribe({
      next: () => this.desc.update(true).subscribe()
    });
  }
}
