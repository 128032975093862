import {Component, EventEmitter, Output, OnDestroy, OnInit} from '@angular/core';
import {DescriptionService} from '../../description.service';
import {ObjectService} from '../../object';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../login/login.component';
import {Router} from '@angular/router';

export class ObjectCreateStep1 {
    type_id: number;
    name: string;
    address: number;
    extra: string;
    user: number;
}

@Component({
    selector: 'app-create-obj-step1',
    templateUrl: './obj-create-step1.component.html',
    styleUrls: ['./../object.common.scss']
})
export class ObjCreateStep1Component implements OnInit, OnDestroy {
    form: FormGroup;
    objectKeys = Object.keys;

    @Output() ready = new EventEmitter<ObjectCreateStep1>();

    constructor(
        public desc: DescriptionService,
        public obj: ObjectService,
        public fb: FormBuilder,
        public auth: AuthService,
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            type_id: [{value: 1}, Validators.required],
            name: ['', Validators.required],
            address: [null, Validators.required],
            user: ['', Validators.required],
            extra: [''],
        });

        this.form.patchValue({type_id: 1, name: 'Название', user: this.auth.id});
    }

    ngOnDestroy() {

    }

    done() {
        if (this.form.valid) {
            this.ready.emit(this.form.value);
        }
    }
}
