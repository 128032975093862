<form [formGroup]="tariff">
    <div>
        <label i18n>
            Необходимо выбрать, тариф.</label>
        <button mat-button color="warn" i18n>
            Изменить выбранный метод тарификации в дальнейшем невозможно!
        </button>
        <mat-radio-group formControlName="starterkit">
            <mat-radio-button [value]="1">Starterkit</mat-radio-button>
            <mat-radio-button [value]="0">ELS Cloud Pro</mat-radio-button>
        </mat-radio-group>
    </div>
    <div>
        <p i18n>Предупреждение: Выбрав данный режим работы системы переход на другой режим не возможен!</p>
        <p i18n>При тарификации "starterkit" будет доступно 20 электронных меток на каждый
            контроллер, подключённый к объекту. Увеличение количества доступных
            электронных меток возможно только за счёт увеличения количества
            контроллеров.</p>
    </div>
    <div i18n>
        При тарификации "ELS Cloud Pro" количество меток не ограничено
    </div>
    <mat-card *ngIf="tariff.value.starterkit == 0">
        <mat-card-content>
            <mat-card-header>ELS Cloud Pro"</mat-card-header>
            <mat-form-field>
                <mat-label i18n>Срок пробного бесплатного периода пользования меткой</mat-label>
                <input matInput type="number" formControlName="freePeriod" min="0" max="3"/>
                <mat-hint i18n>
                    Введите количество месяцев пробного периода
                </mat-hint>
                <mat-error *ngIf="tariff.hasError('max','freePeriod')" i18n>
                    Время пробного периода не должно превышать 3 месяцев
                </mat-error>
            </mat-form-field>
            <div formArrayName="grid" class="grid">
                <div *ngFor="let gridItem of gridArray.controls; let i=index" [formGroupName]="i"
                     class="tariff_line">
                    <!--<mat-divider></mat-divider>-->
                    <mat-card-subtitle>
                        <ng-container i18n>Тариф за</ng-container>
                        {{getMonth(tariff.value.grid[i].monthes)}}
                    </mat-card-subtitle>
                    <mat-form-field class="inline">
                        <mat-label>
                            <ng-container i18n>Сумма за</ng-container>
                            {{getMonth(tariff.value.grid[i].monthes)}}
                        </mat-label>
                        <input matInput formControlName="payment" type="number" step="1"
                               [disableControl]="!tariff.value.grid[i].apply"/>
                        <span matSuffix i18n>.00 руб.</span>
                        <mat-error *ngIf="gridItem.hasError('min','payment')">
                            <ng-container i18n>Минимальная сумма для такого периода</ng-container>
                            {{gridItem.getError('min', 'payment').min}} рублей
                        </mat-error>
                    </mat-form-field>
                    <mat-checkbox class="inline" formControlName="apply" i18n>Применять тариф</mat-checkbox>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div>
        <button mat-button matStepperPrevious i18n>Назад</button>
        <button mat-button *ngIf="tariff.value.starterkit == 0 && gridArray.length < 4" (click)="appendTariff()">
            <ng-container i18n>Добавить тариф за</ng-container>
            {{getMonth(monthes(gridArray.length))}}
        </button>
        <button mat-button matStepperNext color="primary" [disabled]="tariff.invalid" (click)="update()" i18n>
            Дальше
        </button>
    </div>
</form>
