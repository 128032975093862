import { Injectable } from '@angular/core';
// import { environment } from '../environments/environment';
// export const php_root = environment.production?'/cgi':'http://localhost:8080/cgi';
export const php_root = 'cgi';

@Injectable()
export class UrlPathService {
  constructor() { }

  table(t: string): string {
    return php_root + `/sqlJsonify.php?table=${t}`;
  }
  login = () => php_root + '/sqlLogin.php';
  config = () => php_root + '/config.json';

// Пользователи
  registerUser = () => php_root + '/user/sqlRegisterUser.php';
  updateUser = () => php_root + '/user/sqlUpdateUser.php';
  users = () => php_root + '/user/sqlUserList.php';
  registerCompany = () => php_root + '/user/sqlRegisterCompany.php';
  company = () => php_root + '/user/sqlCompany.php';

// Работа с метками
  metka = () => php_root + '/metka/sqlMetka.php';
  metkaUpdate = () => php_root + '/metka/sqlUpdateMetka.php';
  metkaDelete = () => php_root + '/metka/sqlDeleteMetka.php';
  metkaLog = (metka_id) => php_root + `/metka/sqlLogMetka.php?metka_id=${metka_id}`;

// Работа с контроллерами
  base = () => php_root + '/base/sqlBase.php';
  baseUpdate = () => php_root + '/base/sqlUpdateBase.php';
  baseDelete = () => php_root + '/base/sqlDeleteBase.php';
  baseAdd = (base_sn, base_hash) => php_root + `/base/sqlAddBase.php?base_sn=${base_sn}&hash=${base_hash}`;
  baseLog = (metka_id) => php_root + `/base/sqlLogBase.php?base_id=${metka_id}`; // todo

  baseOpen = () => php_root + '/base/sqlRemoteOpenBase.php';

// Описания
  description = () => php_root + '/desc/sqlDescription.php';
  updateDesc = () => php_root + '/desc/sqlUpdateDesc.php';
  delDesc = () => php_root + '/desc/sqlDeleteDesc.php';

  geo = () => php_root + '/ip-api.php';

  crypto = (base_id: number) => php_root + `/sqlCrypto.php?base_id=${base_id}`; // todo

// Электронная почта
  restore = () => php_root + `/email/sqlRestory.php`;
  confirm = () => php_root + `/email/sqlConfirmEmail.php`;
  newPassword = () => php_root + `/email/sqlNewPassword.php`;

// Объекты
  object = () => php_root + '/object/sqlObject.php';
  objectUpdate = () => php_root + '/object/sqlObjectUpdate.php';
  objectDelete = () => php_root + '/object/sqlObjectDelete.php';
  objectRequest = () => php_root + '/object/sqlObjectRequests.php';
  objectPend = () => php_root + '/object/sqlObjectsPend.php';
  objectRequestState = () => php_root + '/object/sqlObjectRequestState.php';

// Журналы
  log = () => php_root + '/log/sqlLogCommon.php';
}
