import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date_local'
})
export class DateLocalPipe implements PipeTransform {

  transform(value: any): any {
    return (new Date(value)).toLocaleDateString();
  }

}
