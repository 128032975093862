import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {LogService, LogSimpleLine} from '../log.service';
import {MatSort, MatSortable} from '@angular/material/sort';
import {GroupBy, GroupedObjectsDataSource} from '../grouped-objects-data-source';
import {DescriptionService} from '../description.service';
import {MetkaDialogComponent} from '../metka/metka.component';
import {MatDialog} from '@angular/material/dialog';
import {dec2hex, hexToDec} from '../hex.pipe';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {toSQLString} from '../datetime';
import {IDData, IDDataService} from '../iddata';
import {MatDialogConfig} from '@angular/material/dialog/dialog-config';
import moment from 'moment';

@Component({
    selector: 'app-logger',
    templateUrl: './logger.component.html',
    styleUrls: ['./logger.component.scss']
})
export class LoggerComponent implements OnInit, OnDestroy {
    $update;
    objectKeys = Object.keys;

    ids: IDData[] = [];

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('filterDialog', {static: true}) filterDialog: TemplateRef<any>;

    displayedColumns = [
        'TIME',

        'base',

        'METKA_HEX',
        'metka_phone',
        'metka_type_id',
        'status_id',

        'address',
        // 'address_local',
        'param',

        // 'phone',
        'object',
        'tkd',
    ];

    filter: FormGroup;
    filtered: boolean;
    updating: boolean;

    generating = false;

    dataSource: GroupedObjectsDataSource<LogSimpleLine> =
        new GroupedObjectsDataSource<LogSimpleLine>('address_common_id', {
            'address': (id) => id === null ? 'не задан' : this.desc.address[id].address,
            'METKA_HEX': (id) => id === null ? 'не задано' : id,
            'base': (id) => id === null ? 'не задано' : this.desc.base[id].name,
            'object': (id) => id === null ? 'не задано' : this.desc.object[id].name,
            'tkd': (id) => id === null ? 'не задано' : this.desc.tkd[id].name,
            'metka_phone': (phone) => phone,
        });


    constructor(
        private log: LogService,
        private desc: DescriptionService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private idData: IDDataService,
    ) {
    }

    day(dayNo): Date {
        // return new Date();
        return moment().startOf('day').subtract(dayNo, 'days').toDate();
    }

    get query(): {
        start?: string | Date;
        end?: string | Date;
        metka?: number;
        metka_sn?: string;
        base?: number;
        page?: number;
        remote?: boolean;
        day?: string;
    } {
        // console.log(this.filter.value.day);
        return {
            ...this.filter.value.day === '0' ? {start: this.day(0).toISOString()} : {},
            ...this.filter.value.day !== '0' && this.filter.value.day !== null ? {
                start: this.day(this.filter.value.day).toISOString(),
                end: this.day(this.filter.value.day - 1).toISOString()
            } : {},
            ...this.filter.value.day ? {day: (Number(this.filter.value.day)).toString(10)} : {},
            ...this.filter.value.start ? {start: (new Date(this.filter.value.start)).toISOString()} : {},
            ...this.filter.value.end ? {end: (new Date(this.filter.value.end)).toISOString()} : {},
            ...this.filter.value.metka ? {metka: parseInt(this.filter.value.metka, 10)} : {},
            ...this.filter.value.metka_sn ? {metka_sn: this.filter.value.metka_sn} : {},
            ...this.filter.value.base ? {base: parseInt(this.filter.value.base, 10)} : {},
            ...this.filter.value.page ? {page: parseInt(this.filter.value.page, 10)} : {},
            ...this.filter.value.remote ? {remote: this.filter.value.remote} : {},
        };
    }

    ngOnInit() {
        if (this.sort !== undefined) {
            this.sort.sort(<MatSortable>({id: 'TIME', start: 'desc'}));
            this.dataSource.sort = this.sort;
        }

        this.filter = this.fb.group({
            start: [null],
            end: [null],
            dates: [null],
            metka: [null],
            metka_sn: [null],
            base: [null],
            page: [null],
            remote: [null],
            day: [null],
        });

        this.route.queryParams.subscribe(query => {
            this.filter.reset();
            console.log(query);
            this.filter.patchValue(query);
            if (!this.query.page) {
                this.filter.patchValue({page: 1});
            }

            this.filtered = Object.keys(this.query)
                .reduce((count, key) => count + (key === 'page' ? 0 : this.query[key] !== null ? 1 : 0), 0) > 0;

            this.updating = true;
            this.dataSource.data = [];

            this.update();
        });
    }

    ngOnDestroy() {
        if (this.$update) {
            clearTimeout(this.$update);
        }
    }

    get maximum_interval_end() {
        return new Date();
    }

    get maximum_interval_start() {
        return this.query.end || new Date();
    }

    get minimum_interval_end() {
        return this.query.start;
    }


    private update() {
        if (this.$update) {
            clearTimeout(this.$update);
        }

        this.log.log(this.createParams()).then(log => {
            this.dataSource.data = log;
            this.updating = false;
            if (this.query.page === 1 && !this.query.start && !this.query.end) {
                this.$update = setTimeout(() => this.update(), 30000);
            }
        });
    }

    private createParams() {
        const params =
            Object.keys(this.query).reduce((param, key) => {
                const val = this.query[key];
                if (key === 'start') {
                    return param.append(key, toSQLString(new Date(val as string)));
                } else if (key === 'end') {
                    const d = new Date(val as string);
                    d.setDate(d.getDate() + 1);
                    return param.append(key, toSQLString(d));
                } else if (typeof val === 'string') {
                    return param.append(key, val);
                } else if (val !== null) {
                    return param.append(key, val.toString());
                }
                return param;
            }, new HttpParams());
        return params;
    }

    isGroup(index, item: GroupBy | LogSimpleLine): boolean {
        return (<GroupBy>item).isGroupBy;
    }

    edit_metka(serial_hex?: string | null) {
        const dialogRef = this.dialog.open(MetkaDialogComponent, {
            width: '620px',
            closeOnNavigation: true,
            disableClose: false,
            hasBackdrop: true,

            data: {serial: hexToDec(serial_hex)}
        });
    }

    change(field: string, next: any) {
        const val = {...this.query};
        val[field] = next.toString();
        return val;
    }

    expand(field: string, value: string): Params {
        const val = {...this.query};
        val[field] = value;
        return val;
    }

    // shrink(field: string): Params {
    //     const l: Params = Object.assign({}, this.query);
    //     delete l[field];
    //     return l;
    // }

    selectFilters() {
        if (this.$update) {
            clearTimeout(this.$update);
        }

        const options: MatDialogConfig = {
            closeOnNavigation: true,
            hasBackdrop: true,
        };

        const promise = this.idData.all().then((ids) => this.ids = ids);
        if (this.query.metka) {
            promise.then((ids) => {
                const idData = ids.find(m => Number(m.id) === this.query.metka);
                this.filter.patchValue({metka_sn: idData.serial_hex});
                this.filter.controls.metka.reset();
            }).then(() => {
                this.dialog.open(this.filterDialog, options);
            });
        } else {
            this.dialog.open(this.filterDialog, options);
        }
    }

    async download() {
        this.generating = true;
        await this.log.download(this.createParams());
        this.generating = false;
    }
}
