<mat-card>
  <mat-card-content>
    <mat-expansion-panel [expanded]="obj.objects.length == 0">
      <mat-expansion-panel-header>
        <mat-panel-title i18n>Объекты</mat-panel-title>
        <mat-panel-description i18n>
          Информация об объектах
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p i18n>
        Объекты используются для автоматизированного подключения пользователей
        мобильного приложения к системе
      </p>
      <p i18n>
        После того, как вы создадите объект пользователь мобильного приложения
        сможет увидеть его в списке и создать запрос на подключение
      </p>
      <p i18n>Запросы будут показаны в списке рядом с каждым объектом.</p>
    </mat-expansion-panel>
    <mat-list>
      <ng-container *ngFor="let object of obj.objects">
        <app-single-object [object]="object"></app-single-object>
        <div matLine>
          <button mat-button color="warn" (click)="delete(object)" i18n>
            Удалить объект
          </button>
          <button mat-button color="primary" (click)="edit(object)" i18n>
            Редактировать объект
          </button>
          <button mat-button color="primary" (click)="details(object)" i18n>
            Подробнее
          </button>
        </div>
      </ng-container>
    </mat-list>
  </mat-card-content>
  <mat-card-actions>
    <a mat-button routerLink="/objects/new" i18n>Создать новый объект</a>
  </mat-card-actions>
</mat-card>
