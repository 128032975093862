import {FormBuilder, FormGroup, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, FormControl, Validators} from '@angular/forms';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {of} from 'rxjs';
import {map, tap, switchMap} from 'rxjs/operators';

import {UrlPathService} from './url-path.service';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {DescriptionService} from './description.service';

import {toSQLString} from './datetime';
import {Router} from '@angular/router';


export class BaseData {
    id: number;
    sn: number;
    name: string;
    address_common_id: number;
    address_common?: string;

    comment: string;
    group_ids: string | string[] | number[];

    CHANGE: boolean;

    TIME: string;
    in_sync: boolean;

    tkd: number;

    RF1ENABLE: boolean;
    RF1VALUE: number;
    RELAY1TYPE: number;
    RELAY1TIMEOFF: number;
    RELAY1TIMEON: number;
    RELAY1TIMEREPEAD: number;

    RF2ENABLE: boolean;
    RF2VALUE: number;
    RELAY2TYPE: number;
    RELAY2TIMEOFF: number;
    RELAY2TIMEON: number;
    RELAY2TIMEREPEAD: number;

    REMOTE: number;
    RF1NAME: string;
    RF2NAME: string;

    OPEN1: number;
    OPEN2: number;
    DOR_CHANGE: number;

    WIEGAND1: number;
    WIEGAND2: number;

    TIMESYNC: boolean;

    SERVERSYNC: boolean;
    LOGSYNC: boolean;
    TAGSYNC: boolean;

    IP: string;
    MASK: string;
    GETWAY: string;
    DNS: string;

    DHCP: boolean;
    CURR_IP: string;
    CURR_MASK: string;
    CURR_GETWAY: string;
    CURR_DNS: string;
}

// export interface RemoteOpen {
//     sn: string; // Идентификационный номер контроллера, на котором разрешено удалённое открытие
//     RF1NAME?: string; // Название объекта, связанного с реле 1
//     RF2NAME?: string; // Название объекта, связанного с реле 2
// }[];

export interface RemoteOpenRes {
    result: 'success' | 'denied' | 'error'; // success - разрешение на открытие отправлено, denied - у пользователя нет прав на удалённое открытие, error - ошибка
    error?: string; // Строка с описанием ошибки. Пока видится только одна ошибка - timeout
}


export interface LogBaseLine {
    id: number;
    timestamp: Date | string;
    serial: string;
    channel: number;
    result: number;
}


@Injectable({
    providedIn: 'root'
})
export class BaseService {
    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        private path: UrlPathService,
        public dialog: MatDialog,
        public desc: DescriptionService,
        private router: Router,
    ) {
    }

    transBool(r: BaseData, field: string): boolean {
        return r[field] === undefined || r[field] === null || r[field] === false || r[field] === 0 || r[field] === '0' ? false : true;
    }

    cook(r: BaseData): BaseData {
        if (r === undefined) {
            return new BaseData();
        }

        r.RF1ENABLE = this.transBool(r, 'RF1ENABLE');
        r.RF2ENABLE = this.transBool(r, 'RF2ENABLE');
        r.DHCP = this.transBool(r, 'DHCP');
        r.TIMESYNC = this.transBool(r, 'TIMESYNC');

        r.CHANGE = this.transBool(r, 'CHANGE');

        r.SERVERSYNC = this.transBool(r, 'SERVERSYNC');
        r.LOGSYNC = this.transBool(r, 'LOGSYNC');
        r.TAGSYNC = this.transBool(r, 'TAGSYNC');

        if (r.tkd === undefined) {
            r.tkd = null;
        }

        // @ts-ignore
        if (r.REMOTE === undefined || r.REMOTE == null) {
            r.REMOTE = -1;
        }

        if (r.OPEN1) {
            r.OPEN1 = Number(r.OPEN1);
        }

        if (r.OPEN2) {
            r.OPEN2 = Number(r.OPEN2);
        }

        if (r.DOR_CHANGE) {
            r.DOR_CHANGE = Number(r.DOR_CHANGE);
        }


        const server_time_diff = (new Date(r.TIME)).getTime() - (new Date()).getTime();
        r.in_sync = Math.abs(server_time_diff) < 10 * 60 * 1000;

        if (this.desc.address[r.address_common_id] !== undefined) {
            r.address_common = this.desc.address[r.address_common_id].address;
        }

        if (r.group_ids !== undefined && r.group_ids !== null) {
            if (typeof r.group_ids === 'string') {
                r.group_ids = (<string>r.group_ids).split(',');
            }
        } else {
            r.group_ids = [];
        }

        r.REMOTE = r.REMOTE === undefined || r.REMOTE === null ? -1 : +r.REMOTE;

        return r;
    }

    get(id: number) {
        return this.http.post(this.path.base(), {'id': id}).pipe(
            map((res) => this.cook(res[0])));
    }

    log(id: number) {
        this.router.navigate(['log'], {queryParams: {base: id}});
    }

    form(r?: BaseData): FormGroup {
        const form = this.fb.group({
            sn: [{value: null, disabled: true}],
            name: [null],
            comment: [null],
            address_common_id: [null],
            group_ids: [null],

            tkd: [null],

            RF1ENABLE: [null],
            RF1VALUE: [null],

            RELAY1TYPE: [null],
            RELAY1TIMEOFF: [null],
            RELAY1TIMEON: [null],
            RELAY1TIMEREPEAD: [null],


            RF2ENABLE: [null],
            RF2VALUE: [null],

            RELAY2TYPE: [null],
            RELAY2TIMEOFF: [null],
            RELAY2TIMEON: [null],
            RELAY2TIMEREPEAD: [null],

            RELAY1NAME: ['Реле 1'],
            RELAY2NAME: ['Реле 2'],
            REMOTE: [null],

            WIEGAND1: [null],
            WIEGAND2: [null],

            IP: [null],
            MASK: [null],
            GETWAY: [null],
            DNS: [null],
            DHCP: [null],
            TIMESYNC: [null],

            SERVERSYNC: [null],
            LOGSYNC: [null],
            TAGSYNC: [null],
        });
        if (r !== undefined) {
            form.patchValue(r);
        }
        // todo: Разобраться с остальными параметрами, такими как ТКД
        return form;
    }

    set(old_value, new_value) {
        let req;

        if (old_value !== null) {
            // отправить данные на сервак
            req = {id: old_value.id};
            for (const i in new_value) {
                if (new_value[i] !== null && new_value[i] !== old_value[i]) {
                    req[i] = new_value[i] instanceof Date ? toSQLString(new_value[i])
                        : typeof new_value[i] === 'boolean' ? new_value[i] ? 1 : 0
                            : new_value[i];
                }
            }
        } else {
            req = new_value;
        }
        return this.http.post(this.path.baseUpdate(), req).pipe(tap(() => this.desc.update(true)));
    }

    delete(metka_id: number) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: $localize`Удалить контроллер и все связанные метки?`
        });

        return dialogRef.afterClosed().pipe(switchMap(result => {
            if (result) {
                const req = {'id': metka_id};
                return this.http.post(this.path.baseDelete(), req).pipe(tap(() => this.desc.update(true)));
            } else {
                return of({id: 0});
            }
        }));
    }

    open(base_id: number, relay: number) {
        const params = (new HttpParams())
            .append('id', base_id.toString())
            .append('relay', relay.toString());

        return this.http.post(this.path.baseOpen(), params).toPromise();
    }
}
