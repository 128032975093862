<h1 mat-dialog-title>
    {{state}}
</h1>
<mat-dialog-content>
    <form [formGroup]="metka">
        <mat-form-field *ngIf="iddata.display.metka_type_id">
            <mat-label i18n>Тип метки</mat-label>
            <mat-select formControlName="metka_type_id" [compareWith]="desc.compareWith"
                        [disabled]="metka.controls.metka_type_id.disabled">
                <mat-option *ngFor="let key of objectKeys(desc.metka_type).slice(0,2)"
                            [value]="desc.metka_type[key].id">
                    <app-descr val="metka_type" [id]="key"></app-descr>
                </mat-option>
            </mat-select>
            <mat-hint i18n>
                Выбирите тип метки
            </mat-hint>
            <mat-error *ngIf="(metka.touched || metka.dirty) && metka.hasError('required', 'metka_type_id')" i18n>
                Обязательное поле
            </mat-error>
            <mat-error
                    *ngIf="(metka.touched || metka.dirty) && metka.get('serial_hex').valid && metka.hasError('MismatchTypeToSN','metka_type_id')"
                    i18n>
                Тип метки не соответствует введённому серийному номеру
            </mat-error>
        </mat-form-field>

        <mat-slide-toggle
                *ngIf="iddata.display.serial_auto && metka.get('metka_type_id').enabled && metka.get('metka_type_id').value=='1'"
                formControlName="serial_auto" i18n>
            Загрузить свободный электронный ключ
        </mat-slide-toggle>

        <mat-form-field
                *ngIf="iddata.display.serial_hex && (!metka.get('serial_auto').value || metka.get('metka_type_id').value=='2')">
            <input matInput placeholder="С/Н" formControlName="serial_hex" (keydown)="updateType()">
            <mat-hint i18n>
                Идентификационный номер метки
            </mat-hint>
            <mat-error *ngIf="(metka.touched || metka.dirty) && metka.get('serial_hex').hasError('pattern')" i18n>
                Номер метки должен состоять из 16 цифр и букв латинского алфавита A-F
            </mat-error>
            <mat-error *ngIf="(metka.touched || metka.dirty) && metka.get('serial_hex').hasError('required')" i18n>
                Обязательное поле
            </mat-error>
            <mat-error
                    *ngIf="(metka.touched || metka.dirty) && metka.get('serial_hex').valid && metka.hasError('UnknownType','serial_hex')"
                    i18n>
                Неизвестный тип метки
            </mat-error>
        </mat-form-field>

        <mat-card *ngIf="iddata.display.objects && value && value.objects.length">
            <mat-card-header>
                <mat-card-title i18n>
                    Метка используется на следующих объектах
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <a mat-button color="primary" *ngFor="let o of value.objects" [routerLink]="['/objects', o]">
                    <app-descr val="object" [id]="o"></app-descr>
                </a>
            </mat-card-content>
        </mat-card>

        <mat-form-field *ngIf="iddata.display.comment">
            <textarea matInput i18n-placeholder placeholder="Комментарий" formControlName="comment"></textarea>
        </mat-form-field>

        <mat-form-field *ngIf="iddata.display.address_common_id">
            <mat-label i18n>Адрес регистрации метки</mat-label>
            <mat-select formControlName="address_common_id" [compareWith]="desc.compareWith">
                <mat-option *ngFor="let key of objectKeys(desc.address)" [value]="desc.address[key].id">
                    <app-descr val="address" [id]="key"></app-descr>
                </mat-option>
            </mat-select>
            <mat-hint i18n>
                Выберите адрес регистрации метки
            </mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="iddata.display.address_local">
            <input matInput i18n-placeholder placeholder="Номер квартиры" formControlName="address_local">
        </mat-form-field>

        <mat-form-field *ngIf="iddata.display.livetime && metka.get('metka_type_id').value=='1'">
            <mat-label i18n>Период обновления ключа</mat-label>
            <mat-select formControlName="livetime" [compareWith]="desc.compareWith">
                <mat-option [value]="0" i18n>авто</mat-option>
                <mat-option [value]="60" i18n>минута</mat-option>
                <mat-option [value]="600" i18n>10 минут</mat-option>
                <mat-option [value]="1800" i18n>30 минут</mat-option>
                <mat-option [value]="3600" i18n>час</mat-option>
                <mat-option [value]="7200" i18n>2 часа</mat-option>
                <mat-option [value]="14400" i18n>4 часа</mat-option>
                <mat-option [value]="43200" i18n>12 часов</mat-option>
                <mat-option [value]="86400" i18n>сутки</mat-option>
                <mat-option [value]="259200" i18n>3 суток</mat-option>
                <mat-option [value]="604800" i18n>неделя</mat-option>
                <mat-option [value]="2592000" i18n>30 дней</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="iddata.display.time_exp && metka.get('metka_type_id').value!='3'">
            <input matInput [min]="now" [max]="last_date" [matDatepicker]="validDatepicker" i18n-placeholder
                   placeholder="Срок действия" formControlName="time_exp">
            <mat-datepicker-toggle matSuffix [for]="validDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #validDatepicker></mat-datepicker>
            <mat-error *ngIf="(metka.touched || metka.dirty) && metka.get('time_exp').hasError('required')" i18n>
                Для электронной метки поле срок действия обязательно!
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="iddata.display.phone">
            <ngx-mat-intl-tel-input #phone_input
                                    i18n-placeholder placeholder="Номер Телефона"
                                    formControlName="phone"
                                    [preferredCountries]="['ru', 'ua']"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="false"
                                    [enableSearch]="false"></ngx-mat-intl-tel-input>
            <mat-error *ngIf="(metka.touched || metka.dirty) && metka.get('phone').hasError('required')" i18n>
                Для электронного ключа обязателен ввод номера телефона
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="iddata.display.user_id">
            <mat-label i18n>Менеджер</mat-label>
            <mat-select formControlName="user_id" [compareWith]="desc.compareWith">
                <mat-option *ngFor="let key of objectKeys(desc.user)" [value]="desc.user[key].id">
                    <app-descr val="user" [id]="key"></app-descr>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="iddata.display.activation_time">
            <input matInput [matDatepicker]="activeDatepicker" i18n-placeholder placeholder="активна с"
                   formControlName="activation_time">
            <mat-datepicker-toggle matSuffix [for]="activeDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #activeDatepicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="iddata.display.status_id">
            <mat-label i18n>Статус метки</mat-label>
            <mat-select formControlName="status_id" [compareWith]="desc.compareWith">
                <mat-option *ngFor="let key of objectKeys(desc.status)" [value]="desc.status[key].id">
                    <app-descr val="status" [id]="key"></app-descr>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="iddata.display.group_ids">
            <mat-label i18n>Группы</mat-label>
            <mat-select formControlName="group_ids" multiple (selectionChange)="updateFromGroups()">
                <mat-option *ngFor="let key of objectKeys(desc.group)" [value]="desc.group[key].id">
                    <app-descr val="group" [id]="key"></app-descr>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="iddata.display.group_bases">
            <mat-label i18n>Контроллеры</mat-label>
            <mat-select formControlName="group_bases" multiple>
                <mat-option *ngFor="let key of objectKeys(desc.base)" [value]="desc.base[key].id"
                            [disabled]="desc.base[key].TAGSYNC=='0'">
                    <app-descr val="base" [id]="key"></app-descr>
                </mat-option>
            </mat-select>

            <mat-hint i18n>
                Контроллеры, к которым должна быть добавлена метка
            </mat-hint>
            <mat-error *ngIf="(metka.touched || metka.dirty) && metka.get('group_bases').hasError('required')" i18n>
                Метка должна быть добавлена хотя бы к одному контроллеру
            </mat-error>
        </mat-form-field>
        <mat-divider></mat-divider>
        <h3 mat-dialog-title i18b>Индивидуальные настройки метки для удалённого открытия через интернет</h3>
        <mat-radio-group class="on_new_line" i18n-aria-label aria-label="Удалённое открытие через интернет"
                         formControlName="REMOTE">
            <mat-radio-button value="-1" i18n>Не задано</mat-radio-button>
            <mat-radio-button value="0" i18n>Запрещено всегда</mat-radio-button>
            <mat-radio-button value="1" i18n>Выбрать контроллеры на которых разрешено открытие</mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="metka.value.REMOTE==1">
            <mat-label i18n>Индивидульное управление удалённым открытием</mat-label>
<!--            <p>{{metka.value.group_bases_remote|json}}</p>-->
            <mat-select formControlName="group_bases_remote" multiple [disabled]="metka.value.REMOTE==-1">
                <mat-option *ngFor="let key of objectKeys(desc.base)" [value]="desc.base[key].id"
                            [disabled]="desc.base[key].TAGSYNC=='0'">
                    <app-descr val="base" [id]="key"></app-descr>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button *ngIf="metka.touched" [disabled]="metka.invalid" (click)="save()" color="primary" i18n>
        Сохранить
    </button>
    <button mat-button *ngIf="metka.touched" (click)="cancel()" color="primary" i18n>Отменить</button>
    <button mat-button *ngIf="serial!==''" (click)="delete()" color="primary" i18n>Удалить метку</button>
    <a mat-button *ngIf="serial!==''" (click)="downloadLog()" color="primary" i18n>Загрузить журнал событий</a>
</mat-dialog-actions>
