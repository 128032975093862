import {HttpClient} from '@angular/common/http';
import {Injectable, Output, EventEmitter} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';

import {map, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

import {UrlPathService} from './url-path.service';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';

export type DescriptionFields = 'group' | 'address' | 'status' | 'metka_type' | 'user' | 'base' | 'construct' | 'object' | 'tkd';

export interface EditorDescription {
    title: string;
    add: string;
    delete: string;
    table: string;
    table_src: DescriptionFields;
    displayedColumns: string[];
    add_metka?: string;
    add_metka_field?: (id: number) => any;
    fields: {
        name: string;
        key: string;
        input_comment: string;
        required?: string | boolean;
        routerLink?: string;
        routerQuery?: (id: number) => any;
    }[];
}

export class GroupDescription {
    id: number;
    description: string;
    comment: string;
    group_bases: string;
}

export class AddressDescription {
    id: number;
    address: string;
}

export class StatusDescription {
    id: number;
    status: string;
}

export class MetkaTypeDescription {
    id: number;
    type: string;
}

export class UserDescription {
    id: number;
    name: string;
}

export class BaseDescription {
    id: number;
    no: number;
    comment: string;
    name: string;
    user_id: number;
    sn: number;
    sn_hex: string;
    group_ids: string;
    IP: string;
    TAGSYNC: string;
    tkd: number;
}

export class ConstructDescription {
    id: number;
    name: string;
    param: string;
    object: string;
    tkd_name: string;
    company_id: null | number;
}

export class ObjectDescription {
    id: number;
    name: string;
}

export class TKDDescription {
    id: number;
    name: string;
}


@Injectable({providedIn: 'root'})
export class DescriptionService {
    group: {
        [key: number]: GroupDescription
    } = {};
    address: {
        [key: number]: AddressDescription
    } = {};
    status: {
        [key: number]: StatusDescription
    } = {};
    metka_type: {
        [key: number]: MetkaTypeDescription
    } = {};
    user: {
        [key: number]: UserDescription
    } = {};
    base: {
        [key: number]: BaseDescription
    } = {};
    construct: {
        [key: number]: ConstructDescription
    } = {};
    object: {
        [key: number]: ObjectDescription
    } = {};
    tkd: {
        [key: number]: TKDDescription
    } = {};


    display: { [key: string]: EditorDescription } = {
        'group': {
            title: $localize`Группы контроллеров`,
            add: $localize`Новая группа`,
            table: $localize`Зарегестрированные группы`,
            delete: $localize`Удалить группу контроллеров?`,
            displayedColumns: ['action', 'description', 'comment'],
            table_src: 'group',
            add_metka: $localize`Добавить метку в группу`,
            add_metka_field: (id) => ({'group_ids': [id]}),
            fields: [
                {key: 'comment', name: $localize`Комментарий`, input_comment: $localize`комментарий`},
                {
                    key: 'description', name: $localize`Группа`, input_comment: $localize`имя для группы контроллеров`,
                    required: $localize`имя группы является обязательным полем`,
                    routerLink: '/group-details',
                    routerQuery: (id: number) => ({'group_description_id': id})
                }
            ]
        },
        'address': {
            title: $localize`Адреса`,
            add: $localize`Новый адрес`,
            table: $localize`Зарегестрированные адреса`,
            delete: $localize`Удалить адрес?`,
            displayedColumns: ['action', 'address'],
            table_src: 'address',
            add_metka: $localize`Добавить метку по адресу`,
            add_metka_field: (id) => ({'address_common_id': id}),
            fields: [
                {
                    key: 'address', name: $localize`Адрес`, input_comment: $localize`Адрес`,
                    required: $localize`адрес является обязательным полем`,
                    routerLink: '/ids',
                    routerQuery: (id: number) => ({'address_common_id': id})
                }
            ]
        },
        'construct': {
            title: $localize`Конструктор объектов`,
            add: $localize`Новый тип обектов`,
            table: $localize`Существующие объекты`,
            delete: $localize`Удалить объект?`,
            displayedColumns: ['action', 'name', 'param', 'object', 'tkd_name'],
            table_src: 'construct',
            fields: [
                {key: 'name', name: $localize`Объект`, input_comment: $localize`Наименование объекта`, required: $localize`Наименование объекта является обязательным полем`},
                {key: 'param', name: $localize`Параметр`, input_comment: $localize`Наименование параметра`},
                {key: 'object', name: $localize`Контролируемые номера`, input_comment: $localize`Наименование кнотролирруемого объекта`},
                {key: 'tkd_name', name: $localize`Наименование точки контроля доступа`, input_comment: $localize`Наименовние точки контроля доступа`},
            ]
        }
    };

    force = true;
    @Output() afterUpdate: EventEmitter<DescriptionService> = new EventEmitter<DescriptionService>();

    constructor(
        private http: HttpClient,
        private path: UrlPathService,
        public dialog: MatDialog
    ) {
    }

    compareWith(a, b) {
        return Number(a) === Number(b);
    }

    as_array(field: 'group'): GroupDescription[];
    as_array(field: 'address'): AddressDescription[];
    as_array(field: 'status'): StatusDescription[];
    as_array(field: 'metka_type'): MetkaTypeDescription[];
    as_array(field: 'user'): UserDescription[];
    as_array(field: 'base'): BaseDescription[];
    as_array(field: 'construct'): ConstructDescription[];
    as_array(field: 'object'): ObjectDescription[];
    as_array(field: 'tkd'): TKDDescription[];
    as_array(field: DescriptionFields);
    as_array(field: DescriptionFields) {
        return Object.keys(this[field]).map(key => this[field][key]);
    }

    clear() {
        this.group = {};
        this.address = {};
        this.status = {};
        this.metka_type = {};
        this.user = {};
        this.base = {};
    }

    update(force?: boolean): Observable<DescriptionService> {
        if (force === undefined) {
            force = this.force;
        }

        this.force = false;

        if (force) {
            return this.http.get(this.path.description()).pipe(map((desc: any) => {
                Object.assign(this, desc);
                this.afterUpdate.emit(this);
                return desc;
            }));
        } else {
            return new Observable<any>((observer) => {
                observer.next(this);
            });
        }
    }

    set(val: { [key: string]: string }, desc: EditorDescription): Observable<any> {
        const update = {
            table: desc.table_src,
            value: val
        };

        return this.http.post(this.path.updateDesc(), update).pipe(map(
            () => this.update(true).pipe()
        ));
    }

    delete(val: { [key: string]: string }, desc: EditorDescription): Observable<any> {

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: desc.delete
        });

        return dialogRef.afterClosed().pipe(switchMap(result => {
            if (result) {
                console.log('Yes clicked');

                const del = {
                    table: desc.table_src,
                    id: val.id
                };
                return this.http.post(this.path.delDesc(), del).pipe();
            } else {
                return of(null);
            }
        }));
    }

    get(where: string, id: number) {
        switch (where) {
            case 'group':
                return this.group[id] !== undefined ? this.group[id].description : $localize`не задан`;
            case 'address':
                return this.address[id] !== undefined ? this.address[id].address : $localize`не задан`;
            case 'status':
                return this.status[id] !== undefined ? this.status[id].status : $localize`не задан`;
            case 'metka_type':
                return this.metka_type[id] !== undefined ? this.metka_type[id].type : $localize`не известно`;
            case 'user':
                return this.user[id] !== undefined ? this.user[id].name : $localize`не задан`;
            case 'base':
                return this.base[id] !== undefined ?
                    this.base[id].name.trim() !== '' ? this.base[id].name : this.base[id].sn_hex
                    : 'не задан';
            case 'construct':
                return this.construct[id] !== undefined ? this.construct[id].name : $localize`ошибка в типе объекта`;
            case 'tkd_name':
                return this.construct[id] !== undefined ? this.construct[id].tkd_name : $localize`ошибка в типе объекта`;
            case 'object':
                return id ? (this.object[id] !== undefined ? this.object[id].name : $localize`ошибка в объекте`) : $localize`объект не задан`;
            case 'tkd':
                return id ? (this.tkd[id] !== undefined ? this.tkd[id].name : $localize`ошибка в точке контроля доступа`) : $localize`точка контроля доступа не задана`;
        }
    }
}
