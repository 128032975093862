<form [formGroup]="form">
    <div>
      <mat-form-field>
        <mat-label i18n>Тип объекта</mat-label>
        <mat-select formControlName="type_id" [compareWith]="desc.compareWith">
          <mat-option *ngFor="let key of objectKeys(desc.construct)" [value]="key">
            <app-descr val="construct" [id]="key"></app-descr>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label i18n>Ответственное лицо</mat-label>
        <mat-select formControlName="user" [compareWith]="desc.compareWith">
          <mat-option *ngFor="let key of objectKeys(desc.user)" [value]="key">
            <app-descr val="user" [id]="key"></app-descr>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label i18n>Название объекта</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label i18n>Дополнительная информация</mat-label>
        <textarea matInput formControlName="extra" type="text" rows="10"></textarea>
      </mat-form-field>
    </div>

    <app-address-selector formControlName="address"></app-address-selector>

    <div>
      <button mat-button matStepperNext (click)="done()" color="primary" [disabled]="form.invalid" i18n>
        Дальше
      </button>
    </div>
    <div i18n>
      По каждому адресу можно создать произвольное число объектов. Пользователю
      в WEB приложении по адресу будет показано название объекта, ответственное
      лицо, его телефон для связи и дополнительная информация
    </div>
</form>
