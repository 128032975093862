<mat-card>
    <h2 id="-">ПРАВИЛА ПОКУПКИ И УСЛОВИЯ ОКАЗАНИЯ УСЛУГИ</h2>
    <h2 id="-">(ДАЛЕЕ– «ПРАВИЛА»)</h2>
    <p>Настоящие Правила определяют порядок и условия оказания Исполнителем (ООО «ЕЛС»)
        Покупателю (физическому лицу, использующему услуги Исполнителя в соответствии с
        положениями настоящих Правил) услуги “Комфортный доступ”, подключение мобильного
        телефона Покупателя к системе ELS Access (<a href="https://elsaccess.com/">https://elsaccess.com/</a>) по
        протоколу
        BLE и/или открытие через интернет.</p>
    <p>Исполнитель оставляет за собой право изменять Правила без предварительного
        уведомления Покупателя. Новые Правила вступают в силу с момента их размещения на
        сайте (<a href="https://online.elsaccess.com/">https://online.elsaccess.com/</a>).</p>
    <p>Оформление и приобретение услуги на сайте (<a
            href="https://online.elsaccess.com/">https://online.elsaccess.com/</a>)
        можно осуществить следующим способом:</p>
    <p>Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail, номер
        кредитной карты) является конфиденциальной и не подлежит разглашению. Данные
        вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на
        нашем Web-сервере.</p>
    <p>Безопасность обработки Интернет-платежей по пластиковым картам гарантирует
        банк-эквайер. Все операции с картами происходят в соответствии с требованиями
        VISA International, MasterCard и других платежных систем. При передаче
        информации используются специальные технологии безопасности карточных
        онлайн-платежей, обработка данных ведется на безопасном высокотехнологичном
        процессинговом сервере.</p>
    <p>В случае возникновения вопросов по поводу конфиденциальности операций с
        платёжными картами и предоставляемой вами информации вы можете связаться со
        службой технической поддержки банка.</p>
    <p>На странице авторизации потребуется ввести номер карты, имя владельца карты,
        срок действия карты, верификационный номер карты (CVV2 для VISA или CVC2 для
        MasterCard). Все необходимые данные пропечатаны на самой карте. Верификационный
        номер карты — это три цифры, находящиеся на обратной стороне карты.</p>
    <p>Для оплаты вы будете перенаправлены на страницу банка.</p>
    <p>Произвести оплату необходимо в течение 15 минут после перехода на страницу
        авторизации карточки.</p>
    <p>Транзакция может занять около 40 секунд. Дождитесь завершения операции. Не
        нажимайте повторно кнопку «Заплатить».</p>
    <p>Платеж происходит в режиме реального времени и зачисляется в течение 15 минут.</p>
    <h3 id="-">ВНИМАНИЕ:</h3>
    <p>Исполнитель не несет ответственности за указание неправильного адреса
        электронной почты, реквизитов банковской карты и номера телефона Покупателя.
        Указанные данные должны быть проверены Покупателем до момента оплаты услуги.</p>
    <p>После оплаты, Покупатель обязан проверить получение электронного ключа в
        приложении в разделе “Ключи”. В случае неполучения бланка электронного билета по
        адресу электронной почты или выявления несоответствия между оплаченной услугой,
        Покупатель заблаговременно обращается в службу поддержки Исполнителя по телефону
        +7(812) 904-14-40 для выяснения и устранения такого несоответствия.</p>
    <p>Настоящим Покупатель подтверждает свое согласие с указанными выше условиями и
        дает согласие на обработку Исполнителем персональных данных, при оформлении
        услуги.</p>
    <h2 id="-">Отказ от услуги</h2>
    <p>Право потребителя на расторжение договора об оказании услуги регламентируется
        статьей 32 федерального закона «О защите прав потребителей»</p>
    <ul>
        <li><p>Потребитель вправе расторгнуть договор об оказании услуги в любое время,
            уплатив исполнителю часть цены пропорционально части оказанной услуги до
            получения извещения о расторжении указанного договора и возместив
            исполнителю расходы, произведенные им до этого момента в целях исполнения
            договора, если они не входят в указанную часть цены услуги;</p>
        </li>
        <li><p>Потребитель при обнаружении недостатков оказанной услуги вправе по своему
            выбору потребовать:</p>
            <ul>
                <li><p>безвозмездного устранения недостатков;</p>
                </li>
                <li><p>соответствующего уменьшения цены;</p>
                </li>
                <li><p>возмещения понесенных им расходов по устранению недостатков своими
                    силами или третьими лицами;</p>
                </li>
            </ul>
        </li>
        <li><p>Потребитель вправе предъявлять требования, связанные с недостатками
            оказанной услуги, если они обнаружены в течение гарантийного срока, а при
            его отсутствии в разумный срок, в пределах двух лет со дня принятия
            оказанной услуги;</p>
        </li>
        <li><p>Исполнитель отвечает за недостатки услуги, на которую не установлен
            гарантийный срок, если потребитель докажет, что они возникли до ее принятия
            им или по причинам, возникшим до этого момента;</p>
        </li>
    </ul>
    <mat-divider></mat-divider>
    <mat-card-actions>
        <a mat-button color="accent" routerLink="/">Назад</a>
    </mat-card-actions>
</mat-card>
