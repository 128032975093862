import {Component, OnDestroy} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UrlPathService} from '../url-path.service';
import {Subscription} from 'rxjs';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. */
export class RestoreErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-password-restore',
  templateUrl: './password-restore.component.html',
  styleUrls: ['./password-restore.component.scss']
})
export class PasswordRestoreComponent implements OnDestroy {
  private subscription: Subscription;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new RestoreErrorStateMatcher();

  constructor(
      private recaptchaV3Service: ReCaptchaV3Service,
      private http: HttpClient,
      private path: UrlPathService,
      private dialog: MatDialog,
      private router: Router,
  ) { }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public apply(): void {
    this.subscription = this.recaptchaV3Service.execute('restore')
        .subscribe((token) => {
          this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: $localize`На указанный email будет выслано письмо для сброса пароля`
          }).afterClosed().subscribe({
                next: () => {
                  console.log(token);
                  const params = new HttpParams()
                      .set('email', this.emailFormControl.value)
                      .set('token', token);
                  this.http.post(this.path.restore(), params).subscribe(res => {
                    console.log(res);

                  });
                },
                complete: () => {
                  this.router.navigate(['/']);
                }
              }
          );
        });
  }
}
