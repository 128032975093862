import { CountryCode, CarrierCode } from 'libphonenumber-js';
import { Injectable } from '@angular/core';

export interface Country {
  name: string;
  iso2: CountryCode;
  dialCode: CarrierCode;
  priority: number;
  areaCodes?: string[];
  flagClass: string;
  placeHolder: string;
}

@Injectable()
export class CountryCodeList {
  public static allCountries: Array<Array<string | CountryCode | string[] | number>> = [
    ['Afghanistan (‫افغانستان‬‎)', <CountryCode>('AF'), '93'],
    ['Albania (Shqipëri)', <CountryCode>('AL'), '355'],
    ['Algeria (‫الجزائر‬‎)', <CountryCode>('DZ'), '213'],
    ['American Samoa', <CountryCode>('AS'), '1684'],
    ['Andorra', <CountryCode>('AD'), '376'],
    ['Angola', <CountryCode>('AO'), '244'],
    ['Anguilla', <CountryCode>('AI'), '1264'],
    ['Antigua and Barbuda', <CountryCode>('AG'), '1268'],
    ['Argentina', <CountryCode>('AR'), '54'],
    ['Armenia (Հայաստան)', <CountryCode>('AM'), '374'],
    ['Aruba', <CountryCode>('AW'), '297'],
    ['Australia', <CountryCode>('AU'), '61', 0],
    ['Austria (Österreich)', <CountryCode>('AT'), '43'],
    ['Azerbaijan (Azərbaycan)', <CountryCode>('AZ'), '994'],
    ['Bahamas', <CountryCode>('BS'), '1242'],
    ['Bahrain (‫البحرين‬‎)', <CountryCode>('BH'), '973'],
    ['Bangladesh (বাংলাদেশ)', <CountryCode>('BD'), '880'],
    ['Barbados', <CountryCode>('BB'), '1246'],
    ['Belarus (Беларусь)', <CountryCode>('BY'), '375'],
    ['Belgium (België)', <CountryCode>('BE'), '32'],
    ['Belize', <CountryCode>('BZ'), '501'],
    ['Benin (Bénin)', <CountryCode>('BJ'), '229'],
    ['Bermuda', <CountryCode>('BM'), '1441'],
    ['Bhutan (འབྲུག)', <CountryCode>('BT'), '975'],
    ['Bolivia', <CountryCode>('BO'), '591'],
    ['Bosnia and Herzegovina (Босна и Херцеговина)', <CountryCode>('BA'), '387'],
    ['Botswana', <CountryCode>('BW'), '267'],
    ['Brazil (Brasil)', <CountryCode>('BR'), '55'],
    ['British Indian Ocean Territory', <CountryCode>('IO'), '246'],
    ['British Virgin Islands', <CountryCode>('VG'), '1284'],
    ['Brunei', <CountryCode>('BN'), '673'],
    ['Bulgaria (България)', <CountryCode>('BG'), '359'],
    ['Burkina Faso', <CountryCode>('BF'), '226'],
    ['Burundi (Uburundi)', <CountryCode>('BI'), '257'],
    ['Cambodia (កម្ពុជា)', <CountryCode>('KH'), '855'],
    ['Cameroon (Cameroun)', <CountryCode>('CM'), '237'],
    ['Canada', <CountryCode>('CA'), '1', 1, ['204', '226', '236', '249', '250', '289', '306', '343', '365', '387', '403', '416', '418', '431', '437', '438', '450', '506', '514', '519', '548', '579', '581', '587', '604', '613', '639', '647', '672', '705', '709', '742', '778', '780', '782', '807', '819', '825', '867', '873', '902', '905']],
    ['Cape Verde (Kabu Verdi)', <CountryCode>('CV'), '238'],
    ['Caribbean Netherlands', <CountryCode>('BQ'), '599', 1],
    ['Cayman Islands', <CountryCode>('KY'), '1345'],
    ['Central African Republic (République centrafricaine)', <CountryCode>('CF'), '236'],
    ['Chad (Tchad)', <CountryCode>('TD'), '235'],
    ['Chile', <CountryCode>('CL'), '56'],
    ['China (中国)', <CountryCode>('CN'), '86'],
    ['Christmas Island', <CountryCode>('CX'), '61', 2],
    ['Cocos (Keeling) Islands', <CountryCode>('CC'), '61', 1],
    ['Colombia', <CountryCode>('CO'), '57'],
    ['Comoros (‫جزر القمر‬‎)', <CountryCode>('KM'), '269'],
    ['Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', <CountryCode>('CD'), '243'],
    ['Congo (Republic) (Congo-Brazzaville)', <CountryCode>('CG'), '242'],
    ['Cook Islands', <CountryCode>('CK'), '682'],
    ['Costa Rica', <CountryCode>('CR'), '506'],
    ['Côte d’Ivoire', <CountryCode>('CI'), '225'],
    ['Croatia (Hrvatska)', <CountryCode>('HR'), '385'],
    ['Cuba', <CountryCode>('CU'), '53'],
    ['Curaçao', <CountryCode>('CW'), '599', 0],
    ['Cyprus (Κύπρος)', <CountryCode>('CY'), '357'],
    ['Czech Republic (Česká republika)', <CountryCode>('CZ'), '420'],
    ['Denmark (Danmark)', <CountryCode>('DK'), '45'],
    ['Djibouti', <CountryCode>('DJ'), '253'],
    ['Dominica', <CountryCode>('DM'), '1767'],
    ['Dominican Republic (República Dominicana)', <CountryCode>('DO'), '1', 2, ['809', '829', '849']],
    ['Ecuador', <CountryCode>('EC'), '593'],
    ['Egypt (‫مصر‬‎)', <CountryCode>('EG'), '20'],
    ['El Salvador', <CountryCode>('SV'), '503'],
    ['Equatorial Guinea (Guinea Ecuatorial)', <CountryCode>('GQ'), '240'],
    ['Eritrea', <CountryCode>('ER'), '291'],
    ['Estonia (Eesti)', <CountryCode>('EE'), '372'],
    ['Ethiopia', <CountryCode>('ET'), '251'],
    ['Falkland Islands (Islas Malvinas)', <CountryCode>('FK'), '500'],
    ['Faroe Islands (Føroyar)', <CountryCode>('FO'), '298'],
    ['Fiji', <CountryCode>('FJ'), '679'],
    ['Finland (Suomi)', <CountryCode>('FI'), '358', 0],
    ['France', <CountryCode>('FR'), '33'],
    ['French Guiana (Guyane française)', <CountryCode>('GF'), '594'],
    ['French Polynesia (Polynésie française)', <CountryCode>('PF'), '689'],
    ['Gabon', <CountryCode>('GA'), '241'],
    ['Gambia', <CountryCode>('GM'), '220'],
    ['Georgia (საქართველო)', <CountryCode>('GE'), '995'],
    ['Germany (Deutschland)', <CountryCode>('DE'), '49'],
    ['Ghana (Gaana)', <CountryCode>('GH'), '233'],
    ['Gibraltar', <CountryCode>('GI'), '350'],
    ['Greece (Ελλάδα)', <CountryCode>('GR'), '30'],
    ['Greenland (Kalaallit Nunaat)', <CountryCode>('GL'), '299'],
    ['Grenada', <CountryCode>('GD'), '1473'],
    ['Guadeloupe', <CountryCode>('GP'), '590', 0],
    ['Guam', <CountryCode>('GU'), '1671'],
    ['Guatemala', <CountryCode>('GT'), '502'],
    ['Guernsey', <CountryCode>('GG'), '44', 1],
    ['Guinea (Guinée)', <CountryCode>('GN'), '224'],
    ['Guinea-Bissau (Guiné Bissau)', <CountryCode>('GW'), '245'],
    ['Guyana', <CountryCode>('GY'), '592'],
    ['Haiti', <CountryCode>('HT'), '509'],
    ['Honduras', <CountryCode>('HN'), '504'],
    ['Hong Kong (香港)', <CountryCode>('HK'), '852'],
    ['Hungary (Magyarország)', <CountryCode>('HU'), '36'],
    ['Iceland (Ísland)', <CountryCode>('IS'), '354'],
    ['India (भारत)', <CountryCode>('IN'), '91'],
    ['Indonesia', <CountryCode>('ID'), '62'],
    ['Iran (‫ایران‬‎)', <CountryCode>('IR'), '98'],
    ['Iraq (‫العراق‬‎)', <CountryCode>('IQ'), '964'],
    ['Ireland', <CountryCode>('IE'), '353'],
    ['Isle of Man', <CountryCode>('IM'), '44', 2],
    ['Israel (‫ישראל‬‎)', <CountryCode>('IL'), '972'],
    ['Italy (Italia)', <CountryCode>('IT'), '39', 0],
    ['Jamaica', <CountryCode>('JM'), '1', 4, ['876', '658']], ['Japan (日本)', <CountryCode>('JP'), '81'],
    ['Jersey', <CountryCode>('JE'), '44', 3],
    ['Jordan (‫الأردن‬‎)', <CountryCode>('JO'), '962'],
    ['Kazakhstan (Казахстан)', <CountryCode>('KZ'), '7', 1],
    ['Kenya', <CountryCode>('KE'), '254'],
    ['Kiribati', <CountryCode>('KI'), '686'],
    ['Kosovo', <CountryCode>('XK'), '383'],
    ['Kuwait (‫الكويت‬‎)', <CountryCode>('KW'), '965'],
    ['Kyrgyzstan (Кыргызстан)', <CountryCode>('KG'), '996'],
    ['Laos (ລາວ)', <CountryCode>('LA'), '856'],
    ['Latvia (Latvija)', <CountryCode>('LV'), '371'],
    ['Lebanon (‫لبنان‬‎)', <CountryCode>('LB'), '961'],
    ['Lesotho', <CountryCode>('LS'), '266'],
    ['Liberia', <CountryCode>('LR'), '231'],
    ['Libya (‫ليبيا‬‎)', <CountryCode>('LY'), '218'],
    ['Liechtenstein', <CountryCode>('LI'), '423'],
    ['Lithuania (Lietuva)', <CountryCode>('LT'), '370'],
    ['Luxembourg', <CountryCode>('LU'), '352'],
    ['Macau (澳門)', <CountryCode>('MO'), '853'],
    ['Macedonia (FYROM) (Македонија)', <CountryCode>('MK'), '389'],
    ['Madagascar (Madagasikara)', <CountryCode>('MG'), '261'],
    ['Malawi', <CountryCode>('MW'), '265'],
    ['Malaysia', <CountryCode>('MY'), '60'],
    ['Maldives', <CountryCode>('MV'), '960'],
    ['Mali', <CountryCode>('ML'), '223'],
    ['Malta', <CountryCode>('MT'), '356'],
    ['Marshall Islands', <CountryCode>('MH'), '692'],
    ['Martinique', <CountryCode>('MQ'), '596'],
    ['Mauritania (‫موريتانيا‬‎)', <CountryCode>('MR'), '222'],
    ['Mauritius (Moris)', <CountryCode>('MU'), '230'],
    ['Mayotte', <CountryCode>('YT'), '262', 1],
    ['Mexico (México)', <CountryCode>('MX'), '52'],
    ['Micronesia', <CountryCode>('FM'), '691'],
    ['Moldova (Republica Moldova)', <CountryCode>('MD'), '373'],
    ['Monaco', <CountryCode>('MC'), '377'],
    ['Mongolia (Монгол)', <CountryCode>('MN'), '976'],
    ['Montenegro (Crna Gora)', <CountryCode>('ME'), '382'],
    ['Montserrat', <CountryCode>('MS'), '1664'],
    ['Morocco (‫المغرب‬‎)', <CountryCode>('MA'), '212', 0],
    ['Mozambique (Moçambique)', <CountryCode>('MZ'), '258'],
    ['Myanmar (Burma) (မြန်မာ)', <CountryCode>('MM'), '95'],
    ['Namibia (Namibië)', <CountryCode>('NA'), '264'],
    ['Nauru', <CountryCode>('NR'), '674'],
    ['Nepal (नेपाल)', <CountryCode>('NP'), '977'],
    ['Netherlands (Nederland)', <CountryCode>('NL'), '31'],
    ['New Caledonia (Nouvelle-Calédonie)', <CountryCode>('NC'), '687'],
    ['New Zealand', <CountryCode>('NZ'), '64'],
    ['Nicaragua', <CountryCode>('NI'), '505'],
    ['Niger (Nijar)', <CountryCode>('NE'), '227'],
    ['Nigeria', <CountryCode>('NG'), '234'],
    ['Niue', <CountryCode>('NU'), '683'],
    ['Norfolk Island', <CountryCode>('NF'), '672'],
    ['North Korea (조선 민주주의 인민 공화국)', <CountryCode>('KP'), '850'],
    ['Northern Mariana Islands', <CountryCode>('MP'), '1670'],
    ['Norway (Norge)', <CountryCode>('NO'), '47', 0],
    ['Oman (‫عُمان‬‎)', <CountryCode>('OM'), '968'],
    ['Pakistan (‫پاکستان‬‎)', <CountryCode>('PK'), '92'],
    ['Palau', <CountryCode>('PW'), '680'],
    ['Palestine (‫فلسطين‬‎)', <CountryCode>('PS'), '970'],
    ['Panama (Panamá)', <CountryCode>('PA'), '507'],
    ['Papua New Guinea', <CountryCode>('PG'), '675'],
    ['Paraguay', <CountryCode>('PY'), '595'],
    ['Peru (Perú)', <CountryCode>('PE'), '51'],
    ['Philippines', <CountryCode>('PH'), '63'],
    ['Poland (Polska)', <CountryCode>('PL'), '48'],
    ['Portugal', <CountryCode>('PT'), '351'],
    ['Puerto Rico', <CountryCode>('PR'), '1', 3, ['787', '939']], ['Qatar (‫قطر‬‎)', <CountryCode>('QA'), '974'],
    ['Réunion (La Réunion)', <CountryCode>('RE'), '262', 0],
    ['Romania (România)', <CountryCode>('RO'), '40'],
    ['Russia (Россия)', <CountryCode>('RU'), '7', 0],
    ['Rwanda', <CountryCode>('RW'), '250'],
    ['Saint Barthélemy', <CountryCode>('BL'), '590', 1],
    ['Saint Helena', <CountryCode>('SH'), '290'],
    ['Saint Kitts and Nevis', <CountryCode>('KN'), '1869'],
    ['Saint Lucia', <CountryCode>('LC'), '1758'],
    ['Saint Martin (Saint-Martin (partie française))', <CountryCode>('MF'), '590', 2],
    ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', <CountryCode>('PM'), '508'],
    ['Saint Vincent and the Grenadines', <CountryCode>('VC'), '1784'],
    ['Samoa', <CountryCode>('WS'), '685'],
    ['San Marino', <CountryCode>('SM'), '378'],
    ['São Tomé and Príncipe (São Tomé e Príncipe)', <CountryCode>('ST'), '239'],
    ['Saudi Arabia (‫المملكة العربية السعودية‬‎)', <CountryCode>('SA'), '966'],
    ['Senegal (Sénégal)', <CountryCode>('SN'), '221'],
    ['Serbia (Србија)', <CountryCode>('RS'), '381'],
    ['Seychelles', <CountryCode>('SC'), '248'],
    ['Sierra Leone', <CountryCode>('SL'), '232'],
    ['Singapore', <CountryCode>('SG'), '65'],
    ['Sint Maarten', <CountryCode>('SX'), '1721'],
    ['Slovakia (Slovensko)', <CountryCode>('SK'), '421'],
    ['Slovenia (Slovenija)', <CountryCode>('SI'), '386'],
    ['Solomon Islands', <CountryCode>('SB'), '677'],
    ['Somalia (Soomaaliya)', <CountryCode>('SO'), '252'],
    ['South Africa', <CountryCode>('ZA'), '27'],
    ['South Korea (대한민국)', <CountryCode>('KR'), '82'],
    ['South Sudan (‫جنوب السودان‬‎)', <CountryCode>('SS'), '211'],
    ['Spain (España)', <CountryCode>('ES'), '34'],
    ['Sri Lanka (ශ්‍රී ලංකාව)', <CountryCode>('LK'), '94'],
    ['Sudan (‫السودان‬‎)', <CountryCode>('SD'), '249'],
    ['Suriname', <CountryCode>('SR'), '597'],
    ['Svalbard and Jan Mayen', <CountryCode>('SJ'), '47', 1],
    ['Swaziland', <CountryCode>('SZ'), '268'],
    ['Sweden (Sverige)', <CountryCode>('SE'), '46'],
    ['Switzerland (Schweiz)', <CountryCode>('CH'), '41'],
    ['Syria (‫سوريا‬‎)', <CountryCode>('SY'), '963'],
    ['Taiwan (台灣)', <CountryCode>('TW'), '886'],
    ['Tajikistan', <CountryCode>('TJ'), '992'],
    ['Tanzania', <CountryCode>('TZ'), '255'],
    ['Thailand (ไทย)', <CountryCode>('TH'), '66'],
    ['Timor-Leste', <CountryCode>('TL'), '670'],
    ['Togo', <CountryCode>('TG'), '228'],
    ['Tokelau', <CountryCode>('TK'), '690'],
    ['Tonga', <CountryCode>('TO'), '676'],
    ['Trinidad and Tobago', <CountryCode>('TT'), '1868'],
    ['Tunisia (‫تونس‬‎)', <CountryCode>('TN'), '216'],
    ['Turkey (Türkiye)', <CountryCode>('TR'), '90'],
    ['Turkmenistan', <CountryCode>('TM'), '993'],
    ['Turks and Caicos Islands', <CountryCode>('TC'), '1649'],
    ['Tuvalu', <CountryCode>('TV'), '688'],
    ['U.S. Virgin Islands', <CountryCode>('VI'), '1340'],
    ['Uganda', <CountryCode>('UG'), '256'],
    ['Ukraine (Україна)', <CountryCode>('UA'), '380'],
    ['United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', <CountryCode>('AE'), '971'],
    ['United Kingdom', <CountryCode>('GB'), '44', 0],
    ['United States', <CountryCode>('US'), '1', 0],
    ['Uruguay', <CountryCode>('UY'), '598'],
    ['Uzbekistan (Oʻzbekiston)', <CountryCode>('UZ'), '998'],
    ['Vanuatu', <CountryCode>('VU'), '678'],
    ['Vatican City (Città del Vaticano)', <CountryCode>('VA'), '39', 1],
    ['Venezuela', <CountryCode>('VE'), '58'],
    ['Vietnam (Việt Nam)', <CountryCode>('VN'), '84'],
    ['Wallis and Futuna (Wallis-et-Futuna)', <CountryCode>('WF'), '681'],
    ['Western Sahara (‫الصحراء الغربية‬‎)', <CountryCode>('EH'), '212', 1],
    ['Yemen (‫اليمن‬‎)', <CountryCode>('YE'), '967'],
    ['Zambia', <CountryCode>('ZM'), '260'],
    ['Zimbabwe', <CountryCode>('ZW'), '263'],
    ['Åland Islands', <CountryCode>('AX'), '358', 1]];

  public fetchCountryData(): Country[] {
    return CountryCodeList.allCountries.map(c => {
      return {
        name: c[0].toString(),
        iso2: <CountryCode>(c[1]),
        dialCode: c[2].toString(),
        priority: +c[3] || 0,
        areaCodes: c[4] as string[] || undefined,
        flagClass: c[1].toString().toUpperCase(),
        placeHolder: ''
      };
    });
  }
}

export const Examples: any = {
  '001': '001',
  AC: '40123',
  AD: '312345',
  AE: '501234567',
  AF: '701234567',
  AG: '2684641234',
  AI: '2642351234',
  AL: '662123456',
  AM: '77123456',
  AO: '923123456',
  AR: '91123456789',
  AS: '6847331234',
  AT: '664123456',
  AU: '412345678',
  AW: '5601234',
  AX: '412345678',
  AZ: '401234567',
  BA: '61123456',
  BB: '2462501234',
  BD: '1812345678',
  BE: '470123456',
  BF: '70123456',
  BG: '48123456',
  BH: '36001234',
  BI: '79561234',
  BJ: '90011234',
  BL: '690001234',
  BM: '4413701234',
  BN: '7123456',
  BO: '71234567',
  BQ: '3181234',
  BR: '11961234567',
  BS: '2423591234',
  BT: '17123456',
  BW: '71123456',
  BY: '294911911',
  BZ: '6221234',
  CA: '5062345678',
  CC: '412345678',
  CD: '991234567',
  CF: '70012345',
  CG: '061234567',
  CH: '781234567',
  CI: '01234567',
  CK: '71234',
  CL: '221234567',
  CM: '671234567',
  CN: '13123456789',
  CO: '3211234567',
  CR: '83123456',
  CU: '51234567',
  CV: '9911234',
  CW: '95181234',
  CX: '412345678',
  CY: '96123456',
  CZ: '601123456',
  DE: '15123456789',
  DJ: '77831001',
  DK: '32123456',
  DM: '7672251234',
  DO: '8092345678',
  DZ: '551234567',
  EC: '991234567',
  EE: '51234567',
  EG: '1001234567',
  EH: '650123456',
  ER: '7123456',
  ES: '612345678',
  ET: '911234567',
  FI: '412345678',
  FJ: '7012345',
  FK: '51234',
  FM: '3501234',
  FO: '211234',
  FR: '612345678',
  GA: '06031234',
  GB: '7400123456',
  GD: '4734031234',
  GE: '555123456',
  GF: '694201234',
  GG: '7781123456',
  GH: '231234567',
  GI: '57123456',
  GL: '221234',
  GM: '3012345',
  GN: '601123456',
  GP: '690001234',
  GQ: '222123456',
  GR: '6912345678',
  GT: '51234567',
  GU: '6713001234',
  GW: '955012345',
  GY: '6091234',
  HK: '51234567',
  HN: '91234567',
  HR: '921234567',
  HT: '34101234',
  HU: '201234567',
  ID: '812345678',
  IE: '850123456',
  IL: '502345678',
  IM: '7924123456',
  IN: '8123456789',
  IO: '3801234',
  IQ: '7912345678',
  IR: '9123456789',
  IS: '6111234',
  IT: '3123456789',
  JE: '7797712345',
  JM: '8762101234',
  JO: '790123456',
  JP: '9012345678',
  KE: '712123456',
  KG: '700123456',
  KH: '91234567',
  KI: '72001234',
  KM: '3212345',
  KN: '8697652917',
  KP: '1921234567',
  KR: '1000000000',
  KW: '50012345',
  KY: '3453231234',
  KZ: '7710009998',
  LA: '2023123456',
  LB: '71123456',
  LC: '7582845678',
  LI: '660234567',
  LK: '712345678',
  LR: '770123456',
  LS: '50123456',
  LT: '61234567',
  LU: '628123456',
  LV: '21234567',
  LY: '912345678',
  MA: '650123456',
  MC: '612345678',
  MD: '62112345',
  ME: '67622901',
  MF: '690001234',
  MG: '321234567',
  MH: '2351234',
  MK: '72345678',
  ML: '65012345',
  MM: '92123456',
  MN: '88123456',
  MO: '66123456',
  MP: '6702345678',
  MQ: '696201234',
  MR: '22123456',
  MS: '6644923456',
  MT: '96961234',
  MU: '52512345',
  MV: '7712345',
  MW: '991234567',
  MX: '12221234567',
  MY: '123456789',
  MZ: '821234567',
  NA: '811234567',
  NC: '751234',
  NE: '93123456',
  NF: '381234',
  NG: '8021234567',
  NI: '81234567',
  NL: '612345678',
  NO: '40612345',
  NP: '9841234567',
  NR: '5551234',
  NU: '8884012',
  NZ: '211234567',
  OM: '92123456',
  PA: '61234567',
  PE: '912345678',
  PF: '87123456',
  PG: '70123456',
  PH: '9051234567',
  PK: '3012345678',
  PL: '512345678',
  PM: '551234',
  PR: '7872345678',
  PS: '599123456',
  PT: '912345678',
  PW: '6201234',
  PY: '961456789',
  QA: '33123456',
  RE: '692123456',
  RO: '712034567',
  RS: '601234567',
  RU: '9123456789',
  RW: '720123456',
  SA: '512345678',
  SB: '7421234',
  SC: '2510123',
  SD: '911231234',
  SE: '701234567',
  SG: '81234567',
  SH: '51234',
  SI: '31234567',
  SJ: '41234567',
  SK: '912123456',
  SL: '25123456',
  SM: '66661212',
  SN: '701234567',
  SO: '71123456',
  SR: '7412345',
  SS: '977123456',
  ST: '9812345',
  SV: '70123456',
  SX: '7215205678',
  SY: '944567890',
  SZ: '76123456',
  TA: '8999',
  TC: '6492311234',
  TD: '63012345',
  TG: '90112345',
  TH: '812345678',
  TJ: '917123456',
  TK: '7290',
  TL: '77212345',
  TM: '66123456',
  TN: '20123456',
  TO: '7715123',
  TR: '5012345678',
  TT: '8682911234',
  TV: '901234',
  TW: '912345678',
  TZ: '621234567',
  UA: '501234567',
  UG: '712345678',
  US: '2015550123',
  UY: '94231234',
  UZ: '912345678',
  VA: '3123456789',
  VC: '7844301234',
  VE: '4121234567',
  VG: '2843001234',
  VI: '3406421234',
  VN: '912345678',
  VU: '5912345',
  WF: '501234',
  WS: '7212345',
  XK: '43201234',
  YE: '712345678',
  YT: '639012345',
  ZA: '711234567',
  ZM: '955123456',
  ZW: '712345678'
};
