<form>
  <mat-card>
    <mat-card-title i18n>Восстановление пароля</mat-card-title>
    <mat-card-content>
      <mat-form-field>
        <input type="email" matInput placeholder="Email" [formControl]="emailFormControl">
        <mat-hint i18n>Введите email</mat-hint>
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')" i18n>
          Ошибка при вводе email
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')" i18n>
          Для восстановления пароля email <strong>необходим</strong>
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="apply()" i18n>Продолжить</button>
    </mat-card-actions>
  </mat-card>
</form>
