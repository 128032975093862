<mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step>
        <ng-template matStepLabel><ng-container i18n>Выбор файла</ng-container></ng-template>
        <h1 i18n>
            Добавление списка жильцов из файла
        </h1>
        <p i18n>
            Загрузите таблицу Excel следующего формата - в каждой строке таблицы содержится:
        </p>
        <ul>
            <li i18n>в первом столбце - общий адрес</li>
            <li i18n>во втором столбце - номер квартиры</li>
            <li i18n>в третьем столбце - телефон</li>
            <li i18n>в четвертом столбце - может содержаться комментарий</li>
        </ul>
        <mat-divider></mat-divider>

        <p i18n>Телефоны, записанные не в международном формате (начиная со знака +) будут автоматически преобразованы
            согласно выбранным региональным правилам</p>
        <mat-form-field class="screen_wide">
            <mat-label i18n>Код страны "по умолчанию"</mat-label>
            <mat-select [(value)]="selectedCountry">
                <mat-option [value]="" i18n>Не использовать преобразование</mat-option>
                <mat-option *ngFor="let country of allCountries" [value]="country.iso2">
                    <div class="icon-wrapper">
                        <div class="flag" [ngClass]="country.flagClass"></div>
                    </div>
                    <div class="label-wrapper">{{country.name}} +{{country.dialCode}}</div>
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-divider></mat-divider>
        <mat-progress-bar mode="determinate" [value]="table_progress"></mat-progress-bar>
        <div>
            <input type="file" (change)="fileChanged($event)">
            <button mat-raised-button color="primary" (click)="loadFile()" i18n>Загрузить выбранный файл</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel><ng-container i18n>Загруженный файл</ng-container></ng-template>
        <mat-table [dataSource]="dataSource" class="table_limit">

            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef class="select_col">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                                  [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="select_col">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                                  class="table_checkbox" [checked]="selection.isSelected(row)"
                                  [aria-label]="checkboxLabel(row)"
                                  [disabled]="!row.importable">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="common">
                <mat-header-cell *matHeaderCellDef i18n>
                    Адрес
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.common}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="local">
                <mat-header-cell *matHeaderCellDef i18n>
                    Квартира
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.local}}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="phone">
                <mat-header-cell *matHeaderCellDef i18n>
                    Телефон
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.phone}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="comment">
                <mat-header-cell *matHeaderCellDef i18n>
                    Комментарий
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.comment}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="error">
                <mat-header-cell *matHeaderCellDef i18n>
                    Ошибки
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.error}}
                </mat-cell>
            </ng-container>

            <!-- Заголовоки -->
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

            <!-- Обычные строки -->
            <mat-row *matRowDef="let row; columns: displayedColumns" [class]="get_class(row)"
                     [matTooltip]="tooltip(row)">
            </mat-row>
        </mat-table>
        <mat-card>
            <mat-card-title i18n>Статистика</mat-card-title>
            <mat-card-subtitle i18n>
                Найденные адреса
            </mat-card-subtitle>
            <dl>
                <ng-container *ngFor="let a of objectKeys(common)">
                    <dt>{{a}}</dt>
                    <dd>{{common[a].count}} <ng-container i18n>телефонов</ng-container></dd>
                </ng-container>
            </dl>
            <mat-card-subtitle i18n>
                Обработанные телефонные номера
            </mat-card-subtitle>
            <dl>
                <dt i18n>Не удалось обработать телефонных номеров</dt>
                <dd>{{stat.unparsed}}</dd>
                <dt i18n>Количество ячеек без телефонных номеров</dt>
                <dd>{{stat.undefined}}</dd>
                <dt i18n>Корректных телефонных номеров</dt>
                <dd>{{stat.good}}</dd>
            </dl>
        </mat-card>
        <div class="buttons">
            <button mat-raised-button color="primary" matStepperPrevious i18n>Назад к выбору файла</button>
            <button mat-raised-button color="primary" matStepperNext i18n>Выбор импортируемых адресов</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            <ng-container i18n>Адреса</ng-container>
        </ng-template>
        <ng-container i18n>Для продолжения необходимо сопоставить адреса, зарегистрированные в системе с адресами из
            файла или создать новые адреса
        </ng-container>
        <div *ngFor="let src of objectKeys(common)">
            <mat-form-field>
                <mat-label>{{src}}</mat-label>
                <mat-select [(value)]="common[src].id" [compareWith]="desc.compareWith">
                    <mat-option *ngFor="let key of addresses" [value]="key.id">
                        {{key.address}}
                    </mat-option>
                    <mat-option value="-1" i18n>
                        Создать новый адрес
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="buttons">
            <button mat-raised-button color="primary" matStepperPrevious i18n>Назад к выбору импортируемых строк
            </button>
            <button *ngIf="is_create_new_address(); else ready_for_import" mat-raised-button color="primary"
                    (click)="create_new_address()" i18n>
                Создать выбранные адреса
            </button>
            <ng-template #ready_for_import>
                <button mat-raised-button color="primary" matStepperNext (click)="select_for_import()" i18n>Дальше
                </button>
            </ng-template>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            <ng-container i18n>Импортируемые данные</ng-container>
        </ng-template>
        <mat-table [dataSource]="dataSource_import" class="table_limit" matSort>
            <ng-container matColumnDef="exist">
                <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="element.exist" i18n-matTooltip matTooltip="Запись содержится в базе">done
                    </mat-icon>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="common">
                <mat-header-cell *matHeaderCellDef>Адрес</mat-header-cell>
                <mat-cell *matCellDef="let element">{{desc.address[element.common].address}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="local">
                <mat-header-cell *matHeaderCellDef i18n>Квартира</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.local}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="phone">
                <mat-header-cell *matHeaderCellDef i18n>Телефон</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.phone}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="comment">
                <mat-header-cell *matHeaderCellDef i18n>Комментарий</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.comment}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="metka_type_id">
                <mat-header-cell *matHeaderCellDef i18n>Тип метки</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.exist">
                        <app-descr val="metka_type" [id]="element.metka_type_id"></app-descr>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="serial">
                <mat-header-cell *matHeaderCellDef i18n>Идентификационный номер</mat-header-cell>
                <mat-cell *matCellDef="let element" class="serial">
                    <ng-container *ngIf="element.exist && element.metka_type_id!=3">{{ element.serial }}</ng-container>
                </mat-cell>
            </ng-container>

            <!-- Заголовоки -->
            <mat-header-row *matHeaderRowDef="importColumns; sticky: true"></mat-header-row>

            <!-- Обычные строки -->
            <mat-row *matRowDef="let row; columns: importColumns"></mat-row>
        </mat-table>
        <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
        <div class="buttons">
            <button *ngIf="!importing" mat-raised-button color="primary" matStepperPrevious i18n>Назад к адресам
            </button>
            <button *ngIf="!importing; else abort_btn" mat-raised-button color="primary" (click)="start_import()" i18n>
                Импортировать
            </button>
            <ng-template #abort_btn>
                <button mat-raised-button color="warning" (click)="start_abort()" i18n>
                    Прервать
                </button>
            </ng-template>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            <ng-container i18n>Завершение</ng-container>
        </ng-template>
        <mat-card>
            <mat-card-title i18n>
                Результат импорта файла
            </mat-card-title>
            <dl>
                <dt i18n>Всего обработано записей</dt>
                <dd>{{lines}}</dd>
                <dt i18n>Импортировано жильцов:</dt>
                <dd>{{success}}</dd>
            </dl>
            <a mat-raised-button color="primary" [routerLink]="'/ids'" i18n>Готово</a>
        </mat-card>
    </mat-step>
</mat-horizontal-stepper>
