import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface GroupBy {
  initial: string;
  isGroupBy: boolean;
  value: string;
  src: any;
}

export class GroupedObjectsDataSource<T> extends MatTableDataSource<GroupBy | T> {

  filterOut = (el) => true;
  filterOutEmptyGroups(data: (GroupBy | T)[]) {
    return data.filter((el, idx, dat) => {
      if (idx + 1 >= dat.length) {
        return !(<GroupBy>el).isGroupBy;
      }

      const next = dat[idx + 1];

      return !((<GroupBy>el).isGroupBy !== undefined && (<GroupBy>next).isGroupBy !== undefined);
    });

  }

  constructor(private groupName: string, private groupable: { [property: string]: ((id: any) => string) } = {}) {
    super();
  }

  isSubHeader(el): el is GroupBy {
    return (<GroupBy>el).isGroupBy !== undefined && (<GroupBy>el).isGroupBy;
  }

  isNotSubHeader(el: (GroupBy | T)): el is T {
    return !((<GroupBy>el).isGroupBy !== undefined && (<GroupBy>el).isGroupBy);
  }

  sortingDataAccessor: ((data: (GroupBy | T), sortHeaderId: string) => string | number) = (data: (GroupBy | T), sortHeaderId: string): string | number => {
    if (this.isNotSubHeader(data)) {
      return data[sortHeaderId];
    } else {
      return data.initial;
    }
  }

  sortData: ((data: (GroupBy | T)[], sort: MatSort) => (GroupBy | T)[]) = (data: (GroupBy | T)[], sort: MatSort): (GroupBy | T)[] => {
    const active = sort.active;
    const direction = sort.direction;
    if (!active || direction === '') { return data; }

    let dataSource = data.sort((a, b) => {
      const valueA = this.sortingDataAccessor(a, active);
      const valueB = this.sortingDataAccessor(b, active);

      // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
      // one value exists while the other doesn't. In this case, existing value should come first.
      // This avoids inconsistent results when comparing values to undefined/null.
      // If neither value exists, return 0 (equal).
      let comparatorResult = 0;
      if (valueA != null && valueB != null) {
        // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
        if (valueA > valueB) {
          comparatorResult = 1;
        } else if (valueA < valueB) {
          comparatorResult = -1;
        }
      } else if (valueA != null) {
        comparatorResult = 1;
      } else if (valueB != null) {
        comparatorResult = -1;
      }

      return comparatorResult * (direction === 'asc' ? 1 : -1);
    });

    dataSource = dataSource.filter(el => this.isNotSubHeader(el));
    if (this.groupable[active] !== undefined) {
      for (let idx = 0; idx < dataSource.length; ++idx) {
        if (idx === 0 || this.groupable[active](dataSource[idx - 1][active]) !== this.groupable[active](dataSource[idx][active])) {
          dataSource.splice(
            idx, 0, { initial: dataSource[idx][active], isGroupBy: true, value: this.groupable[active](dataSource[idx][active]), src: dataSource[idx]});
          ++idx;
        }
      }
    }

    return this.filterOutEmptyGroups(dataSource.filter(this.filterOut));
  }

}
