import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UrlPathService} from '../url-path.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordValidation} from '../registration/registration.component';

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnDestroy, OnInit {
    private subscription: Subscription;
    private params$: Subscription;

    emailForm: FormGroup;
    sent = false;
    success = null;

    constructor(
        private fb: FormBuilder,
        private recaptchaV3Service: ReCaptchaV3Service,
        private route: ActivatedRoute,
        public router: Router,
        private http: HttpClient,
        private path: UrlPathService,
    ) {
        this.emailForm = this.fb.group({
            uid: ['', [Validators.required]],
            email: ['', [Validators.required]],
            password: ['', [Validators.required]],
            confirmPassword: ['', [Validators.required]],
        }, {validator: PasswordValidation.MatchPassword});
        this.emailForm.patchValue({uid: ''});
    }

    public ngOnInit() {
        this.params$ = this.route
            .queryParams
            .subscribe({
                next: (query) => {
                    this.emailForm.patchValue(query);
                }
            });
    }

    public ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.params$) {
            this.params$.unsubscribe();
        }
    }

    public apply(): void {
        this.subscription = this.recaptchaV3Service.execute('newpassword')
            .subscribe((token) => {
                const params = new HttpParams()
                    .set('uid', this.emailForm.value.uid)
                    .set('password', this.emailForm.value.password)
                    .set('email', this.emailForm.value.email)
                    .set('token', token);
                this.sent = true;
                this.http.post(this.path.newPassword(), params).subscribe({
                    next: res => {
                        this.router.navigate(['/']);
                    },
                    error: () => this.sent = false,
                    complete: () => this.sent = false
                });
            });
    }
}
