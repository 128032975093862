<div class="mat-app-background basic-container">
  <mat-card *ngIf="external_filter==null && (filter.group_description_id || filter.address_common_id)">
    <ng-container i18n>Выбранные фильтры:</ng-container>
    <a *ngIf="filter.group_description_id && desc && desc.group[filter.group_description_id]" routerLink="/base"
      [queryParams]="shrink('group_description_id')">
      <app-descr val="group" [id]="filter.group_description_id"></app-descr>
      <mat-icon>close</mat-icon>
    </a>

    <a *ngIf="filter.address_common_id && desc && desc.address[filter.address_common_id]" routerLink="/base"
      [queryParams]="shrink('address_common_id')">
      <app-descr val="address" [id]="filter.address_common_id"></app-descr>
      <mat-icon>close</mat-icon>
    </a>
  </mat-card>
  <mat-card>
    <mat-card-title i18n>
      Контроллеры
    </mat-card-title>
    <mat-card-subtitle *ngIf="updating">
      <mat-progress-spinner class="" diameter="20" mode="indeterminate"></mat-progress-spinner>
      {{updateText}}
    </mat-card-subtitle>
    <mat-card-content class="table_base">
      <div>
        <mat-form-field>
          <mat-label i18n>Поиск</mat-label>
          <input matInput (keyup)="applyFilter($event)" />
          <mat-icon matSuffix>search</mat-icon>
          <mat-hint i18n>Поиск по контроллерам</mat-hint>
        </mat-form-field>
      </div>
      <form [formGroup]="base">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef class="actions_col" i18n> Действия </mat-header-cell>
            <mat-cell *matCellDef="let element" class="actions_col">
              <ng-container *ngIf="edit_id==null">
                <button mat-icon-button (click)="downloadLog(element)" i18n-matTooltip matTooltip="Журнал событий">
                  <mat-icon class="md-18">assignment_returned</mat-icon>
                </button>
                <button mat-icon-button (click)="edit(element)" i18n-matTooltip matTooltip="Быстрое редактирование">
                  <mat-icon class="md-18">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="delete(element)" i18n-matTooltip matTooltip="Удалить контроллер">
                  <mat-icon class="md-18">delete</mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="edit_id==element.id">
                <button mat-icon-button (click)="cancel()">
                  <mat-icon class="md-18" color="warn" i18n-matTooltip matTooltip="Отменить редактирование">cancel</mat-icon>
                </button>
                <button mat-icon-button (click)="done()" i18n-matTooltip matTooltip="Сохранить">
                  <mat-icon class="md-18">done</mat-icon>
                </button>
              </ng-container>
              <mat-icon *ngIf="element.CHANGE" i18n-matTooltip matTooltip="Идёт обновление данных контроллера">update</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header> # </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sn">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Идентификационный номер </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a mat-button [routerLink]="['/base', element.id]" color="primary">{{element.sn | hex}}</a>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="group_ids">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>
              Группа
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="edit_id==element.id; else show_group">
                <mat-form-field>
                  <mat-label i18n>Группы</mat-label>
                  <mat-select formControlName="group_ids" multiple>
                    <mat-option *ngFor="let key of objectKeys(desc.group)" [value]="desc.group[key].id">
                      <app-descr val="group" [id]="key"></app-descr>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-template #show_group>
                <a *ngFor="let id of element.group_ids; index as i;" [routerLink]="'/base'"
                  [queryParams]="expand('group_description_id',id)">
                  <app-descr val="group" [id]="id"></app-descr>
                </a>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Комментарий</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="edit_id==element.id; else show_comment">
                <mat-form-field>
                  <input matInput  i18n-placeholder placeholder="Комментарий" formControlName="comment">
                </mat-form-field>
              </ng-container>
              <ng-template #show_comment>
                {{element.comment}}
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="insync">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="narrow" i18n>Состояние</mat-header-cell>
            <mat-cell *matCellDef="let element" class="narrow">
              <button mat-icon-button [matTooltip]="'Время последней синхронизации '+element.TIME">
                <mat-icon *ngIf="element.in_sync; else not_in_sync" class="md-18" color="primary">
                  update</mat-icon>
                <ng-template #not_in_sync>
                  <mat-icon class="md-18" color="warn">watch_later
                  </mat-icon>
                </ng-template>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Название</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="edit_id==element.id; else show_name">
                <mat-form-field>
                  <input matInput i18n-placeholder placeholder="Название" formControlName="name">
                </mat-form-field>
              </ng-container>
              <ng-template #show_name>
                {{element.name}}
              </ng-template>
            </mat-cell>
          </ng-container>

          <!-- Адрес регистрации -->
          <ng-container matColumnDef="address_common_id">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Адрес</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="edit_id==element.id; else show_address">
                <mat-form-field>
                  <mat-label i18n>Адрес установки</mat-label>
                  <mat-select formControlName="address_common_id">
                    <mat-option *ngFor="let key of objectKeys(desc.address)" [value]="desc.address[key].id">
                      <app-descr val="address" [id]="key"></app-descr>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-template #show_address>
                <a *ngIf="element.address_common_id" [routerLink]="'/base'"
                  [queryParams]="expand('address_common_id',element.address_common_id)">
                  <app-descr val="address" [id]="element.address_common_id"></app-descr>
                </a>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="remote">
            <mat-header-cell *matHeaderCellDef mat-sort-header> открыть </mat-header-cell>
            <mat-cell *matCellDef="let element">
<!--              {{element.OPEN1|json}}-->
<!--              {{element.OPEN2|json}}-->
<!--              {{element.DOR_CHANGE|json}}-->
              <mat-spinner *ngIf="element.OPEN1 && element.DOR_CHANGE" matTooltip="Открытие реле" diameter="18" class="md-18"></mat-spinner>
              <a *ngIf="element.RF1ENABLE" mat-button (click)="open_controller(element.id, 1)">{{element.RELAY1NAME}}</a>
              <mat-spinner *ngIf="element.OPEN2 && element.DOR_CHANGE" matTooltip="Открытие реле" diameter="18" class="md-18"></mat-spinner>
              <a *ngIf="element.RF2ENABLE" mat-button (click)="open_controller(element.id, 2)">{{element.RELAY2NAME}}</a>
            </mat-cell>
          </ng-container>


          <!-- Заголовок группы -->
          <ng-container matColumnDef="groupHeader">
            <mat-row>
              <mat-header-cell colspan="999" *matCellDef="let groupBy">
                <h3>{{groupBy.value}}</h3>
              </mat-header-cell>
            </mat-row>
          </ng-container>

          <!-- Заголовоки -->
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

          <!-- Обычные строки -->
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          <!-- Строка подзаголовка -->
          <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"></mat-row>
        </mat-table>

      </form>
    </mat-card-content>

    <mat-card-actions> <a *ngIf="allow_add" routerLink="/base/add" mat-raised-button color="primary" i18n>Добавить
        контроллер</a> </mat-card-actions>
  </mat-card>
</div>

<!-- <app-id [external_filter]="{base: true}"> </app-id> -->
