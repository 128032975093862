import { environment } from '../environments/environment';
import { Component } from '@angular/core';
import { AuthService, UserData } from './login/login.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = $localize`ELS Cloud - Web портал управления системой ELS ACCESS`;
  version: string;
  constructor(public auth: AuthService) {
    this.version = environment.version;
    console.log(environment.version);
  }
}
