import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ObjectData, TKDData, ObjectService, TariffData, TariffGridElement} from '../object';
import {DescriptionService} from '../description.service';

import {FormGroup, FormBuilder, Validators, FormArray, FormControl} from '@angular/forms';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'object-edit-dialog',
    templateUrl: 'object.dialog.html',
    styleUrls: ['./object.dialog.scss'],
})
// tslint:disable-next-line:component-class-suffix
export class ObjectEditDialog implements OnInit {
    objectKeys = Object.keys;
    getMonth = this.obj.getMonth;
    form: FormGroup;

    get form_value(): ObjectData {
        return this.form.value as ObjectData;
    }

    get tkds(): FormArray {
        return this.form.get('tkds') as FormArray;
    }

    get tkds_value(): TKDData[] {
        return this.tkds.value as TKDData[];
    }

    get grid(): FormArray {
        return this.form.get('tariff').get('grid') as FormArray;
    }

    get grid_value(): TariffGridElement[] {
        return this.grid.value as TariffGridElement[];
    }

    get tariff(): FormGroup {
        return this.form.get('tariff') as FormGroup;
    }

    get tariff_value(): TariffData {
        return this.tariff.value as TariffData;
    }

    constructor(
        public desc: DescriptionService,
        public dialogRef: MatDialogRef<ObjectEditDialog>,
        public fb: FormBuilder,
        public obj: ObjectService,
        @Inject(MAT_DIALOG_DATA) public object: ObjectData) {
    }

    monthes(m: number): number {
        return [1, 3, 6, 12][m];
    }

    nextTariff(): FormGroup {
        const idx = this.tariff ? this.grid.length : 0;
        const m = this.monthes(idx);
        const min = [19, 39, 59, 99][idx];
        return this.fb.group({
            apply: [true, Validators.required],
            monthes: [m, Validators.required],
            payment: [min, [Validators.required, Validators.min(min)]],
        });
    }

    appendTariff() {
        this.grid.push(this.nextTariff());
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.form = this.obj.form(this.object);
        this.disableTKDRemote();
    }

    getTkdName(tkd: TKDData) {
        if (tkd.name) {
            return tkd.name;
        }

        if (tkd.bases) {
            return this.desc.get('base', tkd.bases[0]);
        }

        return this.desc.get('tkd_name', this.object.type_id);
    }

    append() {
        const val = this.obj.addFormTKD();
        val.patchValue({
            name: this.desc.get('tkd_name', this.object.type_id),
            bases: [],
            REMOTE: -1,
        });

        this.tkds.push(val);
        console.log(this.form.value);
    }

    delete(tkd: TKDData) {
        const idx = this.form.value.tkds.indexOf(tkd);
        this.tkds.removeAt(idx);
    }

    tkd_force_enabled(base_id: number, tkd: TKDData) {
        if (tkd.bases.indexOf(base_id) >= 0) {
            return true;
        }
        return (this.tkds.value as TKDData[]).some(t => t.bases.indexOf(base_id) >= 0);
    }

    save() {
        this.obj.set({...this.form.value}).subscribe({
            next: value => {
                console.log(value);
                this.obj.get(this.object.object_id).subscribe();
            }
        });
    }

    disableTKDRemote() {
            for (let t = 0; t < this.tkds.length; ++t) {
                if (this.form_value.REMOTE === 0) {
                    // this.tkds.at(t).patchValue({REMOTE: 0});
                    this.tkds.at(t).disable();
                } else {
                    this.tkds.at(t).enable();
                }
            }
    }
}

