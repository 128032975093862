import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

import {UrlPathService} from './url-path.service';
import {AuthService} from './login/login.component';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {dec2hex} from './hex.pipe';
import {DescriptionService} from './description.service';

import {saveAs} from 'file-saver';
import {toSQLString} from './datetime';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {Router} from '@angular/router';

export class IDData {
    id: number | null;
    serial: string;
    serial_hex?: string;
    serial_auto?: boolean;
    create_time: string | Date;
    activation_time: string | null | Date;
    time_exp: string | null | Date;
    phone: string;
    address_local: string;
    address_common_id: number;
    comment: string;

    status_id: number | string;
    metka_type_id: number | string;
    user_id: number;
    objects: null | string | number[];

    livetime?: number | null;

    not_any_disabled: number;
    any_enabled: number;
    change_state: number;

    name: string;
    status: string;
    type: string;
    group_ids: string | string[] | number[];
    group_bases: string | string[] | number[];
    group_bases_remote: string | string[] | number[];

    filter?: string;
    base_only?: boolean;

    REMOTE: string | null;
}

export interface LogLine {
    id: number;
    timestamp: Date | string;
    message: string;
    user_id: number;
}

export class MetkaValidation {
    static MatchMetkaTypeToSerial(AC: AbstractControl) {
        const metka_type_id: string | null = AC.get('metka_type_id').value; // to get value in input tag
        const serial_hex: string | null = AC.get('serial_hex').value; // to get value in input tag
        const serial_auto: boolean | null = AC.get('serial_auto').value;

        if (metka_type_id === '2' || (metka_type_id === '1' && !serial_auto)) { // Если серийник обязателен

            if (serial_hex == null) {
                AC.get('serial_hex').setErrors({required: true});
                return;
            }

            if (metka_type_id === '2' && !(/^[0-9a-fA-F]{16}$/.test(serial_hex))) {
                AC.get('serial_hex').setErrors({pattern: true});
                return;
            }

            if (serial_hex !== null) {
                const prefix = (serial_hex as string).substr(0, 2).toLowerCase();

                if (!((prefix === 'e1' && metka_type_id === '2') || (prefix === 'eb' && metka_type_id !== '1'))) {
                    AC.get('serial_hex').setErrors({UnknownType: true});
                    return;
                }

                AC.get('serial_hex').setErrors(null);
            }
        }
    }

    static MatchPhone(AC: AbstractControl) {
        const metka_type_id: string | null = AC.get('metka_type_id').value; // to get value in input tag
        const phone: string | null = AC.get('phone').value;

        if (!(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/.test(phone))) {
            AC.get('phone').setErrors({pattern: true});
        }

        if (metka_type_id === '1' && phone === null) {
            AC.get('phone').setErrors({required: true});
        }
    }

    static MatchTimeExp(AC: AbstractControl) {
        const metka_type_id: string | null = AC.get('metka_type_id').value; // to get value in input tag
        const time_exp: Date | null = AC.get('time_exp').value;

        if (metka_type_id === '1' && time_exp === null) {
            AC.get('time_exp').setErrors({required: true});
        }
        if (metka_type_id === '2') {
            AC.get('time_exp').setErrors(null);
        }
    }
}

// TODO: После сейва должен емититься сигнал на обновление, на который должны подписаться base и ids
@Injectable({
    providedIn: 'root'
})
export class IDDataService {
    constructor(
        private fb: FormBuilder,
        private auth: AuthService,
        private http: HttpClient,
        private path: UrlPathService,
        private desc: DescriptionService,
        public dialog: MatDialog,
        private router: Router,
    ) {
    }

    @Output() afterUpdate: EventEmitter<void> = new EventEmitter<void>();

    display = {
        id: false,
        serial: true,
        serial_hex: true,
        serial_auto: true,
        create_time: false,
        activation_time: false,
        phone: true,

        livetime: true,
        time_exp: true,

        address_local: true,
        address_common_id: true,

        comment: true,
        status_id: true,
        metka_type_id: true,
        user_id: true,
        name: true,
        status: false,
        type: false,
        group_ids: true,
        group_bases: true,
        objects: false,
    };

    cook(r: IDData): IDData {
        if (r === undefined) {
            return new IDData();
        }

        if (r.serial_hex === undefined) {
            r.serial_hex = dec2hex(r.serial);
        }

        r.filter = '';

        if (r.metka_type_id !== undefined && this.desc.metka_type[r.metka_type_id] !== undefined) {
            r.filter = r.filter + this.desc.metka_type[r.metka_type_id].type;
        }
        if (r.metka_type_id === undefined || r.metka_type_id === null) {
            if (r.serial_hex.substr(0, 2).toLowerCase() === 'eb') {
                r.metka_type_id = '1';
            } else {
                r.metka_type_id = '2';
            }
        }

        r.base_only = r.id == null;
        if (r.id == null) {
            r.id = 0;
        }

        if (r.livetime === undefined) {
            r.livetime = 0;
        }

        if (r.objects !== undefined && r.objects !== null) {
            r.objects = (<string>r.objects).split(',').map(v => +v);
        } else {
            r.objects = [];
        }

        r.create_time = new Date(r.create_time);
        if (r.activation_time !== null) {
            r.activation_time = new Date(r.activation_time);
        }
        if (r.time_exp !== null) {
            r.time_exp = new Date(r.time_exp);
        }

        if (r.group_ids !== undefined && r.group_ids !== null) {
            r.group_ids = (<string>r.group_ids).split(',');
        } else {
            r.group_ids = [];
        }

        for (let i = 0; i < r.group_ids.length; ++i) {
            if (this.desc.group[r.group_ids[i]] !== undefined) {
                r.filter = r.filter + this.desc.group[r.group_ids[i]].description;
            }
        }

        if (r.group_bases !== undefined && r.group_bases !== null) {
            r.group_bases = (<string>r.group_bases).split(',');
            r.group_bases = r.group_bases.filter(e => e !== 'web');
        } else {
            r.group_bases = [];
        }

        for (let i = 0; i < r.group_bases.length; ++i) {
            if (this.desc.base[r.group_bases[i]] !== undefined) {
                r.filter = r.filter + this.desc.base[r.group_bases[i]].name;
            }
        }

        if (r.group_bases_remote !== undefined && r.group_bases_remote !== null) {
            r.group_bases_remote = (<string>r.group_bases_remote).split(',');
            r.group_bases_remote = r.group_bases_remote.filter(e => e !== 'web');
        } else {
            r.group_bases_remote = [];
        }

        for (let i = 0; i < r.group_bases_remote.length; ++i) {
            if (this.desc.base[r.group_bases_remote[i]] !== undefined) {
                r.filter = r.filter + this.desc.base[r.group_bases_remote[i]].name;
            }
        }

        if (r.phone) {
            let phoneNumber = parsePhoneNumberFromString(r.phone);
            if (phoneNumber === undefined || !phoneNumber.isValid()) {
                r.phone = r.phone.replace(/[^+0-9]/g, '');
                phoneNumber = parsePhoneNumberFromString(r.phone);
            }
            phoneNumber = parsePhoneNumberFromString(r.phone);
            if (phoneNumber === undefined || !phoneNumber.isValid()) {
                phoneNumber = parsePhoneNumberFromString('+' + r.phone);
            }

            if (phoneNumber !== undefined && phoneNumber.isValid()) {
                r.phone = phoneNumber.number.toString();
            }
        }

        if (r.status_id === undefined || r.status_id == null) {
            r.status_id = 1;
        }

        if (r.status_id !== undefined && this.desc.status[r.status_id] !== undefined) {
            r.filter = r.filter + this.desc.status[r.status_id].status;
        }

        if (r.user_id !== undefined && this.desc.user[r.user_id] !== undefined) {
            r.filter = r.filter + this.desc.user[r.user_id].name;
        }

        if (r.any_enabled > 0 && r.not_any_disabled > 0) {
            r.status_id = 2;
        } else {
            r.status_id = 1;
        }

        if (r.serial_auto === undefined) {
            r.serial_auto = false;
        }

        // @ts-ignore
        if (r.REMOTE === undefined || r.REMOTE == null) {
            r.REMOTE = '-1';
        }

        return r;
    }

    get(serial: number) {
        return this.http.post(this.path.metka(), {'serial': serial}).pipe(
            map((res) => this.cook(res[0])));
    }

    phone(p: string) {
        return this.http.post(this.path.metka(), {'phone': p}).pipe(
            map((res) => this.cook(res[0])));
    }

    log(id: string | number) {
        this.router.navigate(['log'], {queryParams: {metka: id}});
        // this.http.get(this.path.metkaLog(serial), { responseType: 'text' }).subscribe(
        //     (log: string) => {
        //         const file = new File([log], `${dec2hex(serial)}.csv`, { type: 'text/plain;charset=utf-8' });
        //         saveAs(file);
        //     }
        // );
    }

    form(r?: IDData): FormGroup {
        const enabled_serial = r === undefined || (r.id === 0 && r.serial === '');
        const enabled_user = this.auth.role === 1;
        const form = this.fb.group({
            serial_hex: [{value: null, disabled: !enabled_serial}],
            serial_auto: [null],
            comment: [null],
            address_common_id: [null],
            address_local: [null],
            metka_type_id: [{value: null, disabled: !enabled_serial}, Validators.required],
            user_id: [{value: this.auth.id, disabled: !enabled_user}],
            activation_time: [null],
            status_id: [null],
            phone: [{value: null, disabled: r !== undefined}],
            time_exp: [new Date(this.auth.time_exp)],
            livetime: [{value: null, disabled: r !== undefined}],
            group_ids: [null],
            group_bases: [null, Validators.required],
            group_bases_remote: [null],
            id: [{value: null, disabled: true}],
            REMOTE: [null],
        }, {validator: [MetkaValidation.MatchMetkaTypeToSerial, MetkaValidation.MatchTimeExp, MetkaValidation.MatchPhone]});
        if (r !== undefined) {
            if (r.metka_type_id === 3) {
                r.metka_type_id = '1';
            }
            form.patchValue(r);  // BUG! Почему-то не прописывается time_exp
        } else {
            form.patchValue({user_id: this.auth.id, serial_auto: true, metka_type_id: '1', livetime: 0, status_id: '2'});
        }
        // todo: Разобраться с остальными параметрами
        return form;
    }

    set(old_value, new_value) {
        let req;
        const tzoffset = (new Date()).getTimezoneOffset() * 60000;

        if (old_value !== null && new_value.id) {
            // todo: отправить данные на сервак
            req = {id: old_value.id};
            for (const i in new_value) {
                if (new_value[i] !== null && new_value[i] !== old_value[i]) {
                    req[i] = new_value[i];
                }
            }
        } else {
            req = new_value;
        }

        for (const i in req) {
            if (req[i] instanceof Date) {
                req[i] = toSQLString(req[i]);
            }
        }

        // if (req.comment !== undefined && req.comment !== null) {
        //     req.comment = req.comment.substr(0, 40);
        // }
        if (req.address_local !== undefined && req.address_local !== null) {
            req.address_local = req.address_local.substr(0, 40);
        }

        if (req.group_bases_remote) {
            req.REMOTE = new_value.REMOTE;
        }
        return this.http.post(this.path.metkaUpdate(), req).pipe(tap(() => this.afterUpdate.emit()));
    }

    delete(element: IDData, dialog: boolean = true) {
        if (!dialog) {
            return this.http
                .post(this.path.metkaDelete(), element)
                .pipe(tap(() => this.afterUpdate.emit()));
        }

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: $localize`Удалить метку?`
        });

        return dialogRef.afterClosed().pipe(switchMap(result => {
            if (result) {
                return this.delete(element, false);
            } else {
                return of({id: 0});
            }
        }));
    }

    updateFromGroups(metka: FormGroup, desc: DescriptionService) {
        let n = [];
        const gb = metka.value['group_ids'];
        for (let i = 0; i < gb.length; ++i) {
            if (desc.group[gb[i]].group_bases !== null) {
                n = n.concat(desc.group[gb[i]].group_bases.split(','));
            }
        }
        n = n.filter((v, i, a) => a.indexOf(v) === i);

        n = n.filter(v => desc.base[v].TAGSYNC === '1');

        metka.patchValue({'group_bases': n});
    }

    all(): Promise<IDData[]> {
        return this.http.get<IDData[]>(this.path.metka()).toPromise().then(ids => ids.map(id => this.cook(id)));
    }
}
