<ng-container *ngIf="ids.length">
  <mde-popover #show_group_expand mdePopoverPositionX="after" [mdePopoverOverlapTrigger]="false">
    <mat-card>
      <mat-card-header>
        {{title}}:
      </mat-card-header>
      <mat-card-content>
        <div *ngFor="let id of ids; index as i">
          <a (click)="callback(id)">
            <app-descr [val]="val" [id]="id"></app-descr>
          </a>
        </div>
      </mat-card-content>
    </mat-card>
  </mde-popover>
  <ng-container *ngIf="ids.length>1; else singleGroup">
    <a [mdePopoverTriggerFor]="show_group_expand" (click)="callback(ids[0])">
      <app-descr [val]="val" [id]="ids[0]"></app-descr>
      <mat-icon inline="true">more_vert</mat-icon>
    </a>
  </ng-container>
  <ng-template #singleGroup>
    <div>
      <a (click)="callback(ids[0])">
        <app-descr [val]="val" [id]="ids[0]"></app-descr>
      </a>
    </div>
  </ng-template>
</ng-container>