import { Component, OnInit, OnDestroy } from '@angular/core';
import { DescriptionService } from '../description.service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})
export class GroupDetailsComponent implements OnInit, OnDestroy {
  name$;
  name: string;

  group_id: number;

  constructor(
    public desc: DescriptionService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.name$ = combineLatest([this.desc.update(), this.route.queryParams])
      .subscribe({
        next: ([desc, p]) => {
          this.name = desc.group[p.group_description_id].description;
          this.group_id = p.group_description_id;
        }
      });

  }

  ngOnDestroy(): void {
    this.name$.unsubscribe();
  }
}
