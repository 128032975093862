<mat-card>
  <mat-card-title i18n>
    Форма регистрации
  </mat-card-title>
  <mat-card-subtitle i18n>
    Введите регистрационные данные:
  </mat-card-subtitle>
  <mat-accordion>
    <mat-expansion-panel [expanded]="!auth.loggedIn">

      <mat-expansion-panel-header>
        <mat-panel-title i18n>
          Контактная информация
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="contact">
        <mat-form-field>
          <input matInput placeholder="* E-mail" formControlName="email" maxlength="255" >
        </mat-form-field>


        <mat-form-field>
          <input matInput i18n-placeholder placeholder="Контактное лицо" formControlName="name" maxlength="45">
        </mat-form-field>

        <mat-form-field>
          <input matInput i18n-placeholder placeholder="Телефон" formControlName="phone" maxlength="45" appDiggits>
        </mat-form-field>

        <mat-form-field>
          <input matInput i18n-placeholder placeholder="Должность" formControlName="position" maxlength="45">
        </mat-form-field>

        <div *ngIf="auth.loggedIn">
          <mat-form-field>
            <input type="password" matInput i18n-placeholder placeholder="Для смены пароля введите текущий пароль"
                   formControlName="passwordold">
          </mat-form-field>
          <div *ngIf="contact.controls.passwordold.value!==null">
            <mat-form-field>
              <input type="password" matInput ng-required="contact.controls.passwordold.value.length"
                     i18n-placeholder placeholder="Новый пароль" formControlName="password">
              <mat-hint i18n>
                Пароль должен состоять как минимум из 3х символов!
              </mat-hint>
              <mat-error *ngIf="(contact.touched || contact.dirty) && contact.hasError('StrengthPassword','password')"
                         i18n>
                Слишком простой пароль
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input type="password" matInput ng-required="contact.controls.passwordold.value.length"
                     i18n-placeholder placeholder="Подтверждение пароля" formControlName="confirmPassword">
              <mat-hint i18n>
                Повтрорите ввод пароля
              </mat-hint>
              <mat-error
                      *ngIf="(contact.touched || contact.dirty) && contact.hasError('MatchPassword','confirmPassword')"
                      i18n>
                Введённые пароли должны совпадать
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="!auth.loggedIn">
          <mat-form-field>
            <input type="password" required matInput i18n-placeholder placeholder="Пароль" formControlName="password">

            <mat-hint i18n>
              Пароль должен состоять как минимум из 3 символов!
            </mat-hint>
            <mat-error *ngIf="(contact.touched || contact.dirty) && contact.hasError('StrengthPassword','password')"
                       i18n>
              Слишком простой пароль
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input type="password" required matInput i18n-placeholder placeholder="Подтверждение пароля"
                   formControlName="confirmPassword">
            <mat-hint i18n>
              Повторите ввод пароля
            </mat-hint>
            <mat-error
                    *ngIf="(contact.touched || contact.dirty) && contact.hasError('MatchPassword','confirmPassword')" i18n>
              Введённые пароли должны совпадать
            </mat-error>

          </mat-form-field>
        </div>
        <mat-action-row>
          <mat-progress-spinner *ngIf="updating" mode="indeterminate"></mat-progress-spinner>
          <button mat-button *ngIf="success!=null" color="primary">{{success}}</button>
          <button mat-button *ngIf="error!=null" color="warn">{{error}}</button>
          <button type="submit" mat-button color="secondary" *ngIf="!auth.loggedIn" [disabled]="!contact.valid"
            (click)="registerContact()" color="primary" i18n>Зарегистрироваться</button>

          <button type="submit" *ngIf="auth.loggedIn" mat-button color="secondary" (click)="updateContact()" color="primary" i18n>
            Обновить контактную информацию
          </button>
        </mat-action-row>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="auth.loggedIn">
      <mat-expansion-panel-header>
        <mat-panel-title i18n>
          Информация об организации
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p *ngIf="auth.loggedIn && auth.validated" i18n>Ниже можно отредактировать информацию об организации</p>
      <p *ngIf="auth.loggedIn && !auth.validated" i18n>Для окончания регистрации необходимо ввести следующую информацию об
        организации</p>
      <button mat-button *ngIf="!auth.loggedIn" color="warn" i18n>Сперва необходимо зарегистрировать пользователя</button>
      <form [formGroup]="company">
        <mat-radio-group i18n-aria-label aria-label="Представитель" formControlName="jur_phi">
          <mat-radio-button value="1" i18n>Юридическое лицо</mat-radio-button>
          <mat-radio-button value="2" i18n>Физическое лицо</mat-radio-button>
        </mat-radio-group>
        <fieldset *ngIf="company.value.jur_phi=='1'" [attr.disabled]="auth.role==1?null:''">
          <mat-form-field>
            <input matInput i18n-placeholder placeholder="* Название организации" maxlength="255" formControlName="name">
          </mat-form-field>


          <mat-form-field>
            <input matInput i18n-placeholder placeholder="ИНН" maxlength="12" formControlName="inn">
          </mat-form-field>


          <mat-form-field>
            <input matInput i18n-placeholder placeholder="Р/С" maxlength="20" formControlName="r_s">
          </mat-form-field>


          <mat-form-field>
            <input matInput i18n-placeholder placeholder="Кор/с" maxlength="20" formControlName="kor_s">
          </mat-form-field>


          <mat-form-field>
            <input matInput i18n-placeholder placeholder="Юр. Адрес" maxlength="255" formControlName="jur_adr">
          </mat-form-field>


          <mat-form-field>
            <input matInput i18n-placeholder placeholder="КПП"  maxlength="9" formControlName="kpp">
          </mat-form-field>


          <mat-form-field>
            <input matInput i18n-placeholder placeholder="ОКПО" maxlength="10" formControlName="okpo">
          </mat-form-field>


          <mat-form-field>
            <input matInput i18n-placeholder placeholder="БИК" maxlength="9" formControlName="bik">
          </mat-form-field>
        </fieldset>
      </form>
      <mat-action-row>
        <mat-progress-spinner *ngIf="updating"></mat-progress-spinner>
        <button mat-button *ngIf="success!=null" color="primary">{{success}}</button>
        <button mat-button *ngIf="error!=null" color="warn">{{error}}</button>
        <button type="submit" *ngIf="auth.role==1" mat-button (click)="updateCompany()"  color="primary" i18n>Обновить информацию</button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="auth.loggedIn" *ngIf="auth.company !== undefined && auth.company
     !== null && auth.company.pool !== undefined && auth.company.pool !== null && auth.company.pool.pool !== undefined">
      <mat-expansion-panel-header i18n>
        Информация о заключённых договорах
      </mat-expansion-panel-header>
      <mat-table [dataSource]="auth.company.pool.pool" matSort>

        <ng-container matColumnDef="contract">
          <mat-header-cell *matHeaderCellDef mat-sort-header> # </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.contract}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="all">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n> всего меток </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.all}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="empty">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n> доступно меток </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.empty}} </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="licensetime">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n> окончание срока договора </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.licensetime}} </mat-cell>
        </ng-container>
        
        <!-- Заголовоки -->
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

        <!-- Обычные строки -->
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-action-row>
    <button *ngIf="!auth.loggedIn" type="submit" mat-button (click)="login()" color="primary" i18n>Вход</button>
    <span class="flex-spacer"></span>
  </mat-action-row>
</mat-card>
