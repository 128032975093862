<form [formGroup]="form">
    <h2 mat-dialog-title>
        <ng-container i18n>Расположение:</ng-container>
        <app-descr [id]="form_value.address" val="address"></app-descr>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <app-address-selector formControlName="address"></app-address-selector>
        <h4>
            <ng-container i18n>Тип:</ng-container>
            <app-descr val="construct" [id]="object.type_id"></app-descr>
        </h4>
        <mat-form-field>
            <mat-label i18n>Название</mat-label>
            <input matInput formControlName="name" type="text"/>
            <mat-hint i18n>Название объекта, отображаемое в мобильном приложении</mat-hint>
            <mat-error *ngIf="(form.touched || form.dirty) && form.hasError('required', 'name')" i18n>
                Поле обязательное для заполнения
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label i18n>Ответственный:</mat-label>
            <mat-select formControlName="user" [compareWith]="desc.compareWith">
                <mat-option
                        *ngFor="let key of objectKeys(desc.user)"
                        [value]="desc.user[key].id"
                >
                    <app-descr val="user" [id]="key"></app-descr>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label i18n>Дополнительная информация</mat-label>
            <textarea matInput formControlName="extra" type="text" rows="3"></textarea>
        </mat-form-field>
        <div>
            <h3 i18n>Удалённое открытие через интернет</h3>
            <!--            {{form_value|json}}-->
            <mat-radio-group class="on_new_line" i18n-aria-label aria-label="Удалённое открытие через интернет"
                             formControlName="REMOTE"
                             #remote_open
                             (change)="disableTKDRemote()"
            >
                <mat-radio-button [value]="-1" i18n>Разрешения заданы в настройках Контроллера, ТКД или Метки
                </mat-radio-button>
                <mat-radio-button [value]="0" i18n>Запрещено для всех</mat-radio-button>
                <mat-radio-button [value]="1" i18n>Разрешено для всех пользователей объекта</mat-radio-button>
            </mat-radio-group>
        </div>
        <div formArrayName="tkds">
            <h3 i18n>Точки контроля доступа</h3>
            <mat-expansion-panel
                    *ngFor="let tkd of tkds.controls; let i=index"
                    [formGroupName]="i"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>{{getTkdName(tkd.value)}}</mat-panel-title>
                </mat-expansion-panel-header>
                <mat-form-field>
                    <mat-label i18n>Название ассоциированного объекта</mat-label>
                    <input matInput type="text" formControlName="name"/>
                </mat-form-field>
                <mat-form-field>
                    <mat-label><app-descr [id]="form_value.type_id" val="object"></app-descr>
<!--                        {{desc.construct[form_value.type_id].object}}-->
                        <ng-container i18n>диапазон номеров (например: 4-5,7,12-15)</ng-container>
                    </mat-label>
                    <input matInput type="text" formControlName="numbers"/>
                    <mat-error *ngIf="(tkd.touched || tkd.dirty) && tkd.get('numbers').hasError('pattern')" i18n>
                        Диапазон должен состоять из чисел, разделённых знаком тире и запятыми
                    </mat-error>
                    <mat-error *ngIf="(tkd.touched || tkd.dirty) && tkd.get('numbers').hasError('validateRange')" i18n>
                        Ошибка при вводе диапазонов
                    </mat-error>
                </mat-form-field>
                <app-base-selector formControlName="bases" i18n-label label="Точки контроля доступа"
                                   i18n-hint
                                   hint="Изменение списка контроллеров приведёт к автоматической перерегистрации меток объекта в новом списке контроллеров"
                                   i18n-required
                                   required="Необходимо выбрать хотя бы один контроллер"></app-base-selector>
                <mat-radio-group class="on_new_line" i18n-aria-label aria-label="Удалённое открытие через интернет"
                                 formControlName="REMOTE"
                                 #remote_open>
                    <mat-radio-button [value]="-1" i18n>Определяется настройками Объекта</mat-radio-button>
                    <mat-radio-button [value]="0" i18n>Запрещено для всех</mat-radio-button>
                    <mat-radio-button [value]="1" i18n>Разрешено для всех пользователей объекта</mat-radio-button>
                </mat-radio-group>
                <div>
                    <button mat-button color="warn" (click)="delete(tkd.value)" i18n>Удалить</button>
                </div>
            </mat-expansion-panel>
            <mat-card-actions>
                <button mat-button color="primary" (click)="append()" i18n>Добавить</button>
            </mat-card-actions>
        </div>
        <mat-expansion-panel
                *ngIf="object.tariff.starterkit == 0"
                formGroupName="tariff"
        >
            <mat-expansion-panel-header>
                <mat-panel-title i18n>Тариф</mat-panel-title>
                <mat-panel-description>ELS Cloud Pro</mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field>
                <mat-label i18n
                >Срок пробного бесплатного периода пользования меткой
                </mat-label
                >
                <input
                        matInput
                        type="number"
                        formControlName="freePeriod"
                        min="0"
                        max="3"
                />
                <mat-hint i18n>
                    Введите количество месяцев пробного периода
                </mat-hint>
                <mat-error *ngIf="tariff.hasError('max','freePeriod')" i18n>
                    Время пробного периода не должно превышать 3 месяцев
                </mat-error>
            </mat-form-field>
            <div formArrayName="grid" class="grid">
                <div
                        *ngFor="let gridItem of grid.controls; let i=index"
                        [formGroupName]="i"
                        class="tariff_line"
                >
                    <!--<mat-divider></mat-divider>-->
                    <mat-card-subtitle>
                        <ng-container i18n>Тариф за</ng-container>
                        {{getMonth(tariff_value.grid[i].monthes)}}
                    </mat-card-subtitle>
                    <mat-form-field class="inline">
                        <mat-label><ng-container i18n>
                            Сумма за</ng-container>
                            {{getMonth(tariff_value.grid[i].monthes)}}
                        </mat-label>
                        <input
                                matInput
                                formControlName="payment"
                                type="number"
                                step="1"
                        />
                        <span matSuffix i18n>.00 руб.</span>
                        <mat-error *ngIf="gridItem.hasError('min','payment')">
                            <ng-container i18n>Минимальная сумма для такого периода</ng-container>
                            {{gridItem.getError('min', 'payment').min}} рублей
                        </mat-error>
                    </mat-form-field>
                    <mat-checkbox class="inline" formControlName="apply" i18n
                    >Применять тариф
                    </mat-checkbox
                    >
                </div>
            </div>
            <mat-action-row *ngIf="tariff_value.starterkit == 0 && grid.length < 4">
                <button mat-button (click)="appendTariff()">
                    <ng-container i18n>Добавить тариф за</ng-container>
                    {{getMonth(monthes(grid.length))}}
                </button>
            </mat-action-row>
        </mat-expansion-panel>
    </div>

    <div mat-dialog-actions>
        <ng-container *ngIf="form.touched;else Ok">
            <button mat-button (click)="onNoClick()" i18n>Отмена</button>
            <button
                    mat-button
                    [mat-dialog-close]="object"
                    cdkFocusInitial
                    [disabled]="form.invalid || tkds.length == 0"
                    (click)="save()" i18n
            >
                Сохранить
            </button>
            <mat-error *ngIf="tkds.length==0" i18n>У обьекта должно быть по крайней мере одна точка контроля доступа!
            </mat-error>
            <mat-error *ngIf="form.invalid" i18n>Не все поля заполнены верно</mat-error>
        </ng-container>
        <ng-template #Ok>
            <button mat-button (click)="onNoClick()" i18n>Ок</button>
        </ng-template>
    </div>
</form>
