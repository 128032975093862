import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DescriptionService} from '../../description.service';
import {ObjectService, TariffData} from '../../object';
import {AuthService} from '../../login/login.component';
import {Router} from '@angular/router';

@Component({
    selector: 'app-create-obj-step3',
    templateUrl: './obj-create-step3.component.html',
    styleUrls: ['./../object.common.scss']
})
export class ObjCreateStep3Component implements OnInit {
    @Output() ready = new EventEmitter<TariffData>();

    tariff: FormGroup;
    getMonth = this.obj.getMonth;
    assign = 0;

    constructor(
        public desc: DescriptionService,
        public obj: ObjectService,
        public fb: FormBuilder,
        public auth: AuthService,
        public router: Router,
    ) {
    }

    ngOnInit() {
        this.tariff = this.fb.group({
            freePeriod: [0, [Validators.min(0), Validators.max(3)]],
            starterkit: [0, Validators.required],
            grid: this.fb.array([
                this.nextTariff()
            ])
        });
    }

    monthes(m: number): number {
        return [1, 3, 6, 12][m];
    }

    nextTariff(): FormGroup {
        const idx = this.tariff ? this.gridArray.length : 0;
        const m = this.monthes(idx);
        const min = [19, 39, 59, 99][idx];
        return this.fb.group({
            apply: [true, Validators.required],
            monthes: [m, Validators.required],
            payment: [min, [Validators.required, Validators.min(min)]],
        });
    }

    appendTariff() {
        this.gridArray.push(this.nextTariff());
    }

    get gridArray(): FormArray {
        return this.tariff.get('grid') as FormArray;
    }

    update() {
        this.ready.emit(this.tariff.value);
    }
}
