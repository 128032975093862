<mat-form-field>
  <mat-label i18n>Адрес</mat-label>
  <mat-select [disabled]="!enabled" [(ngModel)]="address_id" [compareWith]="desc.compareWith"
    (selectionChange)="proxyChange($event)">
    <mat-option *ngFor="let key of objectKeys(desc.address)" [value]="key">
      <app-descr val='address' [id]="key"></app-descr>
    </mat-option>
  </mat-select>
</mat-form-field>
<a [routerLink]="'/editor/address'" color="primary" mat-button i18n>Создать новое расположение</a>
