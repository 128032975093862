import {HttpClient} from '@angular/common/http';
import {Injectable, Output, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {of, Observable} from 'rxjs';
import {map, tap, switchMap} from 'rxjs/operators';

import {FormGroup, FormBuilder, Validators, FormArray, FormControl} from '@angular/forms';

import {UrlPathService} from './url-path.service';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {toSQLString} from './datetime';
import {DeviceInfo} from 'ngx-device-detector';
import {AuthService} from './login/login.component';

export interface TKDData {
    tkd_id?: number;
    object_no?: number;
    name?: string;
    numbers?: string;
    bases: number[];
    REMOTE: number;
}
export interface TariffGridElement {
    apply: number;
    monthes: number;
    payment: number;
}

export interface TariffData {
    freePeriod?: number;
    starterkit: number;
    grid: TariffGridElement[];
}

export interface ObjectData {
    object_id?: number;
    type_id: number;
    name: string;
    address: number;
    user: number;  // TODO: Указатель на пользователя, отвечающего за объект и чья контактная информация будет выдаваться по объекту

    tariff: TariffData; // TODO: Тариф
    extra: string; // TODO: Дополнительная информация по объекту

    tkds: TKDData[];
    stat?: {
        allow: number;
        denied: number;
        pend: number;
        total: number;
    };
    REMOTE: number;
}

export interface RequestData {
    id: number;
    state?: string;

    allow: number | null;
    pend: number | null;

    device_info: DeviceInfo;
    tkd: number;
    param: number | null;
    phone: string;
    serial: number | null;
    time_exp: Date | null;

    status_id: number | null;
    metka_id: number | null;

    comment: string;
    extra?: {
        comment?: string;
    } | null;
}

export function validateRange(c: FormControl) {
    if (c.value == null) {
        return null;
    }

    const range = (c.value as string).split(',').map(r => r.split('-'));

    return range.every(r => r.length === 1 || (r.length === 2 && parseInt(r[0], 10) < parseInt(r[1], 10))) ? null : {
        validateRange: {
            valid: false
        }
    };
}

@Injectable({
    providedIn: 'root'
})
export class ObjectService {
    objects: ObjectData[] = [];
    requests: RequestData[] = [];

    @Output() update = new EventEmitter<ObjectData[]>();

    constructor(
        private http: HttpClient,
        private path: UrlPathService,
        public fb: FormBuilder,
        public dialog: MatDialog
    ) {
    }

    getMonth(m: number): string {
        switch (m) {
            case 1:
                return $localize`${m} месяц`;
            case 2:
            case 3:
            case 4:
                return $localize`${m} месяца`;
            default:
                return $localize`${m} месяцев`;
        }
    }

    cook(r: ObjectData): ObjectData {
        const remote = (t) => ({...t, REMOTE: t.REMOTE === undefined || t.REMOTE === null ? -1 : +t.REMOTE});
        r = remote(r);

        if (r.tkds) {
            r.tkds = r.tkds.map(remote);
        }

        return r;
    }

    updateObject(r: ObjectData) {
        console.log(r);
        const idx = this.objects.findIndex(o => r.object_id === o.object_id);
        if (idx < 0) {
            this.objects.push(r);
        } else {
            this.objects[idx] = r;
        }
    }

    get(id?: number): Observable<ObjectData[]> {
        return this.http.post(this.path.object(), id ? {'id': id} : {}).pipe(
            map((res) => (res as ObjectData[]).filter(el => id ? el.object_id == id : true).map(el => this.cook(el))),
            tap((res) => (res as ObjectData[]).forEach(el => this.updateObject(el))),
            tap(() => this.update.emit(this.objects))
        );
    }

    set(new_value: ObjectData) {
        return this.http.post(this.path.objectUpdate(), new_value).pipe(tap((res: ObjectData) => this.get(res.object_id)));
    }

    delete(object: ObjectData) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: $localize`Удалить объект ${object.name}, все связанные точки контроля доступа и все связанные метки?`
        });

        return dialogRef.afterClosed().pipe(switchMap(result => {
            if (result) {
                console.log('Yes clicked');

                const req = {'object_id': object.object_id};
                this.objects = this.objects.filter(o => o.object_id !== object.object_id);
                return this.http.post(this.path.objectDelete(), req);
            } else {
                return of({id: 0});
            }
        }));
    }

    cookRequest(r: RequestData): RequestData {

        const current = new Date();

        if (r.serial == null) { // Метка для запроса еще не создана
            r.state = r.allow == 0 ? $localize`Доступ запрещён`
                : r.allow == 1 ? $localize`Доступ одобрен`
                    : r.allow == null ? $localize`Запрос разрешения`
                        : `allow: ${r.allow}, pend: ${r.pend}, time_exp: ${r.time_exp}, r.serial: ${r.serial}`;
        } else { // Метка для запроса существует
            r.state = r.status_id == 2 && r.time_exp != null && new Date(r.time_exp) > current ? $localize`Доступ разрешён`
                : r.status_id == 2 && r.time_exp != null && new Date(r.time_exp) < current ? $localize`Доступ приостановлен`
                    : r.status_id != 2 ? $localize`Доступ заблокирован`
                        : `allow: ${r.allow}, pend: ${r.pend}, time_exp: ${r.time_exp}, r.serial: ${r.serial} r.status_id: ${r.status_id}`;
            r.allow = r.status_id == 2 ? 1 : 0;
        }

        if ((r.comment == null || r.comment === '') && r.extra !== null && r.extra.comment) {
            r.comment = r.extra.comment;
        }

        return r;
    }

    getRequests(object_id: number) {
        return this.http.post<RequestData[]>(this.path.objectRequest(), {'object_id': object_id}).pipe(
            map(res => res.map(r => this.cookRequest(r))),
            tap((res: RequestData[]) => this.requests = res));
    }

    changeRequest(allow: number, r: RequestData) {
        return this.http.post(this.path.objectRequestState(), {'id': r.id, 'allow': allow});
    }

    addFormGrid(): FormGroup {
        return this.fb.group({
            apply: [null],
            monthes: [null, Validators.required],
            payment: [null, Validators.required],
        });
    }

    addFormTKD(): FormGroup {
        return this.fb.group({
            tkd_id: [null],
            name: [null, Validators.required],
            numbers: [null, validateRange],
            bases: [null, Validators.required],
            REMOTE: [null],
        });
    }

    form(object?: ObjectData): FormGroup {
        const form = this.fb.group({
            object_id: [null],
            type_id: [null, Validators.required],
            name: [null, Validators.required],
            address: [null, Validators.required],
            user: [null, Validators.required],
            extra: [null],
            tariff: this.fb.group({
                freePeriod: [null],
                starterkit: [null],
                grid: this.fb.array([]),
            }),
            tkds: this.fb.array([]),
            REMOTE: [null],
        });

        if (object) {
            for (let i = 0; i < object.tariff.grid.length; ++i) {
                (form.get('tariff').get('grid') as FormArray).push(this.addFormGrid());
            }

            for (let i = 0; i < object.tkds.length; ++i) {
                (form.get('tkds') as FormArray).push(this.addFormTKD());
            }

            form.patchValue(object);
        }

        return form;
    }
}
