<mat-card *ngIf="auth.role==1">
  <mat-card-title i18n>
    Добавление пользователей системы
  </mat-card-title>
  <mat-card-subtitle>
    <ng-container i18n>Вы вошли в аккаунт компании как:</ng-container>{{auth.company.name}}
    <span class="flex-spacer"></span>
    <button mat-raised-button (click)="registration()" color="primary" i18n>Редактировать</button>
  </mat-card-subtitle>
  <form [formGroup]="user">
    <mat-card-subtitle i18n>
      Добавление нового пользователя
    </mat-card-subtitle>
    <mat-form-field>
      <input matInput i18n-placeholder placeholder="ФИО" formControlName="name">
    </mat-form-field>
    <mat-form-field>
      <input matInput  i18n-placeholder placeholder="* email" formControlName="email">
    </mat-form-field>
    <mat-form-field>
      <input matInput i18n-placeholder placeholder="Телефон" formControlName="phone" appDiggits>
    </mat-form-field>
    <mat-form-field>
      <input matInput i18n-placeholder placeholder="Должность" formControlName="position" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of users.data" [value]="option.position">
          {{option.position}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <input type="password" ng-required="id==0" matInput i18n-placeholder placeholder="Пароль" formControlName="password">
      <mat-error *ngIf="user.controls.password.errors?.StrengthPassword" i18n>
        Слишком простой пароль</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input type="password" ng-required="id==0" matInput i18n-placeholder placeholder="Подтверждение пароля"
        formControlName="confirmPassword">
    </mat-form-field>
      <mat-error *ngIf="user.controls.confirmPassword.errors?.MatchPassword" i18n>
        Введённые пароли должны совпадать
      </mat-error>
      <mat-action-row>
        <button type="submit" mat-raised-button *ngIf="id!=0" (click)="addUser()" color="primary" i18n>
          Создать нового пользователя
        </button>
        <span class="flex-spacer"></span>
        <button mat-button *ngIf="success!=null" color="primary">{{success}}</button>
        <button mat-button *ngIf="error!=null" color=""warn>{{error}}</button>
        <button type="submit" mat-raised-button *ngIf="id==0" [disabled]="!user.valid"
          (click)="saveUser()" color="primary" i18n>
          Сохранить
        </button>
        <button type="submit" mat-raised-button *ngIf="id!=0" (click)="updateUser()" color="primary" color="primary" i18n>
          Обновить данные
        </button>
      </mat-action-row>
  </form>
</mat-card>
<mat-card>
  <mat-card-title i18n>
    Список пользователей системы
  </mat-card-title>
  <mat-card-subtitle i18n>
    В системе зарегестрированы следующие пользователи:
  </mat-card-subtitle>

  <table mat-table [dataSource]="users" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> ФИО </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Телефон </th>
      <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Должность </th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef i18n>Действия</th>
      <td mat-cell *matCellDef="let element">
        <button *ngIf="auth.role==1" mat-raised-button (click)="deleteUser(element)" color="primary" i18n>Удалить</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectUser(row)"></tr>
  </table>
</mat-card>
