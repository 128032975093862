import { Params } from '@angular/router';

export class FilterBuilder {
  get(external?: any): Params {
    const res: Params = Object.assign({}, external === undefined ? {} : external);
    for (const i in this) {
      if (this[i] !== null && typeof this[i] === 'string') {
        const val: string = String(this[i]);
        res[i] = val;
      }
    }
    return res;
  }

  set(res: any) {
    Object.assign(this, res);
  }
}
