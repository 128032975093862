import {environment} from '../../../environments/environment';

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ObjectService, TKDData, validateRange} from '../../object';
import {DescriptionService} from '../../description.service';
import {AuthService} from '../../login/login.component';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';

export class ObjectCreateStep2 {
    tkds: TKDData[];
    REMOTE: number;
}

@Component({
    selector: 'app-create-obj-step2',
    templateUrl: './obj-create-step2.component.html',
    styleUrls: ['./../object.common.scss']
})
export class ObjCreateStep2Component implements OnInit {
    @Input() type_id: number;
    @Output() ready = new EventEmitter<ObjectCreateStep2>();

    displayedColumns = ['name', 'numbers', 'bases', 'remote', 'action'];
    selected_item = undefined;
    tkds: MatTableDataSource<TKDData> = new MatTableDataSource<TKDData>();

    REMOTE = new FormControl(1);
    tkd: FormGroup;
    tkd_single: FormGroup;

    touched: boolean;

    debug = environment.version === 'DEV';

    constructor(
        public desc: DescriptionService,
        public fb: FormBuilder,
    ) {
    }

    ngOnInit() {
        this.tkd = this.fb.group({
            name: [null, Validators.required],
            numbers: [null, [Validators.pattern(/[ \-\,0-9]+/), validateRange, Validators.required]],
            bases: [null, Validators.required],
            REMOTE: [-1],
        });

        this.tkd_single = this.fb.group({
            bases: [null, Validators.required]
        });
    }

    append() {
        const tkds = this.tkds.data;
        tkds.push({
            name: this.desc.construct[this.type_id].param + ' ' + (this.tkds.data.length + 1),
            numbers: '',
            bases: [],
            REMOTE: -1,
        });
        this.tkds.data = tkds;
        this.select(this.tkds.data.length - 1);
    }

    select(element) {
        if (this.tkd.touched && this.tkd.invalid && this.tkds.data.length !== 1) {
            return;
        }

        if (this.selected_item !== undefined) {
            const tkds = this.tkds.data;
            tkds[this.selected_item] = {...this.tkd.value};
            this.tkds.data = tkds;
            this.touched = true;
        }

        // tslint:disable-next-line: triple-equals
        if (this.selected_item !== undefined && this.selected_item == element) {
            this.selected_item = undefined;
        } else {
            if (this.tkds.data.length !== 1) {
                this.tkd.patchValue(this.tkds.data[element]);
            } else {
                this.tkd.patchValue({
                    name: this.tkds.data[element].name,
                    numbers: this.tkds.data[element].numbers,
                });
            }
            this.selected_item = element;
        }
        // this.update();
    }

    cancel() {
        this.selected_item = undefined;
        // this.update();
    }

    delete(element, $event) {
        console.log(element);
        const tkds = this.tkds.data;
        tkds.splice(element, 1);
        this.tkds.data = tkds;
        // tslint:disable-next-line: triple-equals
        if (element == this.selected_item) {
            this.selected_item = undefined;
        }
        // this.update();
        $event.stopPropagation();
    }

    update() {
        if (this.tkds.data.length) {
            this.ready.emit({tkds: this.tkds.data, REMOTE: this.REMOTE.value});
        } else {
            this.ready.emit({tkds: [this.tkd_single.value], REMOTE: this.REMOTE.value});
        }
    }
}
