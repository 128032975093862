import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDiggits]'
})
export class DiggitsDirective {

  constructor() { }

@HostListener('keydown', ['$event'])
onKeyDown(e: KeyboardEvent) {
  if (
    // Allow: Delete, Backspace, Tab, Escape, Enter
    [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
    (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
    (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
    (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
    (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
    (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
    (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
    (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
    (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
    (e.key === '+') || (e.key === '(') || (e.key === ')') || (e.key === ' ') || (e.key === '-') ||
    (e.keyCode >= 35 && e.keyCode <= 39) // Home, End, Left, Right
  ) {
    return;  // let it happen, don't do anything
  }
  // Ensure that it is a number and stop the keypress
  if (
    (e.key < '0' || e.key > '9')
  ) {
    console.log(e);
    e.preventDefault();
  }
}

}
