import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlPathService } from '../url-path.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../login/login.component';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm_email.component.html',
  styleUrls: ['./confirm_email.component.scss']
})
export class ConfirmEmailComponent implements OnDestroy, OnInit {
  private subscription: Subscription;
  private params$: Subscription;

  emailForm: FormGroup;
  sent = false;
  success = null;

  constructor(
    private fb: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute,
    private http: HttpClient,
    private path: UrlPathService,
    public auth: AuthService,
  ) {
    this.success = auth.confirmed_email;
    this.emailForm = this.fb.group({
      uid: ['', [Validators.required]],
    });
    this.emailForm.patchValue({ uid: '' });
  }

  public ngOnInit() {
    this.params$ = this.route
      .queryParams
      .subscribe({
        next: (query) => {
          this.emailForm.patchValue(query);
        }
      });
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.params$) {
      this.params$.unsubscribe();
    }
  }

  public apply(): void {
    this.subscription = this.recaptchaV3Service.execute('confirm')
      .subscribe((token) => {
        const params = new HttpParams()
          .set('uid', this.emailForm.value.uid)
          .set('token', token);
        this.sent = true;
        this.http.post(this.path.confirm(), params).subscribe({
          next: res => {
            this.success = (res as { confirmed_email: number }).confirmed_email > 0;
            if (this.success) {
              this.emailForm.get('uid').disable();
            }
          },
          error: () => this.sent = false,
          complete: () => this.sent = false
        });
      });
  }
}
