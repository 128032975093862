import {Pipe, PipeTransform} from '@angular/core';
import {Params} from '@angular/router';

@Pipe({
    name: 'shrink'
})
export class ShrinkPipe implements PipeTransform {
    transform(value: Object, field: string | string[]): unknown {
        const l: Params = Object.assign({}, value);
        if (typeof field === 'string') {
            delete l[field];
        } else if (Array.isArray(field)) {
            return field.reduce((val, f) => this.transform(val, f), value);
        }
        return l;
    }
}
