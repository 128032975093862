import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMatIntlTelInputComponent} from './ngx-mat-intl-tel-input.component';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {SearchPipe} from './search.pipe';

@NgModule({
    declarations: [
        NgxMatIntlTelInputComponent,
        SearchPipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatDividerModule,
        ReactiveFormsModule
    ],
    exports: [NgxMatIntlTelInputComponent]
})
export class NgxMatIntlTelInputModule {
}
