import {Component, OnInit, OnDestroy} from '@angular/core';
import {StateService} from '../state.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

    lines = [
        {
            link: ['/base'],
            label: $localize`Контроллеры`,
            disabled: () => false
        }, {
            link: ['/ids'],
            label: $localize`Метки`,
            disabled: () => false
        }, {
            link: ['/editor', 'group'],
            label: $localize`Группы`,
            disabled: () => false
        }, {
            link: ['/editor', 'address'],
            label: $localize`Адреса`,
            disabled: () => false
        }, {
            link: ['/objects'],
            label: $localize`Объекты`,
            disabled: () => false,
            badge: () => this.state.pend ? +this.state.pend : null
        }, {
            link: ['/log'],
            query: {day: 0},
            label: $localize`Журнал событий`,
            disabled: () => false
        },
    ];

    pend_count = 0;
    secondsCounter: Subscription;

    constructor(public state: StateService) {
    }

    ngOnInit() {
        this.secondsCounter = this.state.check().subscribe();
    }

    ngOnDestroy() {
        this.secondsCounter.unsubscribe();
    }
}
