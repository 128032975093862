import {Component} from '@angular/core';
import {Router, Route} from '@angular/router';
import {AuthService, UserData} from '../login/login.component';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
    navLinks: Route[];

    constructor(private router: Router, public auth: AuthService) {
        this.auth.auth.subscribe({
            next: (user: UserData) => {
                this.navLinks = this.router.config.filter(
                    r => {
                        return r.data && r.data.legend && (r.data.role === undefined || Number(user.role) === Number(r.data.role));
                    }
                );
            }
        });
    }
}
