<nav mat-tab-nav-bar class="mat-card">
   <mat-nav-list>
      <a mat-list-item *ngFor="let line of lines" routerLinkActive="active" [routerLink]="line.link" [queryParams]="line.query" [attr.disabled]="line.disabled()">
        <span *ngIf="line.badge && line.badge(); else no_badge" [matBadge]="line.badge()" matBadgeOverlap="false">{{line.label}}
      </span>
        <ng-template #no_badge>
            <span>{{line.label}}</span>
        </ng-template>
      </a>
   </mat-nav-list>
</nav>
