import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService, UserData } from '../login/login.component';
import { UrlPathService } from '../url-path.service';
import { PasswordValidation } from '../registration/registration.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  updating = false;
  id = 0;
  user: FormGroup;
  users: MatTableDataSource<UserData> = new MatTableDataSource<UserData>();
  success: string = null;
  error: string = null;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'position', 'phone', 'email', 'action'];

  constructor(
    public auth: AuthService,
    private fb: FormBuilder,
    private http: HttpClient,
    private path: UrlPathService,
    private router: Router,
    public dialog: MatDialog) { }

  getUsersList() {
    this.updating = true;
    this.http.get(this.path.users()).subscribe((res: UserData[]) => {
      this.users.data = res;
      this.updating = false;
    });
  }
  registration() {
    this.router.navigate(['/registration/']);
  }

  ngOnInit() {
    this.initForm();
    this.getUsersList();
    this.resetStatus();
    this.users.sort = this.sort;
  }

  initForm() {
    this.user = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      name: [null, [Validators.required]],

      password: [null],
      confirmPassword: [null],

      phone: [null],
      position: [null],
    }, { validator: PasswordValidation.MatchPassword });
    // this.user.get('email').disable();
  }

  addUser() {
    this.id = 0;
    this.resetStatus();
    this.user.setValue({ email: null, password: null, confirmPassword: null, name: null, phone: null, position: null });
  }

  selectUser(row: UserData) {
    this.id = row.id;
    this.resetStatus();
    this.user.patchValue(row);
  }

  resetStatus() {
    this.error = null;
    this.success = null;
  }

  deleteUser(row: UserData) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize`Удалить пользователя ${row.name} с email: ${row.email}`
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const user: UserData = row;
        user.role = -1;
        this.updating = true;

        this.resetStatus();

        this.http.post(this.path.updateUser(), JSON.stringify(user)).subscribe((res: any) => {
          if (res.id !== undefined && res.id == 0) {
            this.success = $localize`Пользователь ${user.name} удалён из системы`;
            this.getUsersList();
            this.addUser();
          }
          if (res.error !== undefined) {
            this.error = $localize`Ошибка! ${res.error}`;
          }
        }, (err) => {
          console.log(err);
          this.success = null;
          this.error = $localize`Нарушена связь сервером`;
        });
      }
    });
  }

  updateUser() {
    const user: UserData = this.user.value;
    user.role = 0;
    user.id = this.id;
    this.updating = true;

    this.resetStatus();

    this.http.post(this.path.updateUser(), JSON.stringify(user)).subscribe((res: any) => {
      if (res.id !== undefined) {
        this.getUsersList();
        this.success = $localize`Успешно обновлены данные пользователя ${user.name}`;
      }
      if (res.error !== undefined) {
        this.error = $localize`Ошибка! ${res.error}`;
      }
    }, (err) => {
      console.log(err);
      this.error = $localize`Нарушена связь с сервером`;
    });
  }

  saveUser() {
    const user: UserData = this.user.value;
    user.role = 0;
    this.updating = true;

    this.resetStatus();

    this.http.post(this.path.registerUser(), JSON.stringify(user)).subscribe((res: any) => {
      if (res.id !== undefined) {
        this.getUsersList();
        this.success = $localize`Успешно обновлены данные пользователя ${user.name}`;
        this.id = res.id;
      }
      if (res.error !== undefined) {
        this.error = res.error;
      }
    }, (err) => {
      console.log(err);
    });
  }
}
