import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {DescriptionService} from '../description.service';
import {ObjectData, ObjectService, RequestData} from '../object';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {GroupBy, GroupedObjectsDataSource} from '../grouped-objects-data-source';
import {MatDialog} from '@angular/material/dialog';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MetkaDialogComponent} from '../metka/metka.component';
import {ObjectEditDialog} from './object.dialog';
import {dec2hex} from '../hex.pipe';

@Component({
    selector: 'app-detail-object',
    templateUrl: './object.detail.html',
    styleUrls: ['./object.common.scss']
})
export class ObjectDetailComponent implements OnInit, OnDestroy {
    route$: Subscription;
    obj$: Subscription;
    req$: Subscription;

    object: ObjectData;
    object_id: number;
    now = new Date();

    request: GroupedObjectsDataSource<RequestData>
        = new GroupedObjectsDataSource<RequestData>('state', {
        'state': (e) => e
    });

    @ViewChild(MatSort, {static: true}) sort: MatSort;

    displayedColumns = [
        'action',
        'state',
        'param',
        'phone',
        'comment',
        'serial',
        'time_exp',
        'device_info',
    ];

    isGroup(index, item: GroupBy | RequestData): boolean {
        return (<GroupBy>item).isGroupBy;
    }

    constructor(
        public desc: DescriptionService,
        public obj: ObjectService,
        public router: Router,
        public route: ActivatedRoute,
        public dialog: MatDialog,
    ) {
    }

    ngOnInit() {
        this.sort.sort(<MatSortable>({id: 'state', start: 'asc'}));
        this.request.sort = this.sort;
        this.update();
    }

    decToHex(dec: string): string {
        return dec2hex(dec);
    }

    update() {
        this.route$ = this.route.params.subscribe({
            next: (params) => {
                this.object_id = params.object_id;
                this.obj$ = this.obj.get(params.object_id).subscribe({
                    next: (res) => {
                        this.object = res.find(o => o.object_id === params.object_id);
                        if (this.object.tariff.starterkit == 0 && this.displayedColumns.indexOf('tariff') < 0) {
                            this.displayedColumns.push('tariff');
                        }
                    }
                });
                this.req$ = this.obj.getRequests(params.object_id).subscribe({
                    next: (res) => this.request.data = res.map(e => {
                        e.time_exp = e.time_exp ? new Date(e.time_exp) : null;
                        return e;
                    })
                });
            }
        });
    }

    ngOnDestroy(): void {
        if (this.route$) {
            this.route$.unsubscribe();
        }
        if (this.obj$) {
            this.obj$.unsubscribe();
        }
        if (this.req$) {
            this.req$.unsubscribe();
        }
    }

    applyFilter(event: any) {
        this.request.filter = event.target.value.trim().toLowerCase();
    }

    changeReq(allow: number, r?: RequestData) {
        let q: Observable<any>[] = [];
        if (r) {
            q.push(this.obj.changeRequest(allow, r));
        } else {
            q = [];
            for (let i = 0; i < this.request.data.length; ++i) {
                let r = this.request.data[i] as RequestData;
                if (r.pend && r.allow == null) { // Запрос есть, а с запретом или разрешением еще на определено
                    q.push(this.obj.changeRequest(allow, r));
                }
            }
        }

        forkJoin(q).subscribe((res) => {
            console.log(res);
            this.update();
        });
    }

    add_metka(r?: RequestData) {
        console.log(r);

        // this.dialog.open(MetkaDialogComponent, {
        //     width: '620px',
        //     closeOnNavigation: true,
        //     disableClose: false,
        //     hasBackdrop: true,
        //
        //     // data: this.dst_base
        // });
    }

    allow(r?: RequestData) {
        this.changeReq(1, r);
    }

    deny(r?: RequestData) {
        this.changeReq(0, r);
    }

    show_metka(serial) {
        const dialogRef = this.dialog.open(MetkaDialogComponent, {
            width: '620px',
            closeOnNavigation: true,
            disableClose: false,
            hasBackdrop: true,

            data: {serial: serial}
        });

        return dialogRef.afterClosed().subscribe({
            next: () => this.update()
        });
    }

    edit() {
        const dialogRef = this.dialog.open(ObjectEditDialog, {
            width: '80vw',
            data: this.object
        });
        return dialogRef.afterClosed().subscribe({
            next: () => this.update()
        });
    }
}
