<nav *ngIf="auth.loggedIn && auth.validated" mat-tab-nav-bar
     class="mat-elevation-z6 mat-tab-group mat-background-primary mat-tab-header">
    <a mat-tab-link
       *ngFor="let link of navLinks"
       [routerLink]="[link.path]"
       routerLinkActive #rla="routerLinkActive"
       [active]="rla.isActive"
       i18n>
        {link.data.legend, select, users {Пользователи} company {Информация о компании} other {link.data.legend}}
    </a>
    <span class="flex-spacer"></span>
    <a mat-tab-link>
        <app-login [showDialog]='false'></app-login>
    </a>
</nav>
