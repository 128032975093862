<mat-card>
  <mat-card-title>
    {{display.title}}
  </mat-card-title>
  <mat-card-subtitle>
    {{display.add}}
  </mat-card-subtitle>
  <form [formGroup]="form">
    <mat-form-field *ngFor="let f of display.fields">
<!--      <mat-label></mat-label>-->
      <input matInput type="text" [placeholder]="f.name" [formControlName]="f.key" [errorStateMatcher]="matcher">
      <mat-error *ngIf="(form.dirty || form.touched) && form.hasError('required', f.key)">
        {{f.required}}
      </mat-error>
    </mat-form-field>
    <mat-card-actions>
      <button mat-button *ngIf="form.untouched"  (click)="create()"  color="primary" i18n>Создать</button>
      <button mat-button *ngIf="form.touched"  (click)="cancel()"  color="primary" i18n>Отмена</button>
      <button mat-button *ngIf="form.touched" (click)="save()" [disabled]="form.untouched || form.invalid" color="primary" i18n>Сохранить</button>
    </mat-card-actions>
  </form>
  <mat-card-subtitle>
    {{display.table}} <p><i i18n>Для редактирования выберите нужную строку</i></p>
  </mat-card-subtitle>
  <mat-table matSort class="mat-elevation-z8" [dataSource]="desc.as_array(display.table_src)">

    <ng-container *ngFor="let f of display.fields" [matColumnDef]="f.key">
      <mat-header-cell *matHeaderCellDef mat-sort-header [class]="f.key"> {{f.name}} </mat-header-cell>
      <mat-cell *matCellDef="let element" [class]="f.key">
        <a *ngIf="f.routerLink; else only_display" [routerLink]="f.routerLink"
          [queryParams]="f.routerQuery(element.id)">
          {{element[f.key]}}
        </a>
        <ng-template #only_display>
          {{element[f.key]}}
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef class="action" i18n>Действия</mat-header-cell>
      <mat-cell *matCellDef="let element" class="action">
        <ng-container *ngIf="edit_id==null">
          <button mat-icon-button (click)="delete(element)" i18n-matTooltip matTooltip="Удалить">
            <mat-icon class="md-18">delete</mat-icon>
          </button>
          <button mat-icon-button (click)="select(element)" i18n-matTooltip matTooltip="Редактировать">
            <mat-icon class="md-18">edit</mat-icon>
          </button>
          <button *ngIf="element.add_metka" mat-icon-button
          routerLink="/metka" [queryParams]="display.add_metka_field(element.id)"
          [matTooltip]="display.add_metka">
            <mat-icon class="md-18">add</mat-icon>
          </button>
        </ng-container>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="display.displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: display.displayedColumns" (click)="select(row)"></mat-row>
  </mat-table>
</mat-card>
